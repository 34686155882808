/* todo 
* debugger would not start with breakpoints set, see this
https://github.com/facebook/create-react-app/issues/13060


*/
import axios from "axios"
import {useNavigate, useHistory, Link} from "react-router-dom" 
import {useState, useEffect, useRef, useCallback} from "react"
import { Dropdown, Button, Modal } from 'react-bootstrap';

import {DoDo, ScratchBackUrl,DisplayOpenaiMessages} from '../Utils/gptCall.js'
//import AiQnaList from "./AiQnaList.js"
import {immitateSaveRecord, Get_ScratchBack_Records, Get_CnvIds, Rename_CnvId, Remove_CnvId, DotDotDot, UseEventListener} from "../Utils/MiscForApp.js"
import {ConversationDropdownButton, Modal123, BlobDisplay} from "../Utils/Misc2.js"
import "../App.css";
import {QnA_List, QnA_Unit}  from '../Logic/CnvClasses.ts'
import {CnvScroll, ParticipantSelect} from "./CnvScroll.js"
import AiPage_ModalParams from "./AiPage_ModalParams.js"

// - works only on server node.js .. import { readFileSync, writeFileSync } from 'fs' //
import MiscParamsDefault from "./MiscParams.json"

// alternative technique:

import {Apd,ApdEx,AiPageDefaults, AdvParamsEdit, AdvParamsEdit2, ApdState_Key} from "./AiPageDefaults.js"
import { Checkbox, getNativeSelectUtilityClasses } from "@mui/material"
import { GridToolbarDensitySelector } from "@mui/x-data-grid"

////////////////////// end of imports //////////////////////
const MiscParamsLocalStorageKey="AiPageMiscParams"

console.log("==> loading aipage.js")
// console.log("==> AiPageDefaults: " + JSON.stringify(AiPageDefaults));
// console.log("==> Apd: " + JSON.stringify(Apd()));
// naive  attempt to use mongo from browser
// import dbStartConection from "../DbStuff/DbStart.js"
// import { saveRecordInner} from "../DbStuff/DbComms.js"


const AiPage_QuestionStorage_Key='AiPage_QuestionStorage_Key'
const AiPage_CnvId_Key='AiPage_CnvId_Key'



const AiPage = (props) => {
    //----------------------------------------------------------
    const [showModalDailog, setShowModalDialog] = useState(false)

    function onCloseModalDialog() {setShowModalDialog(false)}
    //----------------------------------------------------------


    const [question, setQuestion] = useState([])
    const [answer, setAnswer] = useState("no answer yet")
    const [query, setQuery] = useState('no query yet')
    

    const [rrCount, setRrCount] = useState(0)

    const [cnvDebugHtml, setCnvDebugHtml] = useState(null)
    const navigate = useNavigate()

    //const [noPreface, setNoPreface] = useState(false)

    //
    //const [conversationId, setConversationId] = useState('Default Conversation') 
    const [cnvRecords, setCnvRecords] = useState(null)
    

    const defaultaCnvIdCurrent = 'New Current Conversation'
    const [cnvIdCurrent, setCnvIdCurrent] = useState(defaultaCnvIdCurrent) 
    const [cnvIds, setCnvIds] = useState([defaultaCnvIdCurrent]) 

    const [miscParams, setMiscParams] = useState(null)
    const [miscParamsDialogOn, setMiscParamsDialogOn] = useState(false)

    const [jsonData, setJsonData] = useState(null);
    const [universalDict, setUniversalDict] = useState({})


    

    const [isRemoting, setIsRemoting] = useState(false) // i.e. is waiting for server

    // ----------- pnifs: ParticiNamesInFocus
    const [pnifs, setPnifs] = useState([])
    function Pnifs_Change(new_pnifs) {
        setPnifs(new_pnifs)
    }
    // -----------------------------------------
    const [apdState, setApdState] = useState(AiPageDefaults)
    // function ApdState_Change(new_apdState) {
    //     setApdState(new_apdState)
    // }
    // ------------------------------------------

    console.log('AiPage render ' + question)

    const questionElRef = useRef()
    const mmmRef = useRef()
    const cnvAssemblyUi = useRef()
    const blobWindowRef = useRef(null); // for 

    const jsonData_Fpath = "./MiscParams.json"

    useEffect(()=>{

        //way_JustIniting = dbStartConection 

        
        
        // const savedApdState_Str = window.localStorage.getItem(ApdState_Key) 
        // if (savedApdState_Str) {
        //     const savedApdState = JSON.parse(savedApdState_Str)
        //     setApdState(savedApdState)
        // }

        

        const prevQuestion = window.localStorage.getItem(AiPage_QuestionStorage_Key) || 'starting from storage'
        console.log('[] UseEffect fired: question:' + question)
        setQuestion(prevQuestion)
        console.log('[] UseEffect fired: prev question:' + prevQuestion)
        questionElRef.current.value = prevQuestion // -<- this is instead of value={question} crap ?
        
        // when component is mounted :
        // const data = readFileSync(jsonData_Fpath);
        // setJsonData(JSON.parse(data));

        
        // Grab arameters .. it's a mess for now
        const defaultMP = Apd()  
        console.log("==> Apd: " + JSON.stringify(Apd()));
        let jd = window.localStorage.getItem(MiscParamsLocalStorageKey)
        try{
            jd=JSON.parse(jd)
        } catch {
            jd = null
        }

        //if (!defaultMP.Override && !jd) jd = Apd() //AiPageDefaults //MiscParamsDefault

        // for now
        jd = Apd() //AiPageDefaults //MiscParamsDefault
        setJsonData(jd)
        //----------------------------------------------


        // Initiate loading records
        const storedCnvId = window.localStorage.getItem(AiPage_CnvId_Key) || defaultaCnvIdCurrent
        //  initiate geting conversations from mongo
        Get_CnvIds().then((cnvIdsFromMongo)=> {
                setCnvIds(cnvIdsFromMongo) // will end up in cnvIds on the next react cycle
                if (cnvIdsFromMongo.includes(storedCnvId)) {
                    setCnvIdCurrent(storedCnvId)
                    LoadCnvRecords(storedCnvId)
                } else if (cnvIdsFromMongo.length > 0) {
                    const theFirstFromMongo = cnvIdsFromMongo[0]
                    setCnvIdCurrent(theFirstFromMongo)
                    LoadCnvRecords(theFirstFromMongo)
                } else {
                    //???
                }
            })
            .catch((err) => {
                LogError(err)
            });
        
         return () => {
            // This code will run when the component is unmounted
            console.log('Component is unmounted.');
            // Do any cleanup work here, such as removing event listeners or clearing intervals
            };

        }
    ,[])

    useEffect(()=>{
        if (jsonData != null) {
            // see design note ["closure" of jsonData happens only once on the mounting]
            console.log('==> jsonDat on useEffect(..[jsonData]) ' + JSON.stringify(jsonData));
            window.localStorage.setItem(MiscParamsLocalStorageKey,JSON.stringify(jsonData))
        }} 
    ,[jsonData])


    //////////////////////////////////////////////////////

    // by default is set up for the whole window
    UseEventListener('keydown', (event) => { //omg
        if (event.key === "Delete" || event.key === "del") {
          onCnvAction('Cut1')
        }
    });
////////////////////////////////////////////////    

    const miscParams_CallBack = (jData) => {
        // see useEffect(..[jsonData]) and  design note ["closure" of jsonData happens only once on the mounting]
        setJsonData(jData) //{ ...jsonData, [key]: value });
        // and what the hell - update the whole local storage on every call?
        //window.localStorage.setItem(MiscParamsLocalStorageKey,JSON.stringify(jData))
    }
//////////////////////////////////////////////////////////////////////////

    const goBack = async (e) => {
        e.preventDefault()
        // memorize current
        window.localStorage.setItem( AiPage_CnvId_Key, cnvIdCurrent)

        navigate('/')
        console.log('AiPage, wen bac to /')
    }
    // console.log('AiPage rendered?')

    




    // const findConversationById = async (id) =>{

    //     try {
    //         const postData = {conversationId: id}
                
    //         console.log("AiPage.onFormSubmit: before post: " + JSON.stringify(postData));
    //         //const response = 
    //         await axios.post(ScratchBackUrl + '/findConversations'
    //                         , postData);
        
    //         } catch (error) {
    //             console.log("AiPage.onFormSubmit: error in post: " + JSON.stringify(error.response.data));
    //         }

    // }


    const createAndRecordQnaUnit =  (qnal, txt, auth, targets = null) => {
        const newQnaUnit = qnal.createQnaUnit(
            {txt : txt, auth:auth,  targets: targets } );
        return recordQnaUnit2(newQnaUnit, qnal)
    }

    const recordQnaUnit2 =  (newQnaUnit, qnal) => {
        // are not more than a question (maybe) and an answer
          // i will record them one at a time 
          
          try {
  
             
              const newRec = QnA_Unit.Record_From_Unit(newQnaUnit)
           
  
  
              const postData = newRec 
                  
              //console.log("AiPage.onFormSubmit: before post: " + JSON.stringify(postData));
  
              // Saving one at a time?
              //const response = 
                axios.post(ScratchBackUrl + '/saveRecord'
                              , postData);
              
              // after it SUCCESSFULLY saved:               
              // cnvRecords <===> QnA_List
              
              if (newQnaUnit) qnal.add(newQnaUnit)
  
              // make sure cnvscroll renders ? :
              const recs = qnal.generateRecords()
              setCnvRecords(recs)
     
  
              return true
  
          } catch (error) {
            LogError('Error on post', error.response.data)
              //console.log("AiPage.onFormSubmit: error in post: " + JSON.stringify(error.response.data));
            return false
          }
      }

      function fff() {
        // Get the JSON object from the URL query parameter
        // const url = new URL(window.location.href);
        // const jsonString = url.searchParams.get('data');
    
        // // Parse the JSON string into an object
        // const jsonObject = JSON.parse(jsonString);

        const jsonObject = {
            aaa: 'aaa',
            bbb: 123
          };
    

        //   const blob = new Blob([JSON.stringify(jsonObject, null, 2)]
        //   , {type : 'application/json'});
        //   const url = URL.createObjectURL(blob);
      
        //   window.open(url);

            BlobDisplay(jsonObject, blobWindowRef)
          return 

        // Set the response headers
        const headers = new Headers();
        headers.set('Content-Type', 'application/json');
    
        // Create a new response with the JSON object
        const response = new Response(JSON.stringify(jsonObject), {
          headers
        });
    
        response.clone().text().then((data) => {
            // Pass the data to postMessage
            window.parent.postMessage(data, '*');
          });
        // // Return the response
        // window.parent.postMessage(response, '*');
      }

      const returnPosdataJson = async (xxx = null) => {
        try {


            const postData = xxx 

            let baseUrl = ScratchBackUrl
            baseUrl = 'http://localhost:5000'

            fff();
            return;

            // const resMongo = await axios.post(baseUrl + '/returnPosdataJson'
            //                 , postData);

            window.parent.location.href = baseUrl + '/returnPosdataJson/:test' //"http://www.ibm.com"

            const ret = await axios.get(baseUrl + '/returnPosdataJson/:test');
            return ret
        } catch (error) {
            //LogError('Error on post', error)
            throw new Error("returnPosdataJson: " + JSON.stringify(error));
            //console.log("AiPage.onFormSubmit: error in post: " + JSON.stringify(error.response.data));
            return false
        }
      }

    const QnaUnits_SaveMany = async (newQnaUnits) => {
        try {
            const newRecs =[]
            for (let i = 0; i < newQnaUnits.length; i++) {
                const newQnaUnit = newQnaUnits[i]
                const newRec = QnA_Unit.Record_From_Unit(newQnaUnit)
                newRecs.push(newRec)
            }

            const postData = newRecs 
            const resMongo = await axios.post(ScratchBackUrl + '/saveMany'
                            , postData);
            return resMongo

        } catch (error) {
            //LogError('Error on post', error)
            throw new Error("QnaUnits_ManyMany: " + JSON.stringify(error));
            //console.log("AiPage.onFormSubmit: error in post: " + JSON.stringify(error.response.data));
            return false
        }
    }


    
    function findJsonForAuth(auth) {
        const particpantsInfo = jsonData["participants"]
        if (!particpantsInfo) { particpantsInfo = Apd()}
        // particpantsInfo.forEach((p, index)=>{
        //     if (p.name == auth || p.dname == auth) 
        //         return p
        // });
        for (let index = 0; index < particpantsInfo.length; index++) {  
            const element = particpantsInfo[index];  
            if (element.name == auth )
                return element 
        }  
        return null
    }




    const recordHuman = async(qnal,txt,auth) => {
        createAndRecordQnaUnit(qnal,txt,auth)
        // momrize in local storage for onvenience
        window.localStorage.setItem(AiPage_QuestionStorage_Key, txt)
    }



    const participantDoRecord = async (e, particpant, apdState, txt) => {
        e.preventDefault()
        const auth = particpant.name
        const apdEx = ApdEx()
        //const emulateCallFn = (apdEx.apd.emulate.calls ? apdEx.genEmulateCall(auth) : null)
        const emulateCallFn = (apdState.emulate.calls ? apdEx.genEmulateCall(auth) : null)
        
        const apdForName= findJsonForAuth(auth)
        console.log('------------------\n participantDoRecord: ' )

        let qq =  questionElRef.current.value    

        // this qnal will (must?) cnvId == cnvIdCurrent
        let qnal = QnA_List.createFromRecords(cnvRecords,cnvIdCurrent)
        
        if (apdForName.isHuman) { // later just check aqd for that 
            // there is no need to ask gpt, just record the question
            // Get the text of Human's box
            
            if (qq === undefined || qq === null || qq.trim() === '') {
                alert('No Empty Questions!');
                return;
            }

            // user particularly recorded:
            await recordHuman(qnal,qq,auth)
            
        } else {
  

            const lastUnitByHuman = qnal.getLastUnitByAuth("me")
            if (lastUnitByHuman == null || qq && lastUnitByHuman.txt.trim() != qq.trim())
            {   // user did not explicitly recorded this particular text
                // before submitting to inhumans :) 
                setIsRemoting(true) 
                await recordHuman(qnal,qq,"me")
                // erase the message in the question box
                questionElRef.current.value = ''
                setIsRemoting(false)
                
                
                if (false) { // does not work ..
                    // redo qnal (records get updated on mongo)
                    const recs = await LoadCnvRecords(cnvIdCurrent)
                    if (recs == null) return // rely on the bitching  inside LoadCnvRecords

                    qnal = QnA_List.createFromRecords(recs,cnvIdCurrent)
                } else if (false) { // just build qnl assuming that new human record is already there
                    const newQnaUnit = qnal.createQnaUnit(
                        {txt : qq, auth:"me",  targets: null } )
                    qnal.add(newQnaUnit)
                }
                // and proceed with ai
                
            }

            let  mood="try to be concize"
            //const mood = moodEl.current.value

            //let gptAnswer = "default answer"

            // parse previus converstaion and build:
            let units = qnal.getUnitsFor(auth)

            // now massage units according to apdState
            

            //let messages = null
            setIsRemoting(true) 
            const gptAnswer = await DoDo({ qnaUnits: units
                , question:qq
                , mood:mood
                , questionToAuth:auth
                , udCallBack:udCallBack
            , emulateCallFn: emulateCallFn})
            setIsRemoting(false) 
            
            // record :
            // const newQnaUnit = new QnA_Unit({txt : qq, prefix :null, suffix : null, 
            //     author:auth, targets: null} );
            // recordQnaUnit(newQnaUnit, qnal)
            createAndRecordQnaUnit(qnal,gptAnswer,auth)



        }

   
        


    }



    // const handleQuestionChange = (e) => {
    //     //setQuestion(this.value)
    //     console.log('handleQuestionChange')
    // }
    // const handleQueryChange = (e) => {
    //     //setQuery(this.value)
    //     console.log('handleQueryChange')
    // }

    

    //------------------------------------------
    const doExp = async (e) => {
        // e.preventDefault()
        // setRrCount(rrCount + 3)
        // alert('rrCount: ' + rrCount)

        // //chatDebug:messages
        // // alert('xxx: ' + rrCount + '\n f: ' + F(rrCount) + '\n g: ' + G(rrCount) )
        // // return
        const NODE_ENV = process.env.NODE_ENV

        
        const xxxxx = await returnPosdataJson();
        return

        alert('apdState.cnvAssembly.removeAsAi ' + apdState.cnvAssembly.removeAsAi)
        alert('NOE_ENV: ' + NODE_ENV + '\n' + 'ScratchBackUrl: ' + ScratchBackUrl)

        

        // alert(cnvIdCurrent) do modal?
        const chatDebug = universalDict['chatDebug']
        const chatData = universalDict['chatData']
        if (chatDebug || chatData) {
            const dbgInfo = {}
            if (chatDebug) dbgInfo['input'] = chatDebug
            if (chatData) dbgInfo['output'] = chatData
            
            DisplayOpenaiMessages(dbgInfo)

            const xxx = JSON.stringify(dbgInfo, null, 2)
            alert(xxx)
        } else {
            //alert()
        }

        
        
        if (false) {
        await immitateSaveRecord(rrCount + 1)
        setRrCount(rrCount + 1)
        } else if (cnvDebugHtml) {
            setCnvDebugHtml(null)
        } else {
            //f('zero', {arg1 : "dsd"},'dada')
            const lsep = "<br/>"
            const txt = QnA_List.createFromRecords(cnvRecords, cnvIdCurrent).combined(lsep)
            
            console.log('==> QnA_List : ' + txt)
            
            setCnvDebugHtml(txt)
            
            console.log(txt)
        }
    }

    // const cnvCallback = function ({records}) {
    //     // const records = [
    //     //   {q:"hi there", a:"hello, i am machine"},
    //     //   {q:"who are you?", a:"i am machine, stupid"},
    //     // ]
    //     setCnvRecords(records)
 
    //   }

    const udCallBack = function(universalDictChange) {
        Object.keys(universalDictChange).forEach((key,index) => {
           universalDict[key] = universalDictChange[key]  
        });

        setUniversalDict(universalDict)



        if (universalDictChange["targetChange"] 
            || universalDictChange["contentChange"]) {
            const qnal = QnA_List.createFromRecords(cnvRecords,cnvIdCurrent)
            const changedQnaUnits = qnal.udCallBack(universalDictChange) 
            // do it poshtuchno:
            if (changedQnaUnits) {
                //recordQnaUnit(newQnaUnit, qnal)
                for (let i = 0; i < changedQnaUnits.length; i++) {
                    const updateQnaUnit = changedQnaUnits[i]
                    
                    const updateRec = QnA_Unit.Record_From_Unit(updateQnaUnit)
            
                    const postData = updateRec //JSON.stringify(updateRec)
                           
                    // Saving one at a time?
                    let prom = axios.post(ScratchBackUrl + '/updateRecord'
                                    , postData).then((result) => {
                                        console.log("==> updateRecord result :" + JSON.stringify(result))
                                        // Do something with res
                                        // make sure cnvRecords changed ? :

                                        LoadCnvRecords(cnvIdCurrent)

                                        // const recs = qnal.generateRecords()
                                        // setCnvRecords(recs)

                                    })
                                    .catch((error)=>{
                                        LogError('Record Update Failure', error)
                                        //console.log("==> updateRecord error :" + JSON.stringify(error))
                                        //error.response.data.message
                                    });
      


                }
            }
            
            //qnal.
        }
    }

    //----------------------------------------------
 
        
    const doRemoveFromTo = async (how) => {
        //if (e) e.preventDefault()
            

        let cutFromIdx = null
        if (universalDict == null) {
            alert("nothing is selected to remove")
            return 
        }

        if ('markedXunitIdx' in universalDict) {
            cutFromIdx = universalDict['markedXunitIdx']
            // .. build appropriate filter for mongo
        }

        if (how == 1) // above and itslef
            doRemoveInterval({iFrom:0, iTo : cutFromIdx + 1})
        else if (how == -1) // below and itslef
            doRemoveInterval({iFrom:cutFromIdx, iTo: null})
        else // only one
            doRemoveInterval({iFrom : cutFromIdx,iTo : cutFromIdx + 1})
    }

    const doRemoveInterval = async (iFrom=null, iTo=null) => {
            
            


        const qnal = QnA_List.createFromRecords(cnvRecords,cnvIdCurrent)
        try {
            
            const postData = {filter: qnal.BuildFilter(cnvIdCurrent, iFrom=iFrom, iTo=iTo)}
            console.log("...");

            //const p = 
            //let prom = axios.post(ScratchBackUrl + '/deleteRecords'
            //                , postData)

            // prom.then((res)=>{
            //     // refresh what's left from the records
            //     LoadCnvRecords(cnvIdCurrent)

            // })
            // .catch((err)=>{
            //     LogError('Removing error', err)
            // })
            // // 
            
            // - less fancy but simpler with await ()
            let response = await axios.post(ScratchBackUrl + '/deleteRecords'
            , postData)
            LoadCnvRecords(cnvIdCurrent)
   
            // tigger the rendering
            setRrCount(rrCount + 1)
            

        } catch (error) {
            LogError('Removing asertion', error)
            // setCnvDebugHtml(JSON.stringify(error.response.data))
            // console.log("AiPage.doRemove: error in post: " + JSON.stringify(error.response.data));
        }

        
    }

//////////////////////

    function promptUniqueCnvId(prefice, oldCnvId) {
        let cnvId = prompt(prefice,oldCnvId)
        while (true) {
            if (cnvId == null) return null

            cnvId = cnvId.trim()

            if (cnvIds.findIndex(element => element == cnvId) >= 0) {
                cnvId = prompt("already exists:",cnvId)
                
            } else
                return cnvId
        }
        

    }

    function onCnvAction(action) {
        //alert(cnvIdCurrent)
        //return 

        if (action == 'New') {

            
                    
            const newCnvId = promptUniqueCnvId("New : ",cnvIdCurrent)
            if ( newCnvId) {

                // change list
                const newCnvIds = [newCnvId,...cnvIds]
                setCnvIds(newCnvIds)

                // change selected
                setCnvIdCurrent(newCnvId)
                LoadCnvRecords(newCnvId) // - should clean out dipslay
            }

            return 
        }

        

        
        if (action == "CutAbove") {
            doRemoveFromTo(+1)
            return
        }

        if (action == "Cut1") {
            doRemoveFromTo(0)
            return
        }
        
        if (action == "CutBelow") {
            doRemoveFromTo(-1)
            return
        }

        if (action == "Rename") {
            const oldCnvId = cnvIdCurrent
            const newCnvId = promptUniqueCnvId("Rename : ",cnvIdCurrent)
            if (newCnvId) {
        
                Rename_CnvId(oldCnvId,newCnvId).then((res)=> {
                    // assume success, change the the array of cnvIds here :
                    const foundIdx = cnvIds.findIndex(element => element == oldCnvId);
                    if (foundIdx == -1) {
                        LogError('Could not find old conversation ', oldCnvId)
                        return
                    }

                        cnvIds[foundIdx] = newCnvId
                        setCnvIds(cnvIds)

                        setCnvIdCurrent(newCnvId)
    
                    }) // i.e. visuals on the client will be rerended in case of success on the server
            
                    .catch((err) => {
                        LogError('Rename error', err)
                        //console.log(err)
                })
            }
            return
        }

        if (action == "Remove") {

            
                Remove_CnvId(cnvIdCurrent).then((res)=> { // res == {acknowledged: true, deletedCount: 0}
                        // assume success, change the the array of cnvIds here :
                        const foundIdx = cnvIds.findIndex(element => element == cnvIdCurrent);
                        cnvIds.splice(foundIdx, 1)
                        setCnvIds(cnvIds)

                        const nextCnvId = cnvIds[foundIdx]
                        setCnvIdCurrent(nextCnvId)
                    
                        LoadCnvRecords(nextCnvId)

                    } // of then's function()

                    ) // i.e. visuals on the client will be rerended in case of success on the server
            
                    .catch((err) => { 
                        LogError('Remove error', err)
                        //console.log(err) //err.response.data.message
                    }
                    )

                return
        
        }
        
        if (action == "Clone") {
            
            const oldCnvId = cnvIdCurrent
            const cloneCnvId = promptUniqueCnvId("Clone : ", cnvIdCurrent)
            if (cloneCnvId) {
                const oldQnal = QnA_List.createFromRecords(cnvRecords,cnvIdCurrent)
                const cloneQnal = oldQnal.cloneOf(cloneCnvId)
                const recs = cloneQnal.generateRecords()
                QnaUnits_SaveMany(recs).then((res) => {
                    
                    const newCnvIds = [cloneCnvId,...cnvIds]
                    setCnvIds(newCnvIds)

                    setCnvIdCurrent(cloneCnvId)
                
                    LoadCnvRecords(cloneCnvId)
                })
                .catch((err) => {
                        LogError('cLONE error', err)
                    }
                )
    
            }
            return
    
        }
    }
    

    //////////////////////

    function LogError(prefix, error) {
        //null, 2
        let errPrefix
        let errObject = null
        if (!error) {
            if (prefix) errPrefix = prefix
            else errPrefix = 'no info'
            errObject = null
        } else if(!error.response) {
            errPrefix = 'no response in error : '//+ JSON.stringify(error)
            errObject = error
        }else if(!error.response.data) {
            errPrefix = 'no data in response : '// + JSON.stringify(error.response)
            errObject = error.response
        }else if(!error.response.data.message) {
            errPrefix = 'no message in data : '// + JSON.stringify(error.response.data)
            errObject = error.response.data
        }else {
            errPrefix = '' //error.response.data.message
            errObject = error.response.data.message
        }

        let finalMessage = errPrefix 
        if (errObject) finalMessage = finalMessage + ' : ' + JSON.stringify(errObject,null,2)
        //error.response.data.message
        //error.response.data
        console.log("==> LogError : " + finalMessage)

        setCnvDebugHtml(finalMessage) //JSON.stringify(error.response.data))
        
        //something user should see and dismiss'
        alert(finalMessage)

    }

    // async function LoadCnvRecords(cnvId) { // Very asynchronous! 
    //     const p = Get_ScratchBack_Records(cnvId)
    //     p.then((recs) => {
    //         setCnvRecords(recs);
    //     }).catch((err) => {
    //         LogError('Failed to load records', err)
    //         //console.log(err)
    //     });
    // }

    async function LoadCnvRecords(cnvId) { // Very Synchronous! 
        try {
            const recs = await Get_ScratchBack_Records(cnvId)
            setCnvRecords(recs);
            return recs // not waiting for them to be set!
        } catch(err) {
            LogError('Failed to load records', err)
            return null
        }
    }

    function handleCnvChange_UNUSED(selectedIndex, selectedValue, selectedText){
        if (selectedIndex == -1) { // new conversation
            // create new conversation with default name and let it change user once? in a modal dialog
            
            let uniqCnvId 
            if (selectedValue) {
                uniqCnvId = selectedValue
            } else {
                const nowDt = new Date()
                const formattedDate = nowDt.toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true
              });
              uniqCnvId = defaultaCnvIdCurrent + ' ' + formattedDate
            }



            // insert new converstaion into cnvIds
          
            // newCnvIds = cnvIds.slice(0,cnvIds.length)
            // newCnvIds.unshift(uniqCnvId)
            const newCnvIds = [uniqCnvId,...cnvIds]
            setCnvIds(newCnvIds)
            // set current to it
            setCnvIdCurrent(uniqCnvId)
        } else {
            setCnvIdCurrent(selectedValue)
            LoadCnvRecords(selectedValue)
        }
    }


    const  cnvFunctions=[
        {
          onActF: () => onCnvAction('New'),
          dname: 'New Conversation',
        },
        {
          onActF: () => onCnvAction('Clone'),
          dname: 'Clone Conversation',
        },
    
        {
            onActF: () => onCnvAction('Rename'),
            dname: 'Rename Conversation',
        },
    
        {
            onActF: () => onCnvAction('Remove'),
            dname: 'Remove Conversation',
        },
      
        {
            dname: '--------------------------------',
            className: 'function-item',
        },
    
    
        //see shortcuts in useEffect
        { onActF: () => onCnvAction('CutAbove'),
            dname: 'Cut To Start',
        },
        { onActF: () => onCnvAction('Cut1'),
            dname: 'Cut Selected',
            className: 'function-item',
        },
        { onActF: () => onCnvAction('CutBelow'),
            dname: 'Cut To End',
            className: 'function-item',
        },
    
        {
            dname: '--------------------------------',
            className: 'function-item',
        },
    
        { onActF:doExp,
            dname: 'Exp',
            className: 'function-item',
        },
    ]

/////////////////////////////////////////////////////////////////


// i show probably merge selectedCnvId === with === cnvIdCurrent
//const [selectedCnvId, setSelectedCnvId] = useState(null);

//const [selectedIndex, setSelectedIndex] = useState(null);
const [showDropdown, setShowDropdown] = useState(false);
// const questionElRef = useRef(null)


//const dispatch = useDispatch();


// const participantDoRecord = async (e, particpant, txt) => {
//   e.preventDefault()

//   const auth = particpant.auth

//   dispatch({ type: 'ADD_REMARK', payload: { auth: auth, text: txt } });
// }

function handleCnvIdClick(index, cnvId) {

    // connectiion with "the old world"
    //handleCnvChange(123, cnvId, null)
    setCnvIdCurrent(cnvId)
    // - that will NOT happen thru effect (there is no such effect yet)
    LoadCnvRecords(cnvId)

    // i show probably merge selectedCnvId === with === cnvIdCurrent
    //setSelectedCnvId(cnvId);
    //setSelectedIndex(index);
    setShowDropdown(false);
  // not defined .. if (onCnvIdClick) onCnvIdClick(index, cnvId);
}

function handleFunctionSelection(func) {

  setShowDropdown(false);
  if (func && func.onActF) func.onActF();
}

function onCnvFunctionsCLick(e) {
  setShowDropdown(!showDropdown)
}
/////////////////////////////////////////////////////////////////


    const ppp = AiPageDefaults.participants

    return (
      <div style={{ display: "flex", flexDirection:"row", width:"100%", height: "100vh" , border: '10px solid green'}}>

    

        <div id="cnvLeftPane" style={{minWidth: '400px', width:'400px',
            display: "flex",
            flexDirection: "column",
            margin:"10px"

            , position: "relative" // so children can become absolute
            ,"overflow-y": "auto" 
            }}>
      
            <div className="cnvLeftPane_header"
                style={{  display: "flex"
                    , "align-items": "center"
                    , "flex-wrap": "nowrap"
                    ,"margin-left": "8px"
                    }}
            > {/* Add this div */}
                <button title="Actions" 
                    onClick={onCnvFunctionsCLick}            
                    >
                        
                        {/* <img src={DownArrow} alt="Functions??" /> */}

                    <i className="material-icons" style={{ color: 'white' }}>expand_more</i>
                </button> 

                {!cnvIdCurrent && <div>Header</div>}
                {cnvIdCurrent && <div>Current : <span className='cnvIdSelected'>{cnvIdCurrent}</span></div>}

            </div> {/* of header container */}

            <div className="cnvLeftPane_underheader"
                    style={{
                    display:"flex",
                    flexDirection:"column",
                    position:"relative",
                    border:"black solid 3px"
                    }}
                >
                
                <div id="FunctionsDiv" className="cnvFunc_list"
                              style={{position:"absolute"}}
                    >
                    {/* {showDropdown && (<ul>
                    {cnvFunctions.map((func) => (
                        <li key={func.dname} onClick={() => handleFunctionSelection(func)}>
                        {func.dname}
                        </li>
                    ))} 
                    </ul>)
                    */}

                    { showDropdown && 
                        (<CnvFuncList cnvFunctions={cnvFunctions}
                        handleFunctionSelection={handleFunctionSelection} />)
                    }
                    
                
                </div> {/* of cnvLeftPane_underheader */}

                <div id="ItemsDiv" className="cnvId_list"
                    style={{position:"absolute"}}
                >
                    <CnvIdsList cnvIds={cnvIds} 
                        selectedCnvId = {cnvIdCurrent}
                        onCnvIdClick={handleCnvIdClick} 
                         />


                    {/* ------------------------ here are 'advanced' features */}
                    <div id="advancedFeatures" 
                        style={{
                            //position:"relative",
                        display:"flex", flexDirection:"column", 
                        border: "10px solid red"}}>

                        {/* <DebugComponent rrCount={rrCount} /> */}

                        {/* ----------------- */}
                        {/* <DisplayOpenaiMessages /> */}
                        <button variant="primary"   
                            onClick={()=>setShowModalDialog(true)}>
                            Launch demo modal
                        </button>


                        <AdvParamsEdit2 apdState = {apdState} setApdState={setApdState} apdStateDefault = {AiPageDefaults}/>

                        {/* ---------------- Pnifs --------------------- */}


+++++++++++
                        {/* <ParticipantSelect selectedNames={pnifs}
            handleChange={Pnifs_Change}
            participants={ppp} /> */}
+++++++++++++



        <br/> 
===================
                        {/* <AdvParamsEdit apdState = {apdState} setApdState={setApdState}
                                participants={ppp} pnifs={pnifs} handlePnifsChange={Pnifs_Change} /> */}
====================


                        <button data-open-modal onClick={(e)=> mmmRef.current.show()}>
                            Conversation Hihglight
                        </button>

                        <br/>

                        <dialog data-modal ref={mmmRef} /* draggable="true" */
                            style={{
                                width:"100%", height:"100%", // insetInline.it should fill the whoe advnce  
                                backgroundColor: "grey",
                                // position: "absolute",   // set position to absolute
                                // top: "50%",            // set a starting position for the dialog
                                // left: "50%",
                                // transform: "translate(-50%, -50%)",   // move it to the center of the screen
                                zIndex: "999",         // set a high z-index value to make sure the dialog appears on top of other elements
                              }}
                            >


<AdvParamsEdit apdState = {apdState} setApdState={setApdState}
                                participants={ppp} pnifs={pnifs} handlePnifsChange={Pnifs_Change} />
 <button
    onClick = {(e) => {
        mmmRef.current.close()
    }}
>Close Edit</button>
{/* 
                            Conversation Hihglight
                            <div >
                                <ParticipantSelect selectedNames={pnifs}
                                    handlePnifsChange={Pnifs_Change}
                                    participants={ppp} />
                                <br/>

                                <div ref={cnvAssemblyUi}>
                                <input type="checkbox" name="removAsAi" value="{apdState.cnvAssembly.removeAsAi}"/>Remove 'as ai'<br/>      
                                <input type="checkbox" name="combineMe" value="Dogs"/>Dogs<br/>      
                                <input type="checkbox" name="lie" value="Birds"/>Birds<br/>
                                </div>

                                <button data-close-modal
                                    onClick = {(e) => {
                                        const x = cnvAssemblyUi.current.querySelector('input[name="removAsAi"]').checked 
                                        apdState.cnvAssembly.removeAsAi = x 
                                        setApdState(apdState)
                                        alert(x)
                                        mmmRef.current.close()
                                    }}
                                >Close</button>
                            </div> */}
                        </dialog> 

                        


                        {/* -------------------------------------------------- */}

                        {/* document.querySelector("[data-modal]")

                        { universalDict['chatDebug'] ?

                            <Modal123 show={showModalDailog} onClose={onCloseModalDialog}
                                                bodyComponent={<DisplayOpenaiMessages 
                                                    messages={
                                                            universalDict['chatDebug']
                                                        } />}
                            />
                                                        : 'no gpt messages yet'

                        }

                        {/* ----------------- */}

                        <Link to={{pathname:"../ParamsPage", par0:"dasdads"}} aaa={123} bbb='dasad'>
                                Change parameters.
                        </Link>

                        {/* ----------------- */}
                        <ConversationDropdownButton
                            onNewConversation={()=>alert('aaa')}
                            onMarkAsRead={()=>alert('bbb')}
                            onDelete={()=>alert('ccc')}
                        />
                        {/* ----------------- */}

                        <button  onClick={e => {
                            setMiscParamsDialogOn(true)
                        }} > Change Parameters Modal </button>

                        <AiPage_ModalParams onClose={(arg)=>{setMiscParamsDialogOn(false)}} show={miscParamsDialogOn} 
                            callBack={miscParams_CallBack} 
                            jsonData={jsonData}
                        />


                    </div>{/*  advancedFeatures */}
                </div>
            </div>
 




        </div> {/* of left pane */}





        <div id="cnvRightPane" style={{ /* width: "100%", */
            border: "3px black solid",

            flexGrow: 1, /* i.e. take the rest of space ? */
        /*  */    display: "flex",
            flexDirection: "column",
            padding: "8px", 
            margin:"10px"
    
            }}>


  

            <CnvScroll  udCallBack={udCallBack} 
            records={cnvRecords} 
            participants={ppp} 
            pnifs={pnifs}/>

            {/* -------------- participants ---------- */}
            <div id="participantStrip" style={{
                display:"flex"
                // ,"align-content": "flex-end" 
                , gap: "40px"
                //, alignItems:"end"
                , justifyContent: "center"
                , flexDirection:"row"
                }}>

                <DotDotDot isRemoting={isRemoting} />
                    
                {ppp.map((participant, index)=>{
                        return <button className="participantButton" 
                                onClick={(e)=> 
                                participantDoRecord(e, participant, apdState, questionElRef.current.value)} 
                                >
                            {participant.dname}</button>
                        })
                }
            </div>

      <textarea name="queryArea" 
          style={{ 
            width: "100%",
            height: "100px", // Adjust this value as needed
            boxSizing: "border-box",
            resize: "none",
            margin: 0,
            padding: 0,
            outline: "none",
            border: "1px solid #ccc",
          }} 
          ref = {questionElRef}
        >
        {/* does not matter what's here because it is a controlled component??? {question} */}
          cz zxc zcx xzkc zl kczc;lxz cxz;l kcz;c zcx
      </textarea>
    
        </div> {/* of cnvRightPane */}


      </div>
    );

} 


function CnvFuncList({cnvFunctions, handleFunctionSelection}) {
    return (<ul 
        onMouseLeave={() => {
            handleFunctionSelection(null);
        }}>

        {cnvFunctions.map((func) => (
                        <li key={func.dname} onClick={() => handleFunctionSelection(func)}>
                        {func.dname}
                        </li>
                    ))
        }
    </ul>)
}

function CnvIdsList({ cnvIds, selectedCnvId, onCnvIdClick }) {


    function handleItemClick(index, cnvId) {
      onCnvIdClick(index, cnvId);
    }


  
    return (
      <div>
        <ul 
          style={{ overflowY: 'auto', listStyleType: 'none', margin: "0px" }}>
            
          {cnvIds.map((cnvId, index) => (
            <li
              key={cnvId} //{item.dName}

              className={`${cnvId.className} ${selectedCnvId === cnvId ? 'cnvIdSelected' : ''}`}
              onClick={() => handleItemClick(index, cnvId)}
            >
              {/* <i className="fas fa-star" style={{ marginRight: '8px' }}></i> */}
              <span className="material-icons" style={{ width:'', marginRight: '8px' }}>chat</span>
              {/* <span className="material-symbols-outlined" style={{marginRight: '8px' }}>home</span> */}
              {cnvId} {/* {item.dName} */}
            </li>
          ))}
        </ul>
      </div>
    );
}

const DebugDisplay = (props) => {
    const html = props.html
    const cnvRecords = props.records
    const lsep = "<br/>"
    const qnal = QnA_List.createFromRecords(cnvRecords,props.cnvId)

   
    let pars = []
    const units = qnal.getUnits()
    for (let i = 0; i < units.length; i++) {
        const u = units[i]
        let paragraph = u.combined()
        pars.push(<div><div>{u.q}</div><div>{u.a}</div></div>)
    }
    return (
        <div className="cnvDebugClass">
            {pars.map(dd=>dd)}
        </div>
         
    )

    qnal.combined(lsep)

    console.log("==> got cnvRecords")
    //return <div>{html}</div>
    //https://stackoverflow.com/questions/36104302/how-do-i-convert-a-string-to-jsx
    return (
        <div className="Container cnvDebugClass" dangerouslySetInnerHTML={{__html: html}}></div>
      )

}


export default AiPage;

//////////////////////////////////////
function DebugComponent({rrCount}) {
    return (<div> 
           <label>xxx:</label> {rrCount}
           <br/>
           <label>f:</label> {F(rrCount)} 
           <br/>
           <label>g:</label> {G(rrCount)}
    </div>)
}
export function F(xxx) {
    const rrr = useRef(55)
    useEffect(() => {
      rrr.current += xxx
    }, [xxx]);
  
    return rrr.current + 5
  }
  
 export function G(xxx) {
    const rrr = useRef(55)
    if (rrr.current != xxx) rrr.current += xxx
    return rrr.current + 5
  }