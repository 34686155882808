
import {BrowserRouter, Routes, Route} from "react-router-dom"
import MainPage from "./Components/MainPage.js"
import DbPage from "./Components/DbPage.js"
import AiPage from "./Components/AiPage.js"
//import PlayPage from "./Components/PlayPage.js"
import ParamsPage from "./Components/ParamsPage.js"
import TextAreaPage from './Components/TextAreaPage';
import VariaProto from './Components/VariaProto';
import ElraiProto from './Components/ElraiProto';

import ElraiPage from './Components/ElraiPage';

//hi there v1

function App() {
  return (

    <BrowserRouter>
      <div className="cnvAppContainer">
        <Routes>

          <Route path="/*" element={<ElraiProto/>}/> 
          {/* <Route path="/*" element={<VariaProto/>}/>  */}
          {/* <Route path="/" element={<MainPage />}/> */}

          <Route path="/dbpage" element={<DbPage />}/>
          <Route path="/aipage" element={<AiPage />}/>


          {/* <Route path="/playPage" element={<PlayPage/>}/> */}
          <Route path="/textareapage" element={<TextAreaPage/>}
            headers={{ 'Content-Type': 'application/json' }}
          /> 
          <Route path="/variaproto" element={<VariaProto/>}
            headers={{ 'Content-Type': 'application/json' }}
          /> 

          <Route path="/elraipage" element={<ElraiPage/>}
            headers={{ 'Content-Type': 'application/json' }}
          /> 
          <Route path="/elraiproto" element={<ElraiProto/>}
            headers={{ 'Content-Type': 'application/json' }}
          /> 
          <Route path="/paramsPage" element={<ParamsPage/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  )

}


// import AiQnaList from "./Components/AiQnaList.js"
// import {useState, useEffect, useRef} from "react" 
// import axios from "axios"
// import immitateSaveRecord from "../Utils/MiscForApp.js"



// function App_Debug() {
//   const [rrCount, setRrCount] = useState(0)
//   //let rrCount = 111
//   const incrCount = async ()=>{
//     await immitateSaveRecord(rrCount + 1)
//     setRrCount(rrCount + 1)
//   }
//   return ( 
//     <div>
//       <button onClick={incrCount}>increment</button>
//       <AiQnaList rrCount = {rrCount}/>
//     </div>    
//     )

// }

export default App;


