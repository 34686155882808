import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import axios from "axios"

import {  BlobDisplay, Utils_Front} from "../Utils/Misc2.js"
import { MenuUniversal, MenuUniSt2} from "../Utils/Menus.js"
//import {Button, Menu, MenuItem, Slide, Zoom} from '@mui/material';
import {ScratchBackUrl} from '../Utils/gptCall.js'
import { BsFileBreakFill } from 'react-icons/bs';


//import __WEBPACK_EXTERNAL_MODULE_1__ from 'react-double-scrollbar';









export default function VariaProto() {

  return (
      <VariaCombined/> 
  )
}

class Cmdr {
  constructor(
    { op=null
      ,arg1=null,arg2=null
      ,args=new Set([])
      ,argLast=null
    }
  ) {
    this.op = op
    this.arg1 = arg1
    this.arg2 = arg2
    this.args = args
    this.argLast = argLast
  }
  
  isAllSet() {
    return this.op && this.arg1 && this.arg2
  }

  clearArgs() {
    this.arg1 = null
    this.arg2 = null
    this.argLast = null
    this.args=new Set([])
    return this.isAllSet() // obviously false
  }
  addArg (arg) { //shif left
    if (this.arg1 != null && this.arg2 != null){
      this.arg1 = this.arg2
      this.arg2 = arg
    } else if (this.arg1 != null) {
      this.arg2 = arg
    } else {
      this.arg1 = arg
    }

    this.argLast = arg
    return this.isAllSet()
  }
  setOp(op) {
    this.op = op
    return this.isAllSet()
  }

  copyOf() {
    return new Cmdr({op:this.op
      ,arg1:this.arg1,arg2:this.arg2
      ,argLast:this.argLast
      ,args:new Set(this.args)})
  }

  toArgString() {
    const sep = ':' // hopefully the same as on the server
    let res = this.op 
    if (this.arg1) {
      res += sep + this.arg1 
      if (this.arg1) {
        res += sep + this.arg2
      }
    }

    return res
  }

}

function CmdrComp({cmdr}) {
  return(
    <div style={{border:'1px solid black'}}>
      op: <span>{cmdr.op}</span>
      ,&nbsp;
      arg1: <span>{cmdr.arg1}</span>
      ,&nbsp;&nbsp;
      <div>arg2: <span>{cmdr.arg2}</span></div>
    </div>
  )
}

function VariaStandardName(prefix, nm, nmShort, nmExpanded, isOperation) {
  // let res = (isOperation? nm : nmShort)
  // res += ' // ' + nmExpanded
  // return res
  let displayName = null
  if (isOperation) {
    displayName =  nm + ' [' + nmExpanded + ']'
  } else {
    displayName =  nmShort + '. ' + nmExpanded 
  }

  if (prefix) displayName = prefix + displayName

  if (displayName.length > 100) displayName = displayName.substring(0,100) + ' ... '
  return displayName

  if (isOperation) return nm + ' // '
  return nmShort + ' // ' + nmExpanded
}







function VariaCombined() {
  const [st2, setSt2] = useState(null) // us state ..


  const [varias, setVarias] = useState(null)
  const [selectIndex, setSelectIndex] = useState(new Set([]))
  const blobWindowRef = useRef(null); // for
  const [cmdr, setCmdr] = useState(new Cmdr({}))
  
  const [apdWindowHandle, setApdWindowHandle] = useState(null)
  const [scaleVal, setScaleVal] = useState('0.5')
  const scaleElRef = useRef();




  useEffect(() => {
    
    //crack the url, just in case i will need it
    const url = new URL(window. location. href)
    const op = url.searchParams.get('op')
    const searchString = url.searchParams.get('q')


    const xxx = {action:{fn:'emsetOp',paramString:'list'}}
    Get_Varias(xxx) // for initial load only
      //{action:{nm:'list', fn:'list'}}) // for initial load only
    .then((result) => {

         
      if (result.error) {
        BlobDisplay(result, blobWindowRef)
        return
      }
        
      
      setVarias_Sorted(result.data)

      // create window for view-ing
      //postElrai_Launch(false)
    })



    //////////////////////////
/*     function getCurrentItems() { // to use inside closure
      return items
    }
    const handlePostMessage = (event) => {
      console.log("TARGET: from origin : " + event.origin + ", to : " + window.location.origin);
      // if (event.origin == window.location.origin) {
      //   // Check the origin to ensure security
      //   return;
      // }
      if (event.source.location.pathname != '/textareapage') return 


      // Handle the incoming message
      const receivedMessage = event.data;
      console.log("TARGET: Received message: " + receivedMessage);

      OutsideOp(receivedMessage)

      const responseObj = {items:getCurrentItems()}
      // Send acknowledment
      event.source.postMessage(
          //"hi there yourself!  the secret response " + "is: rheeeeet!",
          responseObj,
          event.origin,
        );
    };


    window.addEventListener("message", handlePostMessage)
    return () => {
      window.removeEventListener("message", handlePostMessage);
    }; */
    /////////////////////////

  }, []); // of useeffect

  function onSt2(st2) {
    setSt2(st2)
  }

  function getSelectedVariaIds() { // has to be in combined component
    // eturns array
    let variaIds = []
    if (varias && selectIndex) varias.map((varia, index)=> {selectIndex.has(index)});
    return variaIds
  }

  const onEmset = async ({nm=null, idxs=null, action='emsetDefine'}) => {

    const variaIds = getSelectedVariaIds() //varias.map((varia, index)=> {selectIndex.has(index)});

    const postData = {action:action
      , nm: nm
      , ids: variaIds
    }

    const result = await Get_Varias({postData}) 

    // if (siteMode.has('dbgMode')) {
    //   if (result.error == null && result.data.eimList) {
    //     setItems(result.data.eimList)
    //     if (result.data.cbList) setActors(CbItem.createCbList(result.data.cbList))
    //   } else {
    //     BlobDisplay(result, blobWindowRef)
    //   }
    // }
  }


   

  function CmdrToServer_DefaultOnSuccess(data) { // this is called on success
    if (data.vlist) setVarias_Sorted(data.vlist)
    BlobDisplay(data, blobWindowRef)
  }

  function CmdrToServer (cmdr, onSuccessProcessData, argString = null, scale = null) {
    argString = argString ?? cmdr.toArgString()
    const xxx = {action:{fn:'emsetOp',paramString:argString, scale:scale}}
    Get_Varias(xxx) // for initial load only  //{action:{nm:'list', fn:'list'}}) // for initial load only
    .then((result) => {
      if (result.error) {
        BlobDisplay(result, blobWindowRef)
        return
      }
        
      //setVariasetVarias_Sorteds(result.data)
      if (onSuccessProcessData) onSuccessProcessData(result.data)
    })
    .catch((e)=>{
      BlobDisplay({caught:e}, blobWindowRef)
    })
  }

  async function doCmdrServer() {
    const xxx = {action:{fn:'emsetOp',paramString:cmdr.toArgString()}}
    const result = await Get_Varias(xxx) // for initial load only  //{action:{nm:'list', fn:'list'}}) // for initial load only

    if (result.error) {
      throw new Error(result.error)
      //BlobDisplay(result, blobWindowRef)
      return
    }
        
    return result

  }

  function setVarias_Sorted(newVarias) {
    // removes selections and restore them?
    const prevSelectIndex = new Set(selectIndex)
    const prevSelectedNms = new Set()
    if (varias) {
      for (let i = 0; i < varias.length; i++) {
        const varia = varias[i]
        if (prevSelectIndex.has(i)) prevSelectedNms.add(varia.nm)
      }
    }
    // 
    //could ve done with just  const prevSelectedNms = new Set(getSelectedVariaIds())

    newVarias.sort((a, b) => {
        return new Date(b.dt) - new Date(a.dt);
    }
    )
    setVarias(newVarias)

    // restore selections
    const newSelectIndex = new Set()
    for (let i = 0; i < newVarias.length; i++) {
      const varia = newVarias[i]
      if (prevSelectedNms.has(varia.nm)) newSelectIndex.add(i)
    }
    setSelectIndex(newSelectIndex)
  }

  async function onCmdr(op, scale = null){
    if (op == 'clear') {
      setSelectIndex(new Set([]))
      setCmdr(new Cmdr({}))      
      return
    }

    if (op == null) { // it is a vai
      // it is coming from handleSelect i.e. from slecting a varia

    } else { // save op
      const isReady = cmdr.setOp(op)
      setCmdr(cmdr.copyOf())      
    }


    // if (cmdr.op == 'list' || cmdr.isAllSet()) 
    //   CmdrToServer()

    const cmdr0 = new Cmdr({op:op})
    const cmdr1 = new Cmdr({op:op,arg1:cmdr.argLast, argLast:cmdr.argLast})
    const cmdr2 = cmdr
    //const cmdrLast = new Cmdr({op:op,arg1:cmdr.argLast})
    
    const selectedNmList = Array.from(selectIndex).map((i)=>varias[i].nm)
    const selectedNmString =selectedNmList.join(':')

    let complaint = null
    try { switch (op) {
      // case'clear':
      // break;

      case 'research': 
      // CmdrToServer(cmdr, (data)=>{ // this is called on success
      //   CmdrToServer_DefaultOnSuccess(data)
      // })

      // CmdrToServer(null, (data)=>{ // this is called on success
      //   CmdrToServer_DefaultOnSuccess(data)
      // }, op + ':' + selectedNmString)
      
      CmdrToServer(cmdr2, (data)=>{ // this is called on success
        CmdrToServer_DefaultOnSuccess(data)
      })

      break;

      case 'dbg': 
      // CmdrToServer(cmdr, (data)=>{ // this is called on success
      //   CmdrToServer_DefaultOnSuccess(data)
      // })
      CmdrToServer(null, (data)=>{ // this is called on success
        CmdrToServer_DefaultOnSuccess(data)
      }, 'dbg' + ':' + selectedNmString)
      break;

      case 'delete': 
        //const commaList = Array.from(selectIndex).map((i)=>varias[i].nm).join(':')
        if ( window.confirm('are you sure you want to delete this files?' 
                 + '\n' + selectedNmList.join('\n')) != true) {
          break;
        }
        CmdrToServer(null, (data)=>{ // this is called on success
          CmdrToServer_DefaultOnSuccess(data)
        }, op + ':' + selectedNmString)
      break;

      case 'rename': 
        CmdrToServer(cmdr1, (data)=>{ // this is called on success
          CmdrToServer_DefaultOnSuccess(data)
        })
        break;

      case 'neg':  if(cmdr1.arg1 == null) { complaint = 'no arg'; break }
        CmdrToServer(cmdr1, (data)=>{ // this is called on success
          CmdrToServer_DefaultOnSuccess(data)
          })
          break;

      case 'minus': case 'plus': case 'reflect' : case '~' : if(cmdr2.arg1 == null || cmdr2.arg2 == null) { 
          complaint = 'needs 2 args'; 
          break 
        }
        CmdrToServer(cmdr2, (data)=>{ // this is called on success
          CmdrToServer_DefaultOnSuccess(data)
        })
        break;

      case'sense': if(cmdr1.arg1 == null) { complaint = 'no arg'; break }
        CmdrToServer(cmdr1, (data)=>{ // this is called on success
          CmdrToServer_DefaultOnSuccess(data)
        })
      break;

      case'dist':  if(cmdr2.arg1 == null || cmdr2.arg2 == null) { 
        complaint = 'needs 2 args'; 
        break 
      }
      CmdrToServer(cmdr2, (data)=>{ // this is called on success
        CmdrToServer_DefaultOnSuccess(data)
      })
      break;

      case 'list':  
        CmdrToServer(cmdr0, (data)=>{setVarias_Sorted(data)
        }) 
        break;
      
      case 'view': if(cmdr1.arg1 == null) { complaint = 'no arg'; break }
        CmdrToServer(cmdr1, (data)=>{ // pass the items to elrais
          const prefix  = '(' + data.stats.centerDist.toFixed(2) + ') ' // + data.nm
          data.expName=VariaStandardName(prefix, data.nm, data.nmShort, data.nmExpanded.dump, data.isOperation) // correction crap from the backend
          postElrai({op:'view', params: {data: data} },true)
        }) 
    
    }} catch (e) {
      BlobDisplay(e, blobWindowRef)
      return
    }
    if (complaint) alert(complaint)
    return

    // let result = {error:null, data: null}
    //  try { result = doCmdrServer() 
    //  } catch (e) {
    //   BlobDisplay(e, blobWindowRef)
    //  }

   }

 
  function handleSelect(e, index, op) {
    // if (e) e.preventDefault() - it blocks hrefs 
    //if (e.ctrlKey) && e.shiftKey && e.key === "?") 
    if (e.ctrlKey) op = ['flip']
    else op  = ['exclusive','flip']

    const cpy = Utils_Front.Setwork(selectIndex, index, op) 
    setSelectIndex(cpy)

    const isReady = cmdr.addArg(
      (cpy.has(index) ? varias[index].nm : null)) //
    setCmdr(cmdr.copyOf()) // ensuring update
      
    onCmdr(null) // - let it decide what to do on varia click

     return
   }

   /////////////////////////////////////////////////
  const postElrai_EventListener = (event) => {
    // if (event.origin !== window.location.origin) {
    //   // Check the origin to ensure security
    //   return;
    // }
    console.log("in SENDER ??")
    // Handle the incoming message
    const receivedMessage = event.data;
    if (false && receivedMessage.items) {
      BlobDisplay(receivedMessage.items, blobWindowRef)
    } else {

      console.log("SENDER : Received message from Elrai: " + receivedMessage);
    }
  }

  function postElrai_Launch(swicthToTheTargetWindow = false) {
    // in case it is not the first call :
    window.removeEventListener(
      "message",
      postElrai_EventListener,
      false,
    );

    const apdH = window.open('/elraiproto?' + '_posting'); // design note ['_posting' message]
    // opening  default window that hapens to be 
    setApdWindowHandle(apdH)

    window.addEventListener(
      "message",
      postElrai_EventListener,
      false,
    );

  }

  function postElrai(postData, swicthToTheTargetWindow = false) {
    if (apdWindowHandle != null) {
      // const txt = textAreaEl.current.value
      // const mmm = {op:'..', params: {q: txt} }
      console.log("SENDER : sending message: to Elrai");
      apdWindowHandle.postMessage(
        postData,
        '*', //window.location.origin + '/textareapage' ,
      );
      if (swicthToTheTargetWindow)  apdWindowHandle.focus()

    } else {
      alert('create a apd?varia window first')
      return 
    const apdH = window.open("/elraiproto?varia"); // opening  default window that hapens to be 
    setApdWindowHandle(apdH)
    window.addEventListener(
      "message",
      (event) => {
        // if (event.origin !== window.location.origin) {
        //   // Check the origin to ensure security
        //   return;
        // }
        console.log("in SENDER ??")
        // Handle the incoming message
        const receivedMessage = event.data;
        if (false && receivedMessage.items) {
          BlobDisplay(receivedMessage.items, blobWindowRef)
        } else {

          console.log("SENDER : Received message from Elrai: " + receivedMessage);
        }
      },
      false,
    );
    }
  }

  
   return (
    <div>
      <div style={{display: "flex", flexDirection:"row", height:30}}>

        <MenuUniSt2 onSelect={onSt2}/><div>{st2}</div>

        <button onClick={()=>postElrai_Launch(false)}>@vwin</button>
        &nbsp;

        <button onClick={()=>onCmdr('clear')}>clear</button>
        <button onClick={()=>onCmdr('list')}>list</button>
        &nbsp;
        &nbsp;
        <button onClick={()=>onCmdr('neg')}>neg</button>
        <button onClick={()=>onCmdr('plus')}>plus</button>
        <button onClick={()=>onCmdr('minus')}>minus</button>
        <button onClick={()=>{
          const scale = scaleElRef.current.value
          onCmdr('~', scale)}}>reflect</button>
        <input type="text"
          onChange={(e)=>{const text = scaleElRef.current.value;
            if (text.trim() == '') {              
            }
            setScaleVal(e.target.value);
          }}
          size={1}
          ref={scaleElRef} value={scaleVal}
        />

        &nbsp;
        &nbsp;
        <button onClick={()=>onCmdr('dist')}>dist</button>
        &nbsp;
        <button onClick={()=>onCmdr('sense')}>sense</button>
        <button onClick={()=>onCmdr('view')}>view</button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button onClick={()=>onCmdr('delete')}>@del</button>
        &nbsp;

        
        
        &nbsp;
        <button onClick={(e)=>{
          const postData = {op:'ss', params: {q: 'budger issues'} }
          postElrai(postData, true) // i.e. // and switch to it
          
        }} >post</button>

        <button onClick={()=>onCmdr('dbg')}>dbg</button>
        <button onClick={()=>onCmdr('research')}>@r</button>
        
      </div>
      <CmdrComp cmdr={cmdr}/>
      <VariaList varias={varias} selectIndex={selectIndex} handleSelect={handleSelect} />
    </div>
   )
}

function VariaList( {varias, ud, udCallBack
  , selectIndex, handleSelect    
  , handleSearch}) {
    const containerRef = useRef(null);


    useEffect(() => {
    
      const xxx = 111
    }, [varias]); // of useeffect
  
  





    return (  
      <div id="variaDiv"
        style={{ overflowY: 'none' , listStyleType: 'none', paddingLeft: 0 }} 
        ref={containerRef}
        > 

          
          {/* <ExpoHeader siteMode={siteMode} keyCb={keyCb} ud={ud} udCallBack = {udCallBack} /> */}
    
          
          { (varias==null? null :
            varias.map((varia, index) => {    
    
              return <VariaComp varia = {varia}
                  index = {index} 
                  selectIndex={selectIndex} handleSelect={handleSelect} 
                  handleSearch={handleSearch}
                  />
    
    
          }))}


      </div>)

}

function VariaComp({varia
    , index
    , selectIndex, handleSelect
    , handleSearch
}) {
  const isOperation = varia.hdump.length > 1

  return (
  
    <div key={index} 
        style={{ 
          marginTop:'12px', // between each item
          //marginLeft:SearchStrip_LefMargin, // just under search strip start
          marginRight: '0px', //
          //margin:"12px 0px 0px 8px", // aligning XXX
          padding:"0px" // - NO padding here - as to align under 
          //, border: selectItemIndex.has(index) ? '2px red solid' : normalBorder,
          , backgroundColor: selectIndex.has(index) ? 'rgb(210 210 210)' : 'white',
          //display: "flex", alignItems: "flex-start"
        }}
  
  
        onClick = {(e) => { handleSelect(e,index,null) // see in handle select ['exclusive','flip']); // compare with (see  design note [simple expansion handling]) that happens on clicking quote link!
        }}
    > 


      <div style={{color:(varia.nmExpanded.missing?'red' : 'inherit')}}>
        {VariaStandardName(null, varia.nm, varia.nmShort,varia.nmExpanded.dump, isOperation)}</div>
      <div style={{marginLeft:'10px'}}>{varia.psense}</div>
  

  
    </div> 
  )
}

async function Get_Varias({filter=null, action=null, fnIsWorking = null
  , topEntryPoint = '/Varias'}) {
//console.log('===> ScratchBackUrl : ' + ScratchBackUrl)


    const outServerUrl = ScratchBackUrl

    // attach params to action
    const filterCpy = {...filter} // not messing wth the stat .. which dispayed, by the way
    // const fParams = crackFparams(filterCpy) // my instruction to scrathback
    // action.fParams = fParams

    // in case of varias action is as :
    const postData = {action:action.fn, nm: action.paramString, filter: filterCpy }


    try { if (fnIsWorking) fnIsWorking(true);

      const response = await axios.post(outServerUrl + topEntryPoint, postData);
      // - axios.get might trow an error from the back end by the caller should handle it

      const result =  response.data 

      return result // / i.e. response."data" is response == {error:{}. data:{}}

  } catch(e) {
    return {error:{code:Utils_Front.ErrorCode.Network_Error, message:e.message}, data:null}

  } finally {
    if (fnIsWorking) fnIsWorking(false) //,dtCacheFromServer)
  }

};