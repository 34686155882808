import React, { useState, useRef, useEffect } from 'react';
import './Feedback.css';


export function FeedbackDialog({ isOpen, onClose, onSubmitFeedback = null }) {
  // how to send email via smtp:
  // https://www.programonaut.com/how-to-send-an-email-in-node-js-using-an-smtp-step-by-step/
  // go daddy :  https://clean.email/blog/email-settings/godaddy-workspace-email-settings

  const dialog = useRef(null);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen && dialog.current) {
      dialog.current.showModal();
    }
  }, [isOpen]);

  const handleClose = () => {
    if (dialog.current) {
      dialog.current.close();
    }
    onClose();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Simulating sending feedback
    try {
      // Here you would replace this with an actual API call
      // For example:
      // await axios.post('/api/feedback', { feedback, email });

      

      if (onSubmitFeedback) {
        onSubmitFeedback({ feedback, email })
      } else {
        // it's better to send from react client thanr form node server because sending ip's will be various ..
        // actually - impossible from the client :()
      }

      console.log('Feedback submitted:', { feedback, email });
      // Handle success (show a message to the user, etc.)
    } catch (error) {
      // Handle error (show error message to the user)
      console.error('Failed to submit feedback:', error);
    } finally {
      setIsSubmitting(false);
      setFeedback('');
      setEmail('');
      handleClose();
    }
  };


  return (
    <dialog ref={dialog} className="feedback-dialog" onClose={handleClose}>
      <form onSubmit={handleSubmit}>
        <h1>Feedback</h1>
        <div className="form-group">
          {/* "required" attribute (by HTML 5 spec) causes the chrome display of "please fill .." */}
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Type your feedback here..."
            rows="8"
            required
          ></textarea> 
        </div>
        <div className="form-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email (optional)"
          />
        </div>
        <div className="dialog-actions">
          <button type="submit">Send Feedback</button>
          <button type="button" onClick={handleClose}>Close</button>
        </div>
      </form>
    </dialog>
  );
}
