import { Configuration, OpenAIApi } from "openai";
//import {QnA_Unit} from "../Logic/CnvClasses.ts"

// import ddd from "dotenv"
// ddd.config()

// a usefule link on : npm audit fix --force 
//https://stackoverflow.com/questions/69692842/error-message-error0308010cdigital-envelope-routinesunsupported

// for, for example, number of tokens see - https://platform.openai.com/docs/guides/chat/introduction

const kkk = process.env.REACT_APP_OPENAI_API_KEY

console.log ("key: " + kkk)
const KEY = kkk

const configuration = new Configuration({
  apiKey: KEY
});
const openai = new OpenAIApi(configuration);
console.log('=== OpenAIApi ===')


////////////////////////////
export function DisplayOpenaiMessages({messages}) {
  //if (!show) return null
  //return '<div>messages ..</div>'
  //messages = [{role:"role", content:"content"}]
  return (<>
      { (messages? 
        messages.map((message,index) => <div>
        <b><label text="role"/>{message.role}</b> <div>{message.content}</div>
          </div>)
          :<div>no messagessss</div>
        )
      }
      

    </>)
}
////////////////////////////


/*
OpenAI Logo
We're excited to announce a few updates to the OpenAI developer platform.
GPT-3.5 Turbo
This model has been updated with a new version: gpt-3.5-turbo-0613 which is more steerable with the system message and includes a new capability: function calling. By describing functions in your prompts, the model can intelligently output a JSON object containing arguments to call these functions based on user input — perfect for integrating with other tools or APIs. Learn more in our function calling documentation.

Plus enjoy a 25% cost reduction for input tokens on GPT-3.5 Turbo (now $0.0015 per 1K input tokens), effective immediately.

Longer Context
We're also introducing gpt-3.5-turbo-16k. This model offers four times the context length of the 4k base model and is priced at $0.003 per 1K input tokens and $0.004 per 1K output tokens.

Model Transitioning
You can begin using the new gpt-3.5-turbo-0613 model today. On June 27th, the stable gpt-3.5-turbo will be automatically upgraded to this new version. If you need more time to transition, you can specify gpt-3.5-turbo-0301 to keep using the older version, which will remain available until September 13th as part of our upgrade and deprecation process.

—The OpenAI team

Twitter thread 
   https://twitter.com/svpino/status/1668695130570903552

*/

export  async function DoDo_DaVinci({question, mood, modelName = "text-davinci-003"}) {
// "gpt-3.5-turbo"
// "text-davinci-003",
    //return 'not yet xxxx...' + '\n' + KEY

    try {
        const completion = await openai.createCompletion({
          model: modelName,
        //   prompt: "make the answer very optimistic to this question: '" 
        //           + question + "'", //generatePrompt(animal),
            prompt: mood + ": '" 
                  + question + "'", //generatePrompt(animal),
          max_tokens:200,
          temperature: 0.6,
        });
        //res.status(200).json({ result: completion.data.choices[0].text });
        //return completion.data.choices[0].text
        let result = completion.data.choices[0].text
        // completion.then((value) =>{

        //     console.log(value)
        //     result = 'success'

        // },
        //         (error) => {
        //                 console.error(error);
        //                 result = 'failure'
        //         }
        // )

        return result;
  
      } catch(error) {
        // Consider adjusting the error handling logic for your use case
        if (error.response) {
          console.error(error.response.status, error.response.data);
        
          return error.response.data.error.message;
          //  : example :
        //   'That model is currently overloaded with other requests. 
        //   You can retry your request, or contact us through our help 
        //   center at help.openai.com if the error persists. 
        //   (Please include the request ID f2e422e20b4300b37aa43809af260aed
        //      in your message.)'

          
          
          //return error.response.data;
        } else {
          
          return 'failed???' + error.message //JSON.stringify(error)
     
        }
      }

}

// question = null,answer = null, prefix = null, suffix = null, 
//             question_author = null, answer_author = null}) {
//         this.q = question;
//         this.a = answer
//         this.prefix = prefix
//         this.suffix = suffix
//         this.qAuth = question_author // design note [question is just the last in context] actuall the question is just the last in the context?
//         this.aAuth = answer_author


function parseTxtPrefix(txt) {
  let prefix = null
  const arr = txt.split('::',2)
  if (arr.length == 1) {
    txt = txt.trim() // just in case
  } else {
    prefix = arr[0].trim(); txt = arr[1].trim()
  } 
  let role = (prefix == "system"? "system" : "user")
  return {prefix: prefix, txt:txt, role:role}
}
export  async function DoDo({qnaUnits, 
  question
  , questionToAuth=null
  , mood = null, prefix = null, modelName = "gpt-3.5-turbo" //"gpt-3.5-turbo" .. "gpt-4"
  , udCallBack=null
  , emulateCallFn = null}) {
//https://plainenglish.io/blog/beginners-guide-to-openai-s-gpt-3-5-turbo-model
//https://platform.openai.com/docs/api-reference/edits/create?lang=node.js
// ["messages": [{"role": "user", "content": "Hello!"},..]

/* https://techcommunity.microsoft.com/t5/modern-work-app-consult-blog/bring-the-chatgpt-model-into-our-applications/ba-p/3766574
const response = await openai.createChatCompletion({
  model: "gpt-3.5-turbo",
  messages: [
    {"role": "system", "content": "You are a helpful assistant."},
    {"role": "user", "content": "Who won the world series in 2020?"},
    {"role": "assistant", "content": "The Los Angeles Dodgers won the World Series in 2020."},
    {"role": "user", "content": "Where was it played?"}
  ],
}); */

    const prefixSep = '\n'
    let messages = []
    qnaUnits.forEach((qnaUnit, index) => {

      if (qnaUnit.txt == null) return 

      const p = parseTxtPrefix(qnaUnit.txt)


      const txtContent = (qnaUnit.prefix ? (qnaUnit.prefix + prefixSep) : '' )
                     + p.txt 
                     + (qnaUnit.suffix ? (qnaUnit.suffix) : '' )

      let role = null
      if (qnaUnit.auth == 'Human' || qnaUnit.auth== 'me') {
        role = p.role

      } else if(qnaUnit.auth == questionToAuth) { //
        role = "assistant"

      } else {//
        role = p.role //"user" // the idea is that if question is to Bob then 
              // Alice is NOT an assistant but user - bob is assistant!
      }

      messages.push({"role":role, "content": txtContent})

    })
    //#todo - think thru creating too many lines?

    // now the last:
    


    
    try {

      const pExp = parseTxtPrefix(question)
      if (pExp.prefix == 'exp') { // the last message is already it
        return await DoDo_Exp({question:question, udCallBack:udCallBack})
  
      // const p = parseTxtPrefix(question)
      // const currentQuestionContent = (prefix ? (prefix + prefixSep) : '' )
      //                  + p.txt 
                      
  
      //   messages.push({"role":p.role, "content": currentQuestionContent})
      }


      if (udCallBack) udCallBack({chatDebug:messages});
      if (emulateCallFn) return await emulateCallFn //"an emulated response" //await GetQuote(messages)


          //const response = await openai.listModels();

          const completion = await openai.createChatCompletion({
            model: modelName,
            messages: messages,
            max_tokens:200,
            temperature: 0.6,
          });
          let result = completion.data.choices[0].message.content
          if (udCallBack) udCallBack({chatData:completion.data.choices[0]});
          //https://community.openai.com/t/gpt-4-createchatcompletion-stream-response-format-and-model-switch/112719/3
          
// The API responds but:
// Replaces gpt-4 with gpt-4-0314
// Delivers a different response format than is documented 70. Specifically instead of choices[0]message.content it responds with choices[0].delta.content.


          // { answer: completion.data.choices[0].message.content
          //               , messages: messsages} 
  
          return result;
    
        
    } catch(error) {
          // Consider adjusting the error handling logic for your use case
          if (error.response) {
            console.error(error.response.status, error.response.data);
          
            return error.response.data.error.message;
            //  : example :
          //   'That model is currently overloaded with other requests. 
          //   You can retry your request, or contact us through our help 
          //   center at help.openai.com if the error persists. 
          //   (Please include the request ID f2e422e20b4300b37aa43809af260aed
          //      in your message.)'
  
          } else {
            
            return 'failed???' + error.message //JSON.stringify(error)
       
          }
    }
  
  }


export  function scratchBackUrl() {
  // https://learn.microsoft.com/en-us/azure/app-service/app-service-web-nodejs-best-practices-and-troubleshoot-guide
  //https://stackoverflow.com/questions/25678419/how-to-check-if-code-is-running-on-azure-websites/25695126#25695126
  //https://medium.com/the-node-js-collection/making-your-node-js-work-everywhere-with-environment-variables-2da8cdf6e786
  
  //return 'http://localhost:5000'

  const NODE_ENV = process.env.NODE_ENV

  const REACT_APP_YURY_DBG_CAPS = process.env.REACT_APP_YURY_DBG_CAPS
  const REACT_APP_YURY_DBG_STR = process.env.REACT_APP_YURY_DBG_STR
  const REACT_APP_YURY_DBG_ARRAY = process.env.REACT_APP_YURY_DBG_ARRAY
  const REACT_APP_YURY_USE_REMOTE_BACKEND = process.env.REACT_APP_YURY_USE_REMOTE_BACKEND
  const caps = REACT_APP_YURY_DBG_CAPS.split(',').map(e=>e.trim())
  let doRemote = caps.includes("doRemote")
  

  doRemote = false

  // on kudu .. /env : WEBSITE_SITE_NAME = ScratchBackApp .. Rest
  if (!doRemote && NODE_ENV == 'development') { //} && !String.IsNullOrEmpty(process.env.GetEnvironmentVariable("WEBSITE_SITE_NAME"))) {
    return 'http://localhost:5000'
  } else if (true) {
    const nm = 'ScratchBackRest'
    return 'https://' + nm + '.azurewebsites.net'
  }
}
export  const ScratchBackUrl = scratchBackUrl();

//////////////////////////////////////////////////////////
async function GetQuote(hint) {
//https://rapidapi.com/guides/top-five-random-quote-generator-apis

  'https://api.quotable.io'
  const xxx = await
  //fetch('https://quotes.rest/qod?category=inspire')
  //fetch('https://rapidapi.com/martin.svoboda/api/quotes15/')
  //fetch('https://healthruwords.p.rapidapi.com/v1/quotes/') asks for api key
  fetch('https://quotes.rest/qod?category=inspire')
  .then(response => response.json()) // generates a promise - chat says tht response.json() is asynchonous!
  .then(data => {
    const quote = data.contents.quotes[0].quote;
    //console.log(quote);
    return quote
  })
  .catch(error => { //console.error(error)
    return JSON.stringify(error)
  });
  
  return xxx
}

//////////////////////////////////////////////


async function DoDo_Exp({ question, modelName = "gpt-3.5-turbo-0613"
  , udCallBack = null
}) {
  const questionMessage = {role:"user", content:question}

  const originalMessages = [questionMessage]
  
  // setup up all functions & lookup infra
  const functions = [reactOnPerson_Spec]
  const functionDict = {}
  functionDict[reactOnPerson_Spec['name']] = reactOnPerson

  // prep to loop
  let messages = [] //...originalMessages]
  let funcMessages = [] // will be added to if the chat calls the code

  while (true) {
    

    //messages = [...originalMessages] // -- erasing previous func messages
    messages = [...originalMessages, ...funcMessages] // - adding func results

    const response  = await openai.createChatCompletion({
      model: modelName,
      messages: messages,
      max_tokens:200,
      temperature: 0.6,
  
      functions: functions,
      function_call : "auto",
    });
  
    let responseDataChoice = response.data.choices[0]

    if (responseDataChoice.finish_reason == 'stop') {
      if (udCallBack) udCallBack({chatDebug:messages});
      return responseDataChoice.message.content
    }

    if (responseDataChoice.finish_reason == 'function_call') {
      const fnMessage = responseDataChoice.message //response["choices"][0]["message"]
      const fnName = fnMessage["function_call"]["name"]
      

      const fnArgumentsJSON = fnMessage["function_call"]["arguments"]
      try {
      } catch(e) { 
      }
      const fnArguments = JSON.parse(fnArgumentsJSON)

      const firstName = fnArguments["first_name"]
      const lastName = fnArguments["last_name"]

      const fn = functionDict[fnName]
      //let fnResponseJSON = fn(fnArguments)

      const fnResponseJSON = reactOnPerson(
              firstName,
              lastName,
          )

      // KEEP the result of the previous calls!!! 
      //funcMessages = [] // erasing the previous func responses?


      funcMessages.push({
        role: "assistant",
        content: null,
        function_call: {
                      name: fnName,
                      arguments: fnArgumentsJSON,
                  },
      })

      funcMessages.push({
          "role": "function",
          "name": fnName,
          "content": fnResponseJSON,
        })

      // and keep calling

    }
    // = openai.ChatCompletion.create(
    //         model="gpt-3.5-turbo-0613",
    //         messages=[
    //             {"role": "user", "content": "What is the weather like in boston?"},
    //             message,
    //             {
    //                 "role": "function",
    //                 "name": function_name,
    //                 "content": function_response,
    //             },
    //         ],
    //     )
    //     return second_response
  }

}




function reactOnPerson(first_name = null, last_name = null) {
    //my test function of catGpt function calls 
    const test_info = {
      first_name: "John", //first_name, // + " -- First ",
      last_name: "Keystone" , //last_name,// + " -- Last ",
      age: 26,
      occupation: "actor"
    }

    return JSON.stringify(test_info)

}

const reactOnPerson_Spec = {
  "name": "test_function_reactOnPerson",
  "description": "Provides the  age and the  occupation of the person",
  "parameters": {
      "type": "object",
        "properties": {
          "first_name": {
              "type": "string",
              "description": "The first name",
          },
          "last_name": {
            "type": "string", 
            "description": "The last name",
          },
        },
  },
  "required": ["first_name", "last_name"],
}