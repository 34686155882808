
// for hitextcomp : 
import { Button, Input, InputAdornment, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// does NOT work well with BROWSERS - try it in the back ... import {tokenizer} from 'natural' //!!!

import * as HiUtils from './HiText.js'
import { Utils_Front } from './Misc2.js';


//mysql> SELECT * FROM INFORMATION_SCHEMA.INNODB_FT_DEFAULT_STOPWORD; .. but i aded "and" !!??
const StopWords = new Set(["a","about","an","and","are","as","at","be","by","com"
,"de","en","for","from","how","i","in","is","it","la","of","on","or"
,"that","the","this","to","was","what","when","where","who","will","with","und","the","www"] // but see https://www.ranks.nl/stopwords
)
const PrepSigns = new Set([".",","]) // etc??? what about  .. economy's - stems and so forth?
// You can do it specifying the characters you want to remove: https://stackoverflow.com/questions/6555182/remove-all-special-characters-except-space-from-a-string-using-javascript
// string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');

function isAcceptableWord(word, countStopWords) {
  const startsWithCapital = word !== word.toLowerCase()
  
  if (word.length < 2) return false; // no one letters? 

  //if  (!searchSet.has(word)) return false
  if (StopWords.has(word) && !startsWithCapital) return false

  return true
}



export function purgeStops (searchArray, stopWordsSet = StopWords) {
  const arr = searchArray.map((w)=>{
    if (w.length < 2) return null; // no one letters? 
    if (stopWordsSet.has(w.toLowerCase())) return null;
    return w
  }).filter((w)=>{return (w==null? null:w)});
  return arr
}

export function HiGetParagraphs(txt, noEmpties = true) { //chatgpt
  if (txt == null) return null
  let paragraphs = txt.split(/\n|\r\n/);

  // Trim whitespace from each paragraph
  paragraphs = paragraphs.map(function(paragraph) {
    return paragraph.trim();
  });
  
  if (noEmpties) paragraphs = paragraphs.filter((p)=>{return (p==''? null:p)})

  return paragraphs;
}

export function HiRegDo(text, searchArray, onlyBestParagrpaph = false) // taken from chat
// returns array of paragraphs with matches
{
  // get rid of stop words
  const res = []


  const purgedArr = purgeStops(searchArray, StopWords)

  // remove stop words first
  const regex = new RegExp('\\b(' + purgedArr.join('.*?') + ')\\b', 'gi');
  const paragraphs = text.split('\n').filter((p)=>{return (p==''? null:p)})
  for(const paragraph of paragraphs) {
    const pMatches = paragraph.match(regex);
    res.push({paragraph: paragraph, matches : pMatches, quality: null })
    // if (pMatches) { // can matches be nil?


    //   const pMatchSet = new Set(filteredMatches)
    //   if (bestMatchSize < pMatchSet.size) {
    //     bestP = p; bestMatchSize = pMatchSet.size; bestI = i
    //   }
    // }

  }

  return res
}


function pMatcheEval(pMatch,purgedArr) { // the closer the number of words the better


}




export function Search_ToArray(searchString) {
  let searchArray = [] 
  if (searchString) {
    searchArray = searchString.split(' ')

    if (true) {
      searchArray = searchArray.filter((word)=> {return (word != '' 
      //&& ! StopWords.has(word.toLowerCase())
      )
    }); // https://stackoverflow.com/questions/24806772/how-to-skip-over-an-element-in-map
    }
  }
  return searchArray
}

export function HiText(text, searchObject, onlyBestParagrpaph = false) {
  if (! searchObject) return {nReplacements:0, replacedText:text, errstr:null}


  let searchArray = []
  if (Array.isArray(searchObject)) {
    searchArray = searchObject
  } else {
    searchArray = Search_ToArray(searchObject)
  }

  return HighlightText(text, searchArray, onlyBestParagrpaph)
}

function HiTextA(text, searchArray, onlyBestParagrpaph = false) {
  if (! searchArray) return {nReplacements:0, replacedText:text, errstr:null}
  return HighlightText(text, searchArray, onlyBestParagrpaph)
}

export function HighlightText(text, searchArray, onlyBestParagrpaph = false) // taken from chat
// returns the number of words highlighted
{

  /////////////resItem.pBest = true // if it came here then on the exit .. in the caller i have to mark this item for expansion?

 
//const cbi = CbItem.create({x:123,y:'dasda'})

  let res = {nReplacements:0, replacedText:text, errstr:null}

  try {

  if (searchArray == null || searchArray.length == 0) return res

  // Create a regular expression to match any word in the array.
  const regex = new RegExp('\\b(' + searchArray.join('|') + ')\\b', 'gi'); // global insensitive

  const searchSet = new Set(searchArray) // to llok words up



  // find the best paragraph - i do not want to do the whole text in?
  if (onlyBestParagrpaph ) {
    const paragraphs = text.split('\n').filter((p)=>{return (p==''? null:p)})
    let bestP = null
    let bestMatchSize = -1
    let bestI = -1
    for (let i = 0; i <  paragraphs.length; i++) {
      const p = paragraphs[i]
      const pMatches = p.match(regex);
      if (pMatches) {
        const filteredMatches = pMatches.filter((word)=>{ return isAcceptableWord(word)});
        const pMatchSet = new Set(filteredMatches)
        if (bestMatchSize < pMatchSet.size) {
          bestP = p; bestMatchSize = pMatchSet.size; bestI = i
        }
      }

    }
    text = bestP
  }

  // Get the matches

  const matches = text.match(regex);


  if (matches) {
    // console.log(`Found ${matches.length} matches:`);
    // console.log(matches);
  
    // If you want to see the unique matches:
    const uniqueMatches = [...new Set(matches)];
    // console.log(`Unique matches:`);
    // console.log(uniqueMatches);
  
    res.replacedText = text.replace(regex, (match) => {
      if (isAcceptableWord(match)) {
        res.nReplacements += 1
        return '<b><font color="brown">'+match+'</font></b>';
      }else {  
        return match // ie. unchanged
      }
    });
  
    //res.nReplacements = matches.length

    // console.log(res.replacedText);


  } else {
    res.errstr = 'No matches found.' 
    // console.log('No matches found.');
  }

} catch (error) {
  return res
}

  return res

}

export function porterStemmer(word) { // simple version fro chatgpt?

// // Example usage:
// const word = "running";
// const stemmedWord = porterStemmer(word);
// console.log(stemmedWord); // Output: "run"

  const step1aSuffixes = ["sses", "ies", "ss", "s"];
  const step1bSuffixes = ["eed", "ed", "ing"];
  const step2Suffixes = [
    "ational",
    "tional",
    "enci",
    "anci",
    "izer",
    "bli",
    "alli",
    "entli",
    "eli",
    "ousli",
    "ization",
    "ation",
    "ator",
    "alism",
    "iveness",
    "fulness",
    "ousness",
    "aliti",
    "iviti",
    "biliti",
    "logi",
  ];

  // Step 1a
  for (const suffix of step1aSuffixes) {
    if (word.endsWith(suffix)) {
      return word.slice(0, -suffix.length);
    }
  }

  // Step 1b
  for (const suffix of step1bSuffixes) {
    if (word.endsWith(suffix)) {
      const stem = word.slice(0, -suffix.length);
      if (/[aeiouy]/.test(stem)) {
        return stem;
      }
      return word;
    }
  }

  // Step 2
  for (const suffix of step2Suffixes) {
    if (word.endsWith(suffix)) {
      const stem = word.slice(0, -suffix.length);
      if (stem.length >= 2) {
        return stem;
      }
      return word;
    }
  }

  return word;
}




function tokenizeText(text) {
  // Use the \w+ regular expression pattern to split the text into words
  const words = text
    .toLowerCase() // Convert the text to lowercase for consistency
    .match(/\w+/g); // Split by one or more word characters (letters, digits, or underscores)

  // Return the array of words
  return words || [];

  // const inputText = "the crazy-horse: is invented!";
  // const tokenizedArray = tokenizeText(inputText);

  // console.log(tokenizedArray);
}


export function Rex_ToArray(query) {
  let arr = [] 
  if (query) {

    const rrr = new RegExp('\\b\\w+\\b', 'gi') // chatgpt advice
    arr = query.match(rrr)

  }
  return arr
}

export function Rex_PrepArray(wordsArr) {
  const purgedArr = purgeStops(wordsArr, StopWords)
  return purgedArr
}

const RegExp_ThatMatchesNothigg = new RegExp('\b\B', 'gi')


export function Rex_To_Find_Straight(preppedArr) {
  // one after another separated by nonword chars

  if (preppedArr == null || preppedArr.length == 0) return RegExp_ThatMatchesNothigg

  const aa = preppedArr.map((w)=>{return '\\b' + w })

  const rex = new RegExp(aa.join('\\W+'), 'gi')
  return rex
  

}

export function Rex_ToFind_Words(preppedArr) {

  //const purgedArr = purgeStops(wordsArr, StopWords)

  // remove stop words first

  // that is what i have to do for every word/stem
  //      \bneed.*?\b

  if (preppedArr == null || preppedArr.length == 0) return RegExp_ThatMatchesNothigg

  const aa = preppedArr.map((w)=>{return '\\b' + w + '.*?\\b'})

  const rex = new RegExp(aa.join('|'), 'gi')
  return rex
  

}
export function Rex_ToFind_Phrase(preppedArr) {

  //const purgedArr = purgeStops(wordsArr, StopWords)

  if (preppedArr == null || preppedArr.length == 0) return RegExp_ThatMatchesNothigg
  
  const prefix = '\\b' // to match from the first word start
  const suffix = '\\S*' // to match to the last word finish
  const ptn = '(' + prefix + preppedArr.join('.*?\\b') + suffix + ')' 
  const rex = new RegExp(ptn, 'gi');
  return rex
}

export function HiTextComp({text, textMatch = null, hiMatch = null, aCallBack=null 
//  , allCompData, setAllCompData
}) {
  // returns text formatted into paragraphs!!

  // const tokenizer = new natural.WordTokenizer();
  // const stems = tokenizer.tokenize(text)
  // const t2 = stems.join(' / ')

  const paragraphs = HiGetParagraphs(text)
                  // tempTxtIn = paragraphs.join('<br/>')

  let ttt = text
  if (textMatch != null) {
  const t0 = textMatch.join(' ')
  const a3 = tokenizeText(t0)
  const s3 = a3.map((word)=>{return porterStemmer(word)});
  const t3 = purgeStops(s3).join(' / ')

  const prefix = '\\b' // to match from the first word start
  const suffix = '\\S*' // to match to the last word finish
  const ptn = '(' + prefix + s3.join('.*?\\b') + suffix + ')' 
  const regex = new RegExp(ptn, 'gi');
  const pMatches = regex.exec(text);
  const xxx = 1
  }

  return (
    <div style={{ fontWeight: "560", fontFamily: "roboto", display: "flex", alignItems: "flex-start" }}
    >
      <div style={{ border:'1px red solid',  display: "flex", alignItems: "flex-start" }}>
        <IconButton style={{ padding: 0, verticalAlign: 'middle' }}>
          <ExpandLessIcon fontSize="small" style={{ color: 'navy' }}
          onClick={()=>{aCallBack()}}
        /></IconButton>
      </div>
      <div>{
        paragraphs.map((p, index) => {
          //return <p style={{ marginLeft: index === 0 ? 0 : '10px' }}>{p}</p>
          return <p>{p}</p>
        })
      }</div>
    </div>
  )
}

export function  HtmlToSpan ( {htmlString} ) { // by chatgot :)!
  // Replace <b> tags with <strong> tags since <b> is not semantically correct
  // actually clear all html out ??????????????????????????????????? or what
  const transformedHtml = htmlString.replace(/<b>/g, 
  '<strong><font color="red">'
  ).replace(/<\/b>/g, 
  '</font></strong>'
  );

  return (
    <span dangerouslySetInnerHTML={{ __html: transformedHtml }} />
  );
};

export function  myHighlightOn ( rex, txt, color='Black' ) { // reversable with myHighlightOff()
  let nReplacements = 0
  const html = txt.replace(rex, (match) => {
    if (isAcceptableWord(match)) {
      nReplacements += 1
      return '<b><font color="' + color + '">'+match+'</font></b>'; // see unrex in myHighlightOff
    }else {  
      return match // ie. unchanged
    }
  });

  return {nReplacements:nReplacements, html:html}
}

export function  myHighlightOff ( html ) { // reverses myHighlightOn
  const unRex = /<b><font .*>(.*?)<\/font><\/b>/g
  const txt = html.replace((unRex, match) => {
      return match
    }
  );
  return txt
};


export function purgeRepetitions(arr) {
  const already = new Set()
  const res = arr.filter((e)=>{
    if (already.has(e)) return false
    already.add(e)
    return true
  })
  return res
}





export class QMatching {
  
 

  static singlesMatchEval2(arr, matches, pHigh = 1, hAt = 0.5, hP = 0.3) {
    // the idea is that if al elements from array are found - it gets 1
    // if all but one found - it gets LESS
    // otherwise zero

    //critical case arr = [Bil, Barr] matches [bill, bills]
  
    // hP is p when hAt matchRatio found
  
    if (matches == null || arr == null ) return 0

    matches = purgeRepetitions(matches)
      matches = matches.map((w)=>w.toLowerCase())
    arr = purgeRepetitions(arr)
      arr = arr.map((w)=>w.toLowerCase())

    // const arrRex = HiUtils.Rex_ToFind_Words(arr)
    const matchText = matches.join(' ')
    //const arrMatches = matchText.match(arrRex)
    // const nFound = arrMatches.length

    // ... IAM tired
    let nFound = 0
    for (let a of arr) if (matchText.indexOf(a) >= 0)  nFound += 1
    
    
  
    const matchRatio = nFound / arr.length // 0 to 1
    // -- actually i could uses .exec() and evaluate more precisely the quaity of the match
    // e..g whie stemming - .. query : disuss, text: .. discussing : arr: discu matches : dicussing
  
    //const hAt = 0.5 // really - 1 of 2 words?>
    //const pAtVal = 0.5 * (pLow + pHigh)
    const pLow = 0
    const res = Utils_Front.Fading(1 - matchRatio, hAt, hP,  pLow, pHigh)
    return res
    
  }

  // static singlesMatchEval(arr, matches, pHigh = 1, pLow = 0.3) {
  //   // the idea is that if al elements from array are found - it gets 1
  //   // if all but one found - it gets LESS
  //   // otherwise zero
  //   const matchSet = new Set(matches)
  //   const arrSet = new Set(arr)
  //   for (const m of matches) {
  //     if (arrSet.has(m)) arrSet.delete(m)
  //   }
  
  //   if (arrSet.size == 0) return pHigh
  //   if (arrSet.size == 1) return pLow
  //   return 0
  // }

  static phraseMatchEval(arr, matches) {
    // for now the idea is that if match found at all - give 1 .. otherwise ero
    if (matches == null) return 0
    return (matches.length == 0? 0 : 1)
  
  }
  

//   static QueryMatch_EvalWrap({words, rex, matches, fadeWeight, fadeDrop, nmForLog}) {
//     const xprg = new QMatching1()
//     xprg.pPure = (matches ? this.singlesMatchEval2(words, matches) : 0)
//     console.log(nmForLog + ' : ' + xprg.pPure.toFixed(2))
//     //---
//     //if (p < barelyAceptablePure) noHope = true  // no chance to improve??
//     xprg.pFaded = xprg.pPure * fadeWeight * fadeDrop

    
//     xprg.rex = rex; // so far
//     xprg.matches = matches
//     xprg.log = nmForLog

//     return xprg
// }

static barelyAceptablePure = this.singlesMatchEval2(['one', 'two', 'three'], ['one', 'two']) 

static QueryMatchEval(query, title, quote, txt) {
/*     #todo - think thru words staring with capitals ..
    #todo ** should i add scanning the title AND paragraph to find match ???
    like title = it is about abortions and paragraph - parents do not have rights
    - my sticking to paragraphs seem to be TOO strict  */
  
  
    // console.log('chat:')
    // for (let pNumber = 0; pNumber < 20; pNumber++) {
    //   console.log(pNumber + ' : ' + Sigmoid(pNumber, 1.0, 0.2, 4) )
    //}

    if (query === undefined || query == null) return null

    // prep paragraphs, title, if any o zeroth pozition
    const txtPeparagraphs = HiUtils.HiGetParagraphs(txt)
  
    if (title === undefined || title == null) title = null
    const paragraphs = [title, quote, ... txtPeparagraphs] // design note[indexes of title and quote] 0th and 1st, 
    // pNumberShift is for fading ..
    let pNumberShift  = -2 // everything negative (vi pNumberShift is NOT fading)

    return  this.QueryMatchEval_Array(query, paragraphs, pNumberShift )

}

static QueryMatchEval_Array(query, paragraphs, pNumberShift ) {


    //const barelyAceptablePure = this.singlesMatchEval2(['one', 'two', 'three'], ['one', 'two']) // 2 of three ?
  
    if (false) {
    console.log('myBase:')
    for (let pNumber = 0; pNumber < 20; pNumber++) {
      const minVal = 0.2
      const maxVal = 1
      const hAt = 4
      const hVal = (minVal + maxVal) * 0.5
      console.log(pNumber + ' : ' + Utils_Front.Fading(pNumber, hAt, hVal, minVal, maxVal ))
    }
    }
  
    
    
    // let rrr = new RegexpTokenizer()
    if (false) {
    const rrr = new RegExp('\\b\\w+\\b', 'gi')
    let mmm = query.match(rrr)
    }

  
  
    let qWords = HiUtils.Rex_ToArray(query)
      qWords = HiUtils.Rex_PrepArray(qWords) // so thta garbage is removed
  
    const qStems = qWords.map((word)=>{return HiUtils.porterStemmer(word)});
  
    const rexWord_Singles = HiUtils.Rex_ToFind_Words(qWords)
    const rexStem_Singles = HiUtils.Rex_ToFind_Words(qStems)
    
    const rexWord_Phrase = HiUtils.Rex_ToFind_Phrase(qWords)
    const rexStem_Phrase = HiUtils.Rex_ToFind_Phrase(qStems)

    const rexWord_Straight = HiUtils.Rex_To_Find_Straight(qWords)
  
    console.clear()
    console.log('query:::::: ' + query)
    //console.log('title:::::: ' + title)
  
    
  
  
    const stem_Singles_Weight = 0.8
    const word_Singles_Weight = 0.85
    const stem_Phrase_Weight = 0.9
    const word_Phrase_Weight = 1.0
    const word_Straight_Weight = 1.0
  
  
    const pNumberForHalfDrop = 5
    const pMinValForDownText = 0.2
  
    
    // if (title && title.trim() != '') {
      
    // }
  
    // const  lll(text, regex) {
    //   text.replace(regex, (match) => {
    //     if (isAcceptableWord(match)) {
    //       res.nReplacements += 1
    //       return '<b><font color="brown">'+match+'</font></b>';
    //     }else {  
    //       return match // ie. unchanged
    //     }
    //   });
    // }
    
    let xprgs = []

    // these are all weighted, i.e. corrected for match type
    let pBestNumber = -1
    let pBest = 0
    let pOverall = 0 // additionally is discounted by the paragraph drop in significance
    let pDropAtBestNumber = 1 // the highst value

    const lastResortStems = new Set()
  
  

  
  

    for (let pNumber = 0; pNumber < paragraphs.length; pNumber ++) { // in the order of difficulty match - the less diffiult first
      const Ptext = paragraphs[pNumber]

      if (Ptext == null || Ptext == '') continue // in case of empty title o quote?


      const pNumberDrop = Utils_Front.Fading( pNumber + pNumberShift, pNumberForHalfDrop, 0.5, pMinValForDownText, 1)
      
      //let pPure = 0
      let pPure = 0
      let pFaded = 0
      let pParagraphBest = 0
      let xprg = null
      let xprgBest = null
  
      let newStuff = false


      DifferentCases: if (true) {
        let noHope = false
  
      const stem_Singles_Matches = Ptext.match(rexStem_Singles)

        if (stem_Singles_Matches) {
          for (let s of stem_Singles_Matches) lastResortStems.add(s)
        }

        const p_stem_Singles = 
          pPure  = (stem_Singles_Matches ? this.singlesMatchEval2(qStems, stem_Singles_Matches) : 0)
        //console.log('p_stem_Singles : ' + p_stem_Singles.toFixed(2))
        //---
        //if (pPure <this.barelyAceptablePure) noHope = true  // no chance to improve??
        pFaded = pPure * stem_Singles_Weight * pNumberDrop
 

        const stem_Singles_xprg = xprg =new QMatching1({words:qStems
          , rex: rexStem_Singles
          , matches: stem_Singles_Matches 
          , fadeWeight: stem_Singles_Weight
          , fadeDrop: pNumberDrop
          , pPure: pPure, pFaded: pFaded
          , log : 'p_stem_Singles'})
        if (pParagraphBest <= xprg.pPure) { pParagraphBest = xprg.pPure; xprgBest = xprg.getCloneOf() }
        if (xprg.pPure < this.barelyAceptablePure) break DifferentCases 


  
      const word_Singles_Matches = Ptext.match(rexWord_Singles)

        const p_word_Singles = 
          pPure = (word_Singles_Matches ? this.singlesMatchEval2(qWords, word_Singles_Matches) : 0)
        //console.log('p_word_Singles : ' + p_word_Singles.toFixed(2))
        //---
        //if (pPure < barelyAceptablePure) noHope = true // no chance to improve??
        pFaded = pPure * word_Singles_Weight * pNumberDrop
        

        const word_Singles_xprg = xprg =new QMatching1({words:qWords
          , rex: rexWord_Singles
          , matches: word_Singles_Matches 
          , fadeWeight: word_Singles_Weight
          , fadeDrop: pNumberDrop
          , pPure: pPure, pFaded: pFaded
          , log : 'p_word_Singles'})
        if (pParagraphBest <= xprg.pPure) { pParagraphBest = xprg.pPure; xprgBest = xprg.getCloneOf() }
        if (xprg.pPure < this.barelyAceptablePure) break DifferentCases 

  
        // now phrase match
  
      const stem_Phrase_Matches = Ptext.match(rexStem_Phrase)
        const p_stem_Phrase = 
          pPure = (stem_Phrase_Matches ? this.phraseMatchEval(qStems, stem_Phrase_Matches) : 0)
        //console.log('p_stem_Phrase : ' + p_stem_Phrase.toFixed(2))
        //---
        //if (pPure < barelyAceptablePure) noHope = true // no chance to improve??
        pFaded = pPure * stem_Phrase_Weight * pNumberDrop




        const stem_Phrase_xprg = xprg =new QMatching1({words:qStems
          , rex: rexStem_Phrase
          , matches: stem_Phrase_Matches 
          , fadeWeight: stem_Phrase_Weight
          , fadeDrop: pNumberDrop
          , pPure: pPure, pFaded: pFaded
          , log : 'p_stem_Phrase'})

        if (word_Singles_xprg.pPure == stem_Phrase_xprg.pPure
            &&  stem_Phrase_xprg.precision < 0.5) {
          // Hell with phrase matching
          const x  = 1
        } else {
          if (pParagraphBest <= xprg.pPure) { pParagraphBest = xprg.pPure; xprgBest = xprg.getCloneOf() }
          if (xprg.pPure < this.barelyAceptablePure) break DifferentCases 
        }
  
      const word_Phrase_Matches = Ptext.match(rexWord_Phrase)
        const p_word_Phrase = 
          pPure = ( word_Phrase_Matches ? this.phraseMatchEval(qWords, word_Phrase_Matches) : 0)
        //console.log('p_word_Phrase : ' + p_word_Phrase.toFixed(2))
        //---
        //if (pPure < barelyAceptablePure) noHope = true // no chance to improve??
        pFaded = pPure * word_Phrase_Weight * pNumberDrop

    

        const word_Phrase_xprg = xprg = new QMatching1({words:qWords
          , rex: rexWord_Phrase
          , matches: word_Phrase_Matches 
          , fadeWeight: word_Phrase_Weight
          , fadeDrop: pNumberDrop
          , pPure: pPure, pFaded: pFaded
          , log : 'p_word_Phrase'})

        // punish if a lot of not matching words get inside the phrase match .. so that simpler stem or word matches win
        // .. and highlighting would be less extensive :)
        // and MORE - i would choose .. word_Singles_xprg in case of Bob Dole ... Dole
        if (word_Singles_xprg.pPure == word_Phrase_xprg.pPure
            &&  word_Phrase_xprg.precision < 0.5) {
          // Hell with phrase matching
          const x  = 1
        } else {

          if (pParagraphBest <= xprg.pPure) { pParagraphBest = xprg.pPure; xprgBest = xprg.getCloneOf() }
          if (xprg.pPure < this.barelyAceptablePure) break DifferentCases 
        }

      

      // shooting for the straigt match
      const word_Straight_Matches = Ptext.match(rexWord_Straight)
        const p_word_Straight = 
          pPure = ( word_Straight_Matches ? this.phraseMatchEval(qWords, word_Straight_Matches) : 0)
        //console.log('p_word_Straight : ' + p_word_Straight.toFixed(2))
        //---
        //if (pPure < barelyAceptablePure) noHope = true // no chance to improve??
        pFaded = pPure * word_Straight_Weight * pNumberDrop

        const word_Straight_xprg = xprg = new QMatching1({words:qWords
          , rex: rexWord_Straight
          , matches: word_Straight_Matches 
          , fadeWeight: word_Straight_Weight
          , fadeDrop: pNumberDrop
          , pPure: pPure, pFaded: pFaded
          , log : 'p_word_Straight'})

  

          if (pParagraphBest <= xprg.pPure) { pParagraphBest = xprg.pPure; xprgBest = xprg.getCloneOf() }
          if (xprg.pPure < this.barelyAceptablePure) break DifferentCases 
        

      } // of DifferentCases 
    
    
      xprgBest.txt = Ptext
      xprgs.push(xprgBest)

      console.log('-----------')
  
      if (pBest < pParagraphBest) {
        pBest = pParagraphBest // 
        pBestNumber = pNumber
        pDropAtBestNumber = pNumberDrop
      }
  
  
      pOverall = pOverall + pParagraphBest  - pOverall * pParagraphBest
  
  
    
    } // of loop thru paragraphs
  


    const weed = new QMatchingWeed( // the "params" follow :
      { query:query, shift: pNumberShift, 
        xprgs: xprgs, // the zeroth contains title, even if empty
        // - this is an array of QMatching1's

        pBest: pBest, pOverall: pOverall, pAcceptable: this.barelyAceptablePure,
      pNumber: pBestNumber,  
      pDrop: pDropAtBestNumber, // with idea that pDropAtBestNumber * barelyAceptablePure is kinda acceptable ???
      errStr: null,
      qWords: qWords, qStems: qStems,
      
      lastResort:lastResortStems, 
      pLastResort: this.singlesMatchEval2(qStems, [... lastResortStems]),
      


    })
  
    console.log(JSON.stringify(weed,null, 2))
  
    return weed
  
  }


}


export class QMatching1{
  constructor( {words = null, txt = null, fadeWeight = null, fadeDrop = null
       , pPure = null, pFaded = null, rex = null, matches = null
       , log = null}) { 
      this.words = words // i.e. array of words to find
      this.txt = txt // a title or a quete o a pragarpaph
      this.html = null // if not null then highligted
      this.fadeWeight = fadeWeight
      this.fadeDrop = fadeDrop
  
      this.pPure = pPure
      this.pFaded = pFaded // it is ACTUALLY pure * weight * drop - WADED
      this.rex = rex; // so far
      this.matches = matches 
      this.log = log
      this.precision = this.computePrecision() // worst precision for words over matches
      
      this.errstr = null
      this.nReplacements = 0
  }

  // $$jsonFrom(jsonObj) {
  //   Object.keys(jsonObj).forEach(key => {
  //     this[key] = jsonObj[key];
  //   });
  // }

  static $$Create(jsonObj) {
    const c = new QMatching1({})
    Object.keys(jsonObj).forEach(key => {
      c[key] = jsonObj[key];
    });
    return
  }


  isHighlighted() { return this.nReplacements > 0 }
  htmlOf() {
    if (this.html) {
      return this.html
    } else {
      return this.txt
    }
  }
    
  getCloneOf() { // https://stackoverflow.com/questions/41474986/how-to-clone-a-javascript-es6-class-instance
    let clone = Object.assign(Object.create(Object.getPrototypeOf(this)), this)
    return clone

  }
  
  computePrecision() { // i.e the worst precions of words over matches
    // recall is known to caller :) ?? it is .. in a sense - this.pPure
    if (this.words == null || this.matches == null) return 0
    const nWords = this.words.length //stops a purged in words
    let worstPrecision = 1
    for (const match of this.matches) {
      const purgedMatch = Rex_PrepArray(Rex_ToArray(match))
      const precision = (purgedMatch.length == 0 ? 0 : nWords / purgedMatch.length)
      if (precision < worstPrecision) worstPrecision = precision
    }
    this.precision = worstPrecision
    return worstPrecision
  }
  
  hiHtml(minPureThreshHold = QMatching.barelyAceptablePure) {
    // returns html 
    // problems in this.errstr
      
  
      if (this.pPure < minPureThreshHold) return this.txt
  
      const txt = this.txt
      if (txt === undefined || txt === null) {
        return null
      }
      if (this.rex) {} else {
        return null
      }
  
      try {


        // const html = this.txt.replace(this.rex, (match) => {
        //   if (isAcceptableWord(match)) {
        //     return '<b><font color="brown">'+match+'</font></b>';
        //   }else {  
        //     return match // ie. unchanged
        //   }
        // });
  
        // this.html = HtmlToSpan ( {htmlString:html} )

        const struct = myHighlightOn(this.rex,this.txt)
        this.nReplacements = struct.nReplacements
        this.html = struct.html
        // and destory txt - for the sake of space ?!!
        this.txt = null

        return this.html
      } catch (e) {
        this.errstr = e.message
        return null
      }
    }
  
  }


  /*
  design note [$$Create]
  Here's how you can check if an object X is an instance of class XX and if XX has a static function F in JavaScript:

      JavaScript
      function hasStaticFunction(obj, className, staticFunctionName) {
        if (!obj || typeof obj !== 'object') {
          return false; // Not a valid object
        }

        // Check if it's an instance of the class
        if (!(obj instanceof (window[className] || eval(className)))) {
          return false;
        }

        // Check if the class has the static function (using optional chaining)
        return typeof (obj.constructor && obj.constructor[staticFunctionName]) === 'function';
      }
  */

export class QMatchingWeed{
  // constructor(
  //   { pBest: p = 0, pOverall = 0, pAcceptable = 0,
  //     pNumber= -1,  
  //     pDrop= -1, // with idea that pDropAtBestNumber * barelyAceptablePure is kinda acceptable ???
  //     errStr= null,
  //     qWords= qWords, qStems= qStems,
      
  //     lastResort=null, 
  //     pLastResort= 0,
      
  //     //paragraphs: paragraphs, // the zeroth contains title, even if empty
  //     xprgs= null // the zeroth contains title, even if empty
  //   }

  // ) {
  // // how to copy the arguent to this??
    
  // }
  constructor(params = null) { // chatgpt advice
    if (params) {
      Object.keys(params).forEach(key => {
        this[key] = params[key];
      });
    }
  }

  $$jsonFrom(jsonObj) {
    Object.keys(jsonObj).forEach(key => {
      this[key] = jsonObj[key];
    });
  }

  static $$Create(jsonObj) {
    const c = new QMatchingWeed(null)
    Object.keys(jsonObj).forEach(key => {
      c[key] = jsonObj[key];
    });
    return
  }

  // isTitleHighlighted() {
  //   return this.isXprgHighlighted(0) // see design note[indexes of title and quote]
  // }

  // isQuoteHighlighted() {
  //   return this.isXprgHighlighted(1) // see design note[indexes of title and quote]
  // }

  // isXprgHighlighted(pNumber) {
  //   const xprg = this.xprgs[pNumber]
  //   return xprg.html != null
  // }

  dropScoreOf() { // combines pure with drop
    return this.pBest * this.pDrop
    // but on individual paragrpahs it is pFaded ...
  }
 
  getTitleXprg() {
    return this.getXprg(0) // see design note[indexes of title and quote]
  }

  getQuoteXprg() {
    return this.getXprg(1) // see design note[indexes of title and quote]
  }

  getXprg(pNumber) {
    const xprg = this.xprgs[pNumber]
    return xprg
  }

  getParagraphs() {
    const pp = []
    for (let pNumber = 2; pNumber < this.xprgs.length; pNumber ++) {
      const xprg = this.xprgs[pNumber]
      pp.push(xprg.htmlOf())
    }
    return pp
  }

  highlight(minPureThreshHold) {
    let pNumber = 0
    for (const xprg of this.xprgs)
    { pNumber += 1
      if (xprg.pPure >= minPureThreshHold) {
        xprg.hiHtml(minPureThreshHold)
      }
    }
  }

  isTextHighlighted() {
    for (let pNumber = 2; pNumber < this.xprgs.length; pNumber++) {
      const xprg = this.xprgs[pNumber]
      if (xprg.isHighlighted()) return true
    }
    return false

  }

  isTitleOrQuoteHighlighted() {
    return (
      this.xprgs[0].isHighlighted()
      ||
      this.xprgs[1].isHighlighted()
    )
  }
}

//class QMatchingResult{}
