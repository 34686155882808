import { useState, useRef, useEffect} from 'react';
import {Button, Menu, MenuItem, Slide, Zoom} from '@mui/material';


import {FeedbackDialog} from "../Utils/Feedback.js" // TEMPORARY!!!

export function MenuUniversal({fullNames=[], selected=-1, onSelectIndex, isDisabled = false}) {
  const [anchor, setAnchor] = useState(null)

  const openMenu = (event) => {
    if (!isDisabled)
      setAnchor(event.currentTarget)
  }
  const closeMenu = (event) => {
    setAnchor(null)
  }
  const onMenuItemClick = (event, index) => {
    //setSelected(index)
    //selected = index
    onSelectIndex(index)
    closeMenu()
  }



  const FontSize = 8
//style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
  return (
    <div style={{display:"inline-block"
            //, maxWidthZZZ: '30px', maxHeight: '18px', minWidthZZZ: '30px', minHeight: '18px'
    }} styleZZZ={{marginTop:100, marginLeft:100}}>

      <Button variant = 'contained' 
        // style={{maxWidth: '10px', maxHeight: '10px', minWidth: '30px', minHeight: '10px'}}
        style={{ backgroundColor:selected == 0? 'gray' : 'blue',
          
          fontSize:FontSize,
          maxWidthZZZ: '30px', maxHeight: '18px', minWidthZZZ: '30px', minHeight: '18px'

        }}
        onClick={openMenu}
        >  {(selected < 0? 'State' : 
          //selected == 0? 'fffffffff': 
          fullNames[selected])
        }
      </Button>

      <Menu
        open={Boolean(anchor)}
        keepMounted
        anchorEl={anchor}
        onClose={closeMenu}
        TransitionComponent={Zoom}
        PaperProps = {
          {style:{ maxHeight: (40) * 5, width: '20ch' }}

        }
      >
        { 
          fullNames.map((fullName, index) =>
            <MenuItem 
              key={index}
              // style={{fontSize:FontSize}}
              onClick={(event) => {
                  return (fullName? onMenuItemClick(event, index)
                    : null)
                }
              }
              selected={index === selected}
            >
              {/* {fullName} */}
              {fullName ? fullName  : <strong>--------------------</strong>}
            </MenuItem>
          )
        }
      </Menu>


    </div>
  )
}

// export function MenuSt2Ex({options=[], selected=-1, onSelectIndex}) {
//   const [anchor, setAnchor] = useState(null)
//   const [customIsActive, setCustomIsActive] = useState(false)
//   const [customIsCurrent, setCustomIsCurrent] = useState(false)

//   const openMenu = (event) => {
//     setAnchor(event.currentTarget)
//   }
//   const closeMenu = (event) => {
//     setAnchor(null)
//   }
//   const onMenuItemClick = (event, index) => {
//     //setSelected(index)
//     //selected = index
//     onSelectIndex(index)
//     closeMenu()
//   }

//   //const options = ['All States', 'One', 'Two','Three', 'Four','Five', 'Six','Seven', 'North Carolina']

//   function onCustomUpdate(feedback) {// alternative ois "isCancel"
//     // switch (op) {
//     //   case 'opened': setCustomIsActive(true); break
//     //   case 'done': setCustomIsActive(false); break
//     //   case 'cancelled': setCustomIsActive(false); break
//     // }
//     alert(feedback)
//   }


//   const FontSize = 8
// //style={{maxWidth: '30px', maxHeight: '30px', minWidth: '30px', minHeight: '30px'}}
//   return (
//     <div style={{display:"inline-block"
//             //, maxWidthZZZ: '30px', maxHeight: '18px', minWidthZZZ: '30px', minHeight: '18px'
//     }} styleZZZ={{marginTop:100, marginLeft:100}}>

//       <Button variant = 'contained' 
//         // style={{maxWidth: '10px', maxHeight: '10px', minWidth: '30px', minHeight: '10px'}}
//         style={{ backgroundColor:selected == 0? 'gray' : 'blue',
          
//           fontSize:FontSize,
//           maxWidthZZZ: '30px', maxHeight: '18px', minWidthZZZ: '30px', minHeight: '18px'

//         }}
//         onClick={openMenu}
//         >  {(selected < 0? 'State' : 
//           //selected == 0? 'fffffffff': 
//           options[selected])
//         }
//       </Button>

//       <Menu
//         open={Boolean(anchor)}
//         keepMounted
//         anchorEl={anchor}
//         onClose={closeMenu}
//         TransitionComponent={Zoom}
//         PaperProps = {
//           {style:{ maxHeight: (40) * 5, width: '20ch' }}

//         }
//       >

//         <FeedbackDialog isOpen={customIsActive} onClose={() => 
//             {setCustomIsActive(false)}
//           }
//           onSubmitFeedback={onCustomUpdate} />

//         <MenuItem> 
//           <div onClick={(event) => onMenuItemClick(event, -1)}> Current Custom </div>
//           <strong onClick={()=>setCustomIsActive(true)}> edit </strong> 
//         </MenuItem>
//         { 
//           options.map((option, index) =>
//             <MenuItem 
//               key={option}
//               // style={{fontSize:FontSize}}
//               onClick={(event) => onMenuItemClick(event, index)}
//               selected={index === selected}
//             >
//               {option}
//             </MenuItem>
//           )
//         }
//       </Menu>


//     </div>
//   )
// }

// function CustomSets({isActive,onUpdate}) {
//   const [isOpen, setIsOpen] = useState(false)
//   return(
//     <div onClick={()=>setIsOpen(!isOpen)}> Custome Sets
//       <div style={{display:isOpen?"inline":"none"}}>
//         <div>one</div>
//         <div>two</div>
//         <div>two</div>
//         <div onClick={()=>{onUpdate(true); setIsOpen(false)}}>Done</div> 
//         <div onClick={()=>{onUpdate(false); setIsOpen(false)}}>Cancel</div>
//       </div>
//     </div>

    
//   )
// }



const Geo_Table= [
  ["All States", null],
  ["Swing States", ["OH", "GA", "NV", "WI", "PA", "MI", "AZ", "NC"  ]],
  null, // for a separator
  ["Alabama", "AL", 4447100, 52423, 32.7504062, -86.7502625],
  ["Alaska", "AK", 643786, 663267, 64.0002778, -150.0002778],
  ["Arizona", "AZ", 5456453, 113998, 34.5003037, -111.5009764],
  ["Arkansas", "AR", 2710079, 53179, 34.7503697, -92.5004364],
  ["California", "CA", 33872648, 163707, 37.2502247, -119.7512643],
  ["Colorado", "CO", 4301261, 104100, 38.8338816, -104.8213634],
  ["Connecticut", "CT", 3405565, 5544, 41.6670439, -72.6664819],
  ["Delaware", "DE", 783600, 1984, 39.0003906, -75.4999224],
  ["Florida", "FL", 15982378, 65758, 28.7505408, -82.5000976],
  ["Georgia", "GA", 8186453, 59441, 32.7504171, -83.5001803],
  ["Hawaii", "HI", 1511231, 10932, 20.7502778, -156.5002778],
  ["Idaho", "ID", 1293953, 83574, 44.5001999, -114.2511761],
  ["Illinois", "IL", 12419293, 57918, 40.0003204, -89.2503681],
  ["Indiana", "IN", 6080485, 36420, 40.0003166, -86.2502707],
  ["Iowa", "IA", 2926324, 56276, 42.0002654, -93.5004916],
  ["Kansas", "KS", 2688418, 82282, 39.1141708, -94.627457],
  ["Kentucky", "KY", 4041768, 40411, 38.2004177780005, -84.8776245117188],
  ["Louisiana", "LA", 4468976, 51843, 31.000467, -92.0003987],
  ["Maine", "ME", 1274923, 35387, 45.5003249, -69.2497714],
  ["Maryland", "MD", 5296486, 12407, 39.000388, -76.749969],
  ["Massachusetts", "MA", 6349097, 10555, 42.3656470028119, -71.1083221435547],
  ["Michigan", "MI", 9938444, 96810, 44.2502852, -85.500333],
  ["Minnesota", "MN", 4919479, 86943, 46.2502448, -94.2505535],
  ["Mississippi", "MS", 2844658, 48434, 32.7504114, -89.750357],
  ["Missouri", "MO", 5595211, 69709, 38.2503112, -92.5004614],
  ["Montana", "MT", 902195, 147046, 47.0002454, -109.7510212],
  ["Nebraska", "NE", 1711263, 77358, 41.5002828, -99.7506736],
  ["Nevada", "NV", 1998257, 110567, 39.2502081, -116.7511911],
  ["New Hampshire", "NH", 1235786, 9351, 43.667019, -71.4997965],
  ["New Jersey", "NJ", 8414350, 8722, 40.1670562, -74.4998748],
  ["New Mexico", "NM", 1819046, 121593, 34.5003439, -106.0008454],
  ["New York", "NY", 18976457, 54475, 40.7142691, -74.0059729],
  ["North Carolina", "NC", 8049313, 53821, 35.5006942, -80.0003243],
  ["North Dakota", "ND", 642200, 70704, 47.500274, -100.0006798],
  ["Ohio", "OH", 11353140, 44828, 40.2503398, -83.0001841],
  ["Oklahoma", "OK", 3450654, 69903, 35.4675602, -97.5164276],
  ["Oregon", "OR", 3421399, 98386, 44.0001313, -120.5013876],
  ["Pennsylvania", "PA", 12281054, 46058, 40.2724534393745, -76.9056701660156],
  ["Rhode Island", "RI", 1048319, 1545, 41.7503782, -71.4997825],
  ["South Carolina", "SC", 4012012, 32007, 34.0004338, -81.000091],
  ["South Dakota", "SD", 754884, 77121, 44.5002605, -100.2506864],
  ["Tennessee", "TN", 5689283, 42126, 35.7503468, -86.250271],
  ["Texas", "TX", 20851820, 268601, 31.2504424, -99.2506061],
  ["Utah", "UT", 2233169, 84904, 39.2502391, -111.7510337],
  ["Vermont", "VT", 608827, 9615, 44.0003417, -72.7498308],
  ["Virginia", "VA", 7078515, 42769, 36.8529263, -75.977985],
  ["Washington", "WA", 5894121, 71313, 47.5001238, -120.5014693],
  ["West Virginia", "WV", 1808344, 24231, 38.5003838, -80.5000866],
  ["Wisconsin", "WI", 5363675, 65503, 44.5002368, -90.0004077],
  ["Wyoming", "WY", 493782, 97818, 43.0002381, -107.5009043],
  ["District Columbia", "DC", 572059, 61, 38.8951118, -77.0363658], // ' "?Washington DC" ?
]


const Fullname_Array = Geo_Table.map((e,index)=>e==null?null:e[0])
const St2_Array = Geo_Table.map((e,index)=>e==null?null:e[1])

// const FullName_ToIndex_Map = (function () {
// }

const Nm_To_Index =(function () {
  const res = new Map()
  for (let i = 0; i < Fullname_Array.length; i++) {
    const nm = Fullname_Array[i]
    if (nm && typeof nm === 'string') {
      res[nm.toLowerCase()] = i
    }
  }

  for (let i = 0; i < St2_Array.length; i++) {
    const nm = St2_Array[i]
    if (nm && typeof nm === 'string') {
      res[nm.toLowerCase()] = i
    }
  }


  return res
}
)()

const Nm_To_Definition = (function () {
  const res = new Map()
  for (let i = 0; i < Fullname_Array.length; i++) {
    const nm = Fullname_Array[i]
    if (nm) res[nm.toLowerCase()] = Geo_Table[i][1]
  }
  return res
}
)()

const Definition_To_Name = (function () {
  const res = new Map()
  for (let i = 0; i < Geo_Table.length; i++) {
    const row = Geo_Table[i]
    if (row == null) continue
    const def = row[1]
    if (typeof def == "string")
      res[def.toLowerCase()] = row[0]
  }
  return res
}
)()

// function Nm_Is_Undefined(geoName) {
//   return Nm_To_Definition === undefined
// }



// const Fullname_To_Index_Map = new Map( // for given index - e[1]
//   Geo_Table.map((record, index) => [index, e[1] ])
// )

// const Index_To_Fulname_Map = new Map( // for given index - e[1]
//   Geo_Table.map((record, index) => [index, e[1] ])
// )

export function St2s_by_Fullname(geoName) {
  // returns an array of st2-as
  if (geoName == null) return null
  //Nm_Is_Undefined(geoName) return nu
  let st2s = Nm_To_Definition[geoName.toLowerCase()]
  if (st2s == null) {
    // try harder - it may be an st2
    const idx = Nm_To_Index[geoName.toLowerCase()]
    if (idx) st2s = St2_Array[idx]
  }// i want to caller know if result is UNDEFINED as well 

  if (typeof st2s == "string") return [st2s]
  return st2s
}
export function Fullname_By_St2s(st2) {
  // returns an array of st2-as
  const nm = Definition_To_Name[st2.toLowerCase()]
  return nm ?? st2
}

// const UsStates_Table= [
//   //[null],
//   ["Alabama", "AL", 4447100, 52423, 32.7504062, -86.7502625],
//   ["Alaska", "AK", 643786, 663267, 64.0002778, -150.0002778],
//   ["Arizona", "AZ", 5456453, 113998, 34.5003037, -111.5009764],
//   ["Arkansas", "AR", 2710079, 53179, 34.7503697, -92.5004364],
//   ["California", "CA", 33872648, 163707, 37.2502247, -119.7512643],
//   ["Colorado", "CO", 4301261, 104100, 38.8338816, -104.8213634],
//   ["Connecticut", "CT", 3405565, 5544, 41.6670439, -72.6664819],
//   ["Delaware", "DE", 783600, 1984, 39.0003906, -75.4999224],
//   ["Florida", "FL", 15982378, 65758, 28.7505408, -82.5000976],
//   ["Georgia", "GA", 8186453, 59441, 32.7504171, -83.5001803],
//   ["Hawaii", "HI", 1511231, 10932, 20.7502778, -156.5002778],
//   ["Idaho", "ID", 1293953, 83574, 44.5001999, -114.2511761],
//   ["Illinois", "IL", 12419293, 57918, 40.0003204, -89.2503681],
//   ["Indiana", "IN", 6080485, 36420, 40.0003166, -86.2502707],
//   ["Iowa", "IA", 2926324, 56276, 42.0002654, -93.5004916],
//   ["Kansas", "KS", 2688418, 82282, 39.1141708, -94.627457],
//   ["Kentucky", "KY", 4041768, 40411, 38.2004177780005, -84.8776245117188],
//   ["Louisiana", "LA", 4468976, 51843, 31.000467, -92.0003987],
//   ["Maine", "ME", 1274923, 35387, 45.5003249, -69.2497714],
//   ["Maryland", "MD", 5296486, 12407, 39.000388, -76.749969],
//   ["Massachusetts", "MA", 6349097, 10555, 42.3656470028119, -71.1083221435547],
//   ["Michigan", "MI", 9938444, 96810, 44.2502852, -85.500333],
//   ["Minnesota", "MN", 4919479, 86943, 46.2502448, -94.2505535],
//   ["Mississippi", "MS", 2844658, 48434, 32.7504114, -89.750357],
//   ["Missouri", "MO", 5595211, 69709, 38.2503112, -92.5004614],
//   ["Montana", "MT", 902195, 147046, 47.0002454, -109.7510212],
//   ["Nebraska", "NE", 1711263, 77358, 41.5002828, -99.7506736],
//   ["Nevada", "NV", 1998257, 110567, 39.2502081, -116.7511911],
//   ["New Hampshire", "NH", 1235786, 9351, 43.667019, -71.4997965],
//   ["New Jersey", "NJ", 8414350, 8722, 40.1670562, -74.4998748],
//   ["New Mexico", "NM", 1819046, 121593, 34.5003439, -106.0008454],
//   ["New York", "NY", 18976457, 54475, 40.7142691, -74.0059729],
//   ["North Carolina", "NC", 8049313, 53821, 35.5006942, -80.0003243],
//   ["North Dakota", "ND", 642200, 70704, 47.500274, -100.0006798],
//   ["Ohio", "OH", 11353140, 44828, 40.2503398, -83.0001841],
//   ["Oklahoma", "OK", 3450654, 69903, 35.4675602, -97.5164276],
//   ["Oregon", "OR", 3421399, 98386, 44.0001313, -120.5013876],
//   ["Pennsylvania", "PA", 12281054, 46058, 40.2724534393745, -76.9056701660156],
//   ["Rhode Island", "RI", 1048319, 1545, 41.7503782, -71.4997825],
//   ["South Carolina", "SC", 4012012, 32007, 34.0004338, -81.000091],
//   ["South Dakota", "SD", 754884, 77121, 44.5002605, -100.2506864],
//   ["Tennessee", "TN", 5689283, 42126, 35.7503468, -86.250271],
//   ["Texas", "TX", 20851820, 268601, 31.2504424, -99.2506061],
//   ["Utah", "UT", 2233169, 84904, 39.2502391, -111.7510337],
//   ["Vermont", "VT", 608827, 9615, 44.0003417, -72.7498308],
//   ["Virginia", "VA", 7078515, 42769, 36.8529263, -75.977985],
//   ["Washington", "WA", 5894121, 71313, 47.5001238, -120.5014693],
//   ["West Virginia", "WV", 1808344, 24231, 38.5003838, -80.5000866],
//   ["Wisconsin", "WI", 5363675, 65503, 44.5002368, -90.0004077],
//   ["Wyoming", "WY", 493782, 97818, 43.0002381, -107.5009043],
//   ["District Columbia", "DC", 572059, 61, 38.8951118, -77.0363658], // ' "?Washington DC" ?
// ]



// export function St2_FullNameOf(st) {
//   // first attempt the easy way
//   let fullname = FullName_By_St2.get(st)
//   return fullname
// }



// const FullName_By_St2 = new Map(
//      UsStates_Table.map(e => [e[1], e[0] ])
//   )
// const St2_By_FullName = new Map(
//     UsStates_Table.map(e => [e[0].toLowerCase(), e[1] ])
//  )

export function MenuUniSt2({onSelect, geoName, isDisabled=false}) {
  //const [anchor, setAnchor] = useState(null)
  const [selected, setSelected] = useState(0) // 0-th is select all states

  useEffect(() => {
    //return 
    let newSelectedIndex = Nm_To_Index[geoName == null? null : geoName.toLowerCase()]
    // for (let i = 0; i < Geo_Table.length; i++) {
    //   //console.log('finding ' + i)
    //   geoName = geoName == null ? null : geoName.toLowerCase()
    //   const e = Geo_Table[i]
    //   if (e[0].toLowerCase() == geoName || e[1].toLowerCase() == geoName) {
    //     newSelectedIndex = i + 1 ; break
    //   } 
    // }
    //console.log('found ' + st2)
    if (newSelectedIndex == null) 
      newSelectedIndex = 0
    
    setSelected(newSelectedIndex)
  }, [geoName]);



  const onSelectIndex = (index) => {
    if (onSelect) onSelect(Fullname_Array[index])
    setSelected(index)
    //closeMenu()
  }


  // const options_St2 = [null, // 0-th is select all states
  //   ...UsStates_Table.map((record)=>record[1]) //['All States', 'One', 'Two','Three', 'Four','Five', 'Six','Seven', 'North Carolina']
  // ]

  // const options_FullName = ['All States', // 0-th is select all states
  //   ...UsStates_Table.map((record)=>record[0]) //['All States', 'One', 'Two','Three', 'Four','Five', 'Six','Seven', 'North Carolina']
  // ]

  return (
    <MenuUniversal fullNames={Fullname_Array} selected={selected} onSelectIndex={onSelectIndex} isDisabled={isDisabled}/>
  )
}


///////////////////////////////////////////////////////////////////////
// export function SortMenu() {
//   return (
//     <>


// <div style={{ position: "relative" 
//           //, border:'1px solid green'
//           }}>
      

//           {/* <Badge badgeContent={4} color="primary" 
//             anchorOrigin={{
//               vertical: 'bottom',
//               horizontal: 'right',
//             }}
//           > */}


//             <Tooltip 
//               // title={ // somebody's advice on how to handle cnaelling tip in material  ui...
//               //   (!$$.toolTipSortEnabled ? '': 
//               //     ($$.activeSort == 'default'? Default_Sort_String : Date_Sort_String)
//               //   )
//               // }
//               title={ $$.showSortOptions? '' // means no tooltip - see https://stackoverflow.com/questions/53591747/conditionally-activate-material-ui-tooltip
//                 : <DbgMore htmlKey='sorting' isLong={$$.helpMode} udCallBack={udCallBack}
//                 htmlShort={
//                   !$$.toolTipSortEnabled ? null: // see design note [2 nulls for tooltip]
//                 ($$.activeSort == 'default'? Default_Sort_String : Date_Sort_String)
//               } 
//               />}

//               enterDelay={$$.helpMode? 0 : 3000}

              
//               //open={toolTipSortEnabled}
//               // enterDelay={3000} leaveDelay={100}
//             >
              
//                 <IconButton  
//                   onClick= {
//                   () => {
//                     $$change('showSortOptions', !$$.showSortOptions) ; //setShowSortOptions(prev => !prev)
//                     $$change('toolTipSortEnabled', !$$.toolTipSortEnabled) ; //setToolTipSortEnabled(prev => !prev)
//                   }
//                 } 
//                 > 
//                 {$$.activeSort=='date' ? 
//                   <ScheduleIcon style = {{...style_angry_control,...{color: Angry_Color}, }}
//                     />
//                   :
//                   <SortIcon style = {{...style_angry_control,...{color: Angry_Color}}}/>
//                 }


  
              
//                 </IconButton>
//               </Tooltip> 



//               {/* </Badge>  */}

//               {$$.showSortOptions && (
//                 <div style={{display:"inline-block", position:"absolute", 
//                 top: "100%", 
//                 //left: "25%",
//                 right: 0,  // Adjusted here
//                 zIndex: 1,
//                 boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
//                 backgroundColor: 'white',
//                 minWidth: '120px',

//                 //border:'1px solid blue'
                
//                 }}
          
                
//                 >
                  
//                 <div style={{ padding: '8px 16px', 
//                     fontSize:'small',
//                     cursor: 'pointer', 
//                     backgroundColor: $$.activeSort == 'default' ? "#f0f0f0" : "white", 
//                     borderBottom: '1px solid #e0e0e0', transition: 'background-color 0.3s'
//                   }}                  
//                     onClick={(e) => { $$change('toolTipSortEnabled', true) //setToolTipSortEnabled(true); 
//                       //setActiveSortWrapper('default');  
//                       $$change('activeSort','default')
//                       $$change('showSortOptions', false) //setShowSortOptions(false)
//                       finishWithSort('default', $$.siteMode, $$.items, "by ..")

//                     }}
//                     onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
//                     onMouseOut={(e) => e.currentTarget.style.backgroundColor = $$.activeSort == 'default' ? "#f0f0f0" : "white"}
               
//                   >{
//                     //'By Relevance'
//                     Default_Sort_String
//                   }</div>

//                   <div style={{ padding: '8px 16px', 
//                     fontSize:'small',
//                     cursor: 'pointer', 
//                     backgroundColor: $$.activeSort == 'date' ? "#f0f0f0" : "white", 
//                     borderBottom: '1px solid #e0e0e0', transition: 'background-color 0.3s' 
//                   }} 
//                     onClick={(e) => { $$change('toolTipSortEnabled', true) //setToolTipSortEnabled(true); 
//                       //setActiveSortWrapper('date');  
//                       $$change('activeSort','date')
//                       $$change('showSortOptions',false) //setShowSortOptions(false)
//                       finishWithSort('date', $$.siteMode, $$.items, "by ...")
//                     }}
//                     onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
//                     onMouseOut={(e) => e.currentTarget.style.backgroundColor = $$.activeSort == 'date' ? "#f0f0f0" : "white"}
               
//                   >{
//                     //'By Date' 
//                     Date_Sort_String
//                   }</div>
//                 </div>

                
//               )}
//           </div>






//     </>
//   )
// }


export function SortMenu() {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  return (
      <div>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
              Open Menu
          </Button>
          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
          >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
          </Menu>
      </div>
  );
}