import produce from 'immer';
import {ParticipantSelect} from "./CnvScroll.js"
import {useEffect, useRef, useState} from "react"


// reddit discussion about the system prompt
//https://www.reddit.com/r/OpenAI/comments/11p7p5o/how_do_i_get_gpt35_turbo_to_act_as_a_human/
//..
//https://dev.to/msveshnikov/telegram-bot-with-openai-gpt-35-gpt-35-turbo-connection-498h



// "intro" will be the system prompt
// icons are material-ui
const AiPageDefaults={ version:"1.0"
    , emulate: {calls: false}

    , cnvAssembly: {
        removeAsAi: false,
        combineMe: false,
        lie: false
    }
    
    , "participants":       [
        { "name" : "me", "dname": "Human"
            , "intro" : ""
            , isHuman: true
            ,icon: 'path/to/user-icon.svg', bgColor: '#48c78e' , iconName: "sentiment_neutral"
        }
        , { "name" : "a", "dname": "Alice"
            , "intro" : ["Please call yourself Alice"
                                                       ]
            , "model":""
            , icon: 'path/to/guest-icon.svg', bgColor: '#3e8ed0' , iconName: "face_4"

        }
        , { "name" : "b", "dname": "Bob"
            , "intro" : ["Please call yourself Bob"
                                                       ]
            , "model":""
            , icon: 'path/to/admin-icon.svg', 
            bgColor: '#ffe08a', 
            iconName: "face_5"

        }
    ]
}

const Apd = function () {return AiPageDefaults}

const ApdEx = function () {return {apd:AiPageDefaults,
        genEmulateCall: async (auth='') =>  {

            const t = 3000

            await (new Promise(resolve => setTimeout(resolve, t)))

            return "emulating call to " + auth
        }

    }
}

export {Apd, ApdEx, AiPageDefaults} ;


////////////////////////////////////////////////////////////////////////////////////////////





export const ApdState_Key='AiPage_QuestionStorage_Key'
export const AdvParamsEdit2 = function({ apdState, setApdState, apdStateDefault = null }) {



    if (apdStateDefault == null) {
        const apdStateJson = window.localStorage.getItem(ApdState_Key)
        setApdState(JSON.parse(apdStateJson))
        
    } else if (apdState == null) {
        setApdState(apdStateDefault)
    }

    const rrrDialog = useRef()
    const [textareaValue, setTextareaValue] = useState(JSON.stringify(apdState, null, 2))


    


    useEffect(() => {
        setTextareaValue(JSON.stringify(apdState, null, 2))
    }, [apdState])

    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value)
    }

    const handleButtonClick = () => {
        try {
            const newApdState = JSON.parse(textareaValue)
            setApdState(newApdState)

            var apdAsJson = JSON.stringify(newApdState, null, 2)
            window.localStorage.setItem(ApdState_Key, apdAsJson)

            rrrDialog.current.close()
        } catch (e) {
            alert(e)
        }
    }

    const handleDefaultClick = () => {
        if (apdStateDefault) setApdState(AiPageDefaults)
    }

    return (
        <>
            <button data-open-modal onClick={(e) => rrrDialog.current.showModal()}>
                Param Edit Open
            </button>

            <br />

            <dialog 
                data-modal 
                ref={rrrDialog}
                style={{
                    width: "100%", 
                    height: "100%", 
                    backgroundColor: "yellow",
                    zIndex: "999",
                }}
            >
                <textarea 
                    name="lll"
                    style={{
                        width: "100%",
                        height: "500px",
                        boxSizing: "border-box",
                        resize: "none",
                        margin: 0,
                        padding: 0,
                        outline: "none",
                        border: "1px solid #ccc",
                    }}
                    value={textareaValue}
                    onChange={handleTextareaChange}
                />

                <button onClick={handleButtonClick}>Close</button>
                &nbsp;
                <button onClick={() => rrrDialog.current.close()}>Cancel</button>
                &nbsp;
                <button onClick={handleDefaultClick}>Restore Defaults</button>
            </dialog>
        </>
    )
}

export const AdvParamsEdit = function({
        apdState, setApdState,
        participants, pnifs, handlePnifsChange}) {
    //const mmmRef = useRef();

    // useEffect(() => {
    //     setApdState({ ...apdState, field: updatedField });
    //     }, []);

    // copy of subbranch

    const emul = {...apdState.emulate}
    const ass = {...apdState.cnvAssembly}

    return (



    <div >
        Conversation Hihglights:
        

        <ParticipantSelect selectedNames={pnifs}
            handleChange={handlePnifsChange}
            participants={participants} />
        <br/>

        Assembly Booleans:
        <div>



            <input type="checkbox" name="removAsAi" 
                        // value="{ass.removeAsAi}"
                        checked = {ass.removeAsAi}
                        // value={true}
                        onClick={(e) => {
                            ass.removeAsAi = e.target.checked
                            const newState = {...apdState, cnvAssembly : ass}
                            setApdState(newState);
                        }}
                    />Remove 'as ai'<br/>     

                    <input type="checkbox" name="combineMe" value="Dogs"/>Dogs<br/>      
                    <input type="checkbox" name="lie" value="Birds"/>Birds<br/>
                    ------------- <br/>
                    <input type="checkbox" name="emulCalls" value="{emul.calls}"
                        onClick={(e) => {
                            emul.calls = e.target.checked
                            const newState = {...apdState, emulate : emul}
                            setApdState(newState);
                        }}
                    />Emulate Calls<br/>

        </div>


    </div>

            );

}


// export const ApdState_Edit = function(apdState, setApdState) {
//     const [cpyApdState, setCpyApdState] = useState(apdState)

//     useEffect(() => {
//         setApdState({ ...apdState, field: updatedField });
//       }, []);


//     function onCheck(e) {
//         const x = e.target.checked 
//         apdState.cnvAssembly.removeAsAi = x 
//         setApdState(apdState)
//     }

//     const ass = cpyApdState.cnvAssembly
//     return (

//         <div>
//                             Apd State
//             <div>
//                 <input type="checkbox" name="removAsAi" 
//                     value="{ass.removeAsAi}"
//                     onClick={(e) => ass.removeAsAi = e.target.checked}
//                 />Remove 'as ai'<br/>     

//                 <input type="checkbox" name="combineMe" value="Dogs"/>Dogs<br/>      
//                 <input type="checkbox" name="lie" value="Birds"/>Birds<br/>
//             </div>

//         </div>


//     );
//   }

/* 


Yes, you are correct that every time you call showModal(), your ApdState_Edit function will be called, and the apdState.field value will be updated by appending 'abc' to it. The updated value will be displayed in the <div> within the ApdState_Edit component.

However, directly modifying the state like this is not recommended in React, as it can lead to unexpected behavior and make it difficult to manage state updates. Instead, it's better to use the setApdState function provided by the useState hook to ensure proper state management and component updates.

To achieve the desired behavior without directly modifying the state, you can pass both apdState and setApdState to the ApdState_Edit function:

javascript
Copy code
export const ApdState_Edit = function(apdState, setApdState) {
  const updatedField = 'abc' + apdState.field;

  useEffect(() => {
    setApdState({ ...apdState, field: updatedField });
  }, []);

  return <div>{updatedField}</div>;
}
Then, when calling ApdState_Edit from the AAA component, pass both apdState and setApdState:

javascript
Copy code
<ApdState_Edit apdState={apdState} setApdState={setApdState} />
This way, the ApdState_Edit component will update the state using setApdState without directly modifying the apdState object, ensuring that React can properly manage the state and trigger component updates.





const [apdState, setApdState] = useState(AiPageDefaults)
export const ApdState_Edit = function(apdState) {

    apdState.field = 'abc' + apdState.field
    return (<div> {apdState.field} </div>)
}  

<dialog> 
            <ApdState_Edit apdState={apdState}

                                <button 
                                    onClick = {(e) => {
                                   
                                        setApdState(apdState)
                                    
                                    }}
                                >Close</button>
                            </div>
                        </dialog> 

*/