/*

type NullableString = string | null;

let myVar: NullableString;
myVar = null; // This is allowed now.

*/


import { Date } from "mongoose";
import {HashCode_LordVlad} from "../Utils/MiscForApp.js"

// please rewrite this piece of code in typescript
export class QnA_Unit {

    cnvId: string|null = null;
    txt: string|null  = null;

    idx: number  = -1;

    prefix: string|null = null;
    suffix: string|null = null;
    auth: string|null = null;
    targets: string[]|null = [];
    ts: Date|null = null;


    constructor({
        txt = null,
        prefix = null,
        suffix = null, 
        auth = null,
        targets = null,
        idx = -1,
        cnvId = null
    } : {
        cnvId?: string|null,
        idx?: number,
        txt?: string|null,
        prefix?: string|null,
        suffix?: string|null,
        auth?: string|null,
        targets?: string[]|null,
    } = {}) {
        
        this.cnvId = cnvId
        this.txt = txt;

        this.idx = idx
    
        this.prefix = prefix // i.e. Remember to stay in character
        this.suffix = suffix
        this.auth = auth // design note [question is just the last in context] actuall the question is just the last in the context?
        this.targets = targets // null means 'all'
        this.ts = null
        
    }

    //hashOf = () => {return HashCode_LordVlad(this.q + '\n' + this.a)}

    static Record_To_Unit(record:any) {
        const unit = new QnA_Unit()
            unit.cnvId = record.cnvId
            unit.txt = record.txt

            unit.idx = record.idx

            unit.prefix = record.prefix
            unit.suffix = record.suffix
            unit.auth = record.auth
            unit.targets = record.targets
            unit.ts = record.ts

        return unit
    }

    static Record_From_Unit(unit:QnA_Unit): any {
        const record:any = {
            cnvId : unit.cnvId
            , txt: unit.txt

            , idx: unit.idx

            , prefix: unit.prefix
            , suffix: unit.suffix
            , auth: unit.auth
            , targets: unit.targets
            , ts: unit.ts

        }
        return record
    }

    cloneOf():QnA_Unit {
        
/* https://www.javascripttutorial.net/object/3-ways-to-copy-objects-in-javascript/
// using spread ...
let p1 = {
    ...person
};

// using  Object.assign() method
let p2 = Object.assign({}, person);

// using JSON
let p3 = JSON.parse(JSON.stringify(person)); */
        const c = JSON.parse(JSON.stringify(this));
        return c

    }


    combined(insep:string|null): string | null {
        return this.combined_Author(null, insep)
    }

    combined_Author(author:string | null, insep:string | null) : string | null {
        insep = insep ?? '\n'
        const authorCanSeeThis = this.isSeeableBy(author)
        // if you are not the author hide your suffixes and prefixes
        
        if (! authorCanSeeThis) {
            return ''
        }

        let ingredients:string[] = []
        if (this.prefix) ingredients.push(this.prefix)
        if (this.txt) ingredients.push(this.txt) // 
        if (this.suffix) ingredients.push(this.suffix)
        return ingredients.join(insep)
    }

    isSeeableBy(author:string|null) : boolean {
        // 
        if (author == null) return true
        if (this.targets == null) return true
        if (this.targets.length == 0) return true //?
        if (this.targets.includes('all')) return true //?
        if (this.targets.indexOf(author) > -1) return true //?
        return false

        //return author == null || this.targets == null  || this.targets.indexOf(author) > -1
    }
}


// see https://help.openai.com/en/articles/6654000-best-practices-for-prompt-engineering-with-openai-api
export class QnA_List {

    // cnvId?: string;
    // This makes cnvId an optional property with a default value of undefined. However, if you specifically need the default value to be null, you can stick with your original code.


    cnvId: string|null = null
    dfltPrefix:string|null = null
    dfltSuffix:string|null = null
    pJoiner:string|null = null
    
    //lst: [] | null = []
    lst: QnA_Unit[] = []//| null = null

    lastError:string|null = null
    defaultOutersep:string|null = null

    constructor(cnvId:string|null = null
        , dfltPrefix:string|null = null
        , dfltSuffix:string|null = null
        , paragraphJoiner:string|null = null) {
        this.cnvId = cnvId
        this.dfltPrefix = dfltPrefix ?? null
        this.dfltSuffix = dfltSuffix ?? null
        this.pJoiner = paragraphJoiner ?? '\n'
        this.lst = []
        this.lastError = null
        this.defaultOutersep = this.pJoiner
    }

    unitAt(i:number) : QnA_Unit {
        const x = this.lst[i];//??null 
        return  x //this.lst?[i]??null
      }
  
    length():number {return (this.lst?.length ?? 0)} // optional chaining with the nullish coalescing

    add(unit: QnA_Unit) {
        // const x = this.lst ?? [] //[...(this.lst??[]), unit]
        // const y = [...this.lst??[], unit]
        // const t=[...[], unit]
        // this.lst?.push(unit)
        // const z:QnA_List[] | null = y
        this.lst = [...(this.lst??[]), unit]
        return 'ss'
    }

    combined(lsep: string|null) {
        lsep = lsep ?? this.pJoiner ?? '\n'
        let paragraphs = [] as string[]
        for (let i = 0; i < this.length(); i++) {
            const u:QnA_Unit = this.lst[i]
            let paragraph:string|null = (u.combined(lsep) ?? "")
            paragraphs.push(paragraph)
        }
        return paragraphs.join(lsep)
    }

    combined_Author(author: string, outersep: string | null = null) {
        outersep = outersep ?? this.pJoiner ?? '\n'
        let paragraphs = [] as string[]

        for (let i = 0; i < this.length(); i++) {
            const u = this.lst[i]
            let paragraph = (u.combined(author) ?? "")
            paragraphs.push(paragraph)
        }
        return paragraphs.join(outersep)
    }

    getUnitsFor(author) : QnA_Unit[] {

        let units:QnA_Unit[] = []
        for (let i = 0; i < this.length(); i++) {
            const u:QnA_Unit = this.lst[i]
            
            if (!u.isSeeableBy(author)) {
                continue
            }
            units.push(u)
        }
        return units
    }

    // unitAt(i) {
    //     return this.lst[i]
    // }

    getUnits() {

        return this.lst
    }

    cloneOf(newCnvId : string) {
        const cloneLst = this.lst.map((unit,index)=>{
            const c = unit.cloneOf()
            c.cnvId = newCnvId
            return c
        })
        const cc = new QnA_List(newCnvId)
        cc.lst = cloneLst
        cc.lastError = null
        return cc
    }



    generateRecords() {
        let units = this.getUnits()
        if (units) {
            const records = units.map((unit, indexInConversation)=>{ 
                const record=QnA_Unit.Record_From_Unit(unit); 
                return record
            } )
            return records
        } else {
            return null
        }

    }

    udCallBack = function(universalDictChange) {
    // returns an array of changed units
        let changedUnits : QnA_Unit[] = []
        if (universalDictChange["targetChange"]) {
            const targetChange = universalDictChange["targetChange"]
            const qnaIdx = targetChange["idx"]
            const targetName = targetChange["targetName"]

            const unit = this.lst[qnaIdx]
            if (targetName == null || targetName == 'all') {
                unit.targets = null
            } else { 
                unit.targets = [targetName] 
            }
            changedUnits.push(unit) // =  [unit]
        }

        if (universalDictChange["contentChange"]) {
            const info = universalDictChange["contentChange"]
            const qnaIdx = info["idx"]
            const changedTxt = info["content"]

            const unit = this.lst[qnaIdx]
            unit.txt = changedTxt

            return [unit]
        }

        return changedUnits //null
    }

    static createFromRecords(records, cnvIdIfNoRecords) {
        

        let qnal = new QnA_List()
        if (! Array.isArray(records)) {
            qnal.cnvId = cnvIdIfNoRecords
            return qnal
        }

        try {
        
            if (records.forEach === undefined) 
                return qnal

            let firstRecord = null
            records.forEach((record) => {
                if ( firstRecord == null)  firstRecord = record
                let unit = QnA_Unit.Record_To_Unit(record) 
                qnal.add(unit)

                // // take the conversation id from the first record???
                // if (firstRecord == null) {
                
                //     this.cnvId = firstRecord.cnvId
                //     if (this.cnvId != cnvIdIfNoRecords) 
                //         throw new Error("Assertion with cnvId in createFromRecords ")
                // }
            

            });
            if (qnal.cnvId == null) qnal.cnvId = cnvIdIfNoRecords
            return qnal
        } catch(e) {
            return e.message;
        }
        
    }

    BuildFilter(cnvId, {iFrom = null, iTo = null}) {
        if (!this.lst) return {}

        let  idxArr = this.lst.map((unit,index)=>{
            if (iFrom != null && index < iFrom) return null
            if (iTo != null && index >= iTo) return null
            return unit.idx }) //unit.idx})
   


        let filter = { "cnvId" : cnvId 
            , "idx": { "$in": idxArr }
            // or :
            // , "idx": { $gte: markedIdx}

        }
        return filter

        // chat advise : QnaPair.deleteMany({ age: { $gt: 70 } })
    }

    createQnaUnit ({txt, auth, targets = null}) {
        let maxIdx = -1
        for (let i = 0; i < this.length(); i++) {
            const unit = this.lst[i];

            if (maxIdx < unit.idx) maxIdx = unit.idx 

        } 
        const newQnaUinit = new QnA_Unit ({txt : txt, auth : auth,  targets : targets, idx : maxIdx + 1,
            cnvId: this.cnvId})
        return  newQnaUinit
    }

    getLastUnitByAuth(auth) {
        for (let i = this.lst.length - 1; i >= 0; i--) {
            const unit = this.lst[i]
            if (unit.auth == auth) return unit
        } 
        return null
    }


}


