
//import {useNavigate} from "react-router-dom" 

import {useState, useEffect, useRef} from "react" 
import axios from "axios"
//import ContentEditable from 'react-contenteditable'

//import {IsElementInView, GetScrollableParent} from "../Utils/MiscForApp.js"


//import { Checkbox } from "@mui/material"



export default function ElraiPage() {
  const [items, setItems] = useState([]);
  const [filter, setFilter] = useState({score:{$gte:0.4}});
  const [options, setOptions] = useState({});
  const filterInputRef = useRef();

  const fetchData = () => {
    // const result = Get_Elrais({ filter, options });
    // setItems(result.data);
    const fStr = filterInputRef.current.value
    try {

      const fJson = JSON.parse(fStr)
    
    
      setFilter(fJson) // filter value will  be updated on the next react cycle??
      Get_Elrais({ filter:fJson, options }).then((result) => {setItems(result.data)});
    } catch(e) {alert(e.message); return;}
  };

  const handleFilter = () => {
    // Apply the filter logic here
    // For example:
    setFilter({ source: 'Filtered Source' });
    fetchData();
  };

  const handleSort = () => {
    // Apply sorting logic here
    // For example:
    const sortedItems = [...items].sort((a, b) => a.title.localeCompare(b.title));
    setItems(sortedItems);
  };

  const handleRefresh = () => {
    fetchData(filter, options);
  };

  const handleFilterInputChange = () => {
    
  };

  useEffect(() => {
    
    fetchData(); // for initial load only
  }, []);

  // useEffect(() => {
    
  //   //fetchData();
  // }, [filter, options]);

  return (
    <div>
      <div>
        <button onClick={handleRefresh}>Refresh</button>
        <button onClick={handleSort}>Sort</button>
        <button onClick={handleFilter}>Filter</button>
{/*         <input type="text" 
          // onClick={handleFilterInputChange}
          value={
            JSON.stringify(
            {score:{$gte:0.5}}
            )} 
          ref={filterInputRef} size={60}/> */}

      <textarea
      rows={7}
      columns={60}
      name="parametersJSON" 
      // ref = {paramsEl}
      ref={filterInputRef}

      style={{ 
        width: "100%"
      }}
    >
      {
            JSON.stringify(
            {score:{$gte:0.5}}
            )} 
  </textarea>
        
        
      </div>
      <div>
        <h2>Advanced Menu</h2>
        {/* Add advanced functionalities here */}
      </div>
      <ElraiList elrais={items} />
    </div>
  );
}


function polconQuoteOf(u) {
  try {
    const rs = JSON.parse(u.response)
    if (rs.polcon && rs.polcon.explication && rs.polcon.explication.quote) {
      return rs.polcon.explication.quote
    } else {
      return 'no quote'
    }
  } catch (error) {
    return error.message
  } 
}

function dateStringOf(u) {
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateStr = new Date(u.dt).toLocaleDateString(undefined, dateOptions);
  return dateStr
}

function scoregOf(u) {
  return u.score
}

const ElraiList = ({elrais}) => {
    // const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
    // const scrollContainerRef = useRef(null);

    // const [selectedIndex, setSelectedIndex] = useState(-1)
    // const hasBeenScrolled = useRef(false);
    // const selectRef = useRef(null)


    useEffect(() => { // this effect is for scrolling
        console.log('ASDA')
      }, [elrais]);




    return (  
        <div id="elraisDiv" className="elraisScroll" 
          // onScroll={checkScroll}
          // onResize={checkScroll}
          style={{ flex: 1, overflowY: "auto" }}
          //ref={scrollContainerRef}
        >
                    
        {/* ..... */}
        
        {
    
          
          <ul style={{ overflowY: 'auto', listStyleType: 'none', paddingLeft: 0 }}>
              {elrais.map((u, index) => {
                //const { icon, bgColor , iconName} = getIconAndColor(u.auth);
                
        
                  return (
                  <li key={index} style={{margin:"4px"}}
                    //style={{ backgroundColor: bgColor, border: "1px solid lightgray", margin:"3px" }}
                    //className={clsNames} 
                    //onClick={(e) => handleItemClick(e, index, u)}
                    //onContextMenuXXX={handleItemContextClick}
            
                    /* ref={liRef} */
                  >
                    
                    
                    {/* <span className="material-icons" style={{ width:'', marginRight: '8px' }}>{iconName}</span> */}
                        
                        
                    <span>{dateStringOf(u)}</span> 
                    <span>--score {scoregOf(u)}</span>
                    <br></br>
                    <a href={u.link}>{u.title}</a> 
                    <br/> <span style={{backgroundColor:"yellow"}}>{polconQuoteOf(u)}</span>
      
    
                    {/* {item.auth} :::   {item.text} */}
    
                  </li>
                  )
              })}
    
        </ul>
    
      }
    </div>)



}

async function Get_Elrais({filter, options}) {
  //console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
  // try {

  // all fields for now .. 
    const postData = {filter: filter, fields: {}, options: options, sort: {score:-1}}

//Atlas atlas-mii1uc-shard-0 [primary] test> db.electrais.find({score:{$gte:0.7}},{title:1,score:1,link:1}).sort({score:-1}).count()
    const outServerUrl = 'http://localhost:5000'
    const response = await axios.post(outServerUrl + '/outServerArt/Electrai_LookupMany', postData);
    // - axios.get might trow an error from the back end by the caller should handle it

    
 
    
    return response.data // i.e. returms {error:{}. data:P{}}

};

