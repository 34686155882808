import { useState, useRef, useEffect} from 'react';

import 'bootstrap/dist/css/bootstrap.css';
import { Dropdown, Button, Modal } from 'react-bootstrap';
import { BsChevronDown } from 'react-icons/bs';

export function ConversationDropdownButton({ onNewConversation, onMarkAsRead, onDelete }) {
  const [showMenu, setShowMenu] = useState(false);

  function handleToggleMenu() {
    setShowMenu(!showMenu);
  }

  function handleSelect(eventKey) {
    setShowMenu(false);
    switch (eventKey) {
      case 'new-conversation':
        onNewConversation();
        break;
      case 'mark-as-read':
        onMarkAsRead();
        break;
      case 'delete':
        onDelete();
        break;
      default:
        break;
    }
  }

  return (
    <Dropdown show={showMenu} onSelect={handleSelect}>
      <Dropdown.Toggle as={Button} variant="primary" id="conversation-dropdown-button" onClick={handleToggleMenu}>
        Misc. Functions {/* <BsChevronDown /> */}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item eventKey="new-conversation">New Conversation</Dropdown.Item>
        <Dropdown.Item eventKey="mark-as-read">Mark as Read</Dropdown.Item>
        <Dropdown.Item eventKey="delete">Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}



// import React from 'react';
// import 'bootstrap/dist/css/bootstrap.css';
// import Dropdown from 'react-bootstrap/Dropdown';
  
// export  function ConversationDropdownButton() {
//   return (
//     <div /* style={{ display: 'block', 
//                   width: 700, 
//                   padding: 30 }} */>

//       <Dropdown>
//         <Dropdown.Toggle variant="success">
//           Open Menu
//         </Dropdown.Toggle>
//         <Dropdown.Menu>
//           <Dropdown.Item href="#">
//             Home Page
//           </Dropdown.Item>
//           <Dropdown.Item href="#">
//             Settings
//           </Dropdown.Item>
//           <Dropdown.Item href="#">
//             Logout
//           </Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     </div>
//   );
// }

export function Modal123( {show=false, onClose, bodyComponent }) {
    return (
        <Modal show={show} onHide={onClose} backdrop={false} >
        <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!
            {bodyComponent}

        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>
            Close
            </Button>
        </Modal.Footer>
        </Modal>
    )
}

export function SendFeedbackDialog({ isOpen, onClose }) {
    const dialog = useRef(null);
  
    useEffect(() => {
      if (isOpen) {
        dialog.current.showModal();
      } else {
        dialog.current.close();
      }
    }, [isOpen]);
  
    return (
      <dialog ref={dialog} onClose={onClose}>
        <h1>Dialog Title</h1>
        <p>Dialog content...</p>
        <button onClick={onClose}>Close</button>
      </dialog>
    );
}


export function  BlobDisplay(json, windowRef = null) {

  const blob = new Blob([JSON.stringify(json, null, 2)], {type : 'application/json'});
  const url = URL.createObjectURL(blob);

  // If a window is already open, close it
  if (windowRef == null) {
    window.open(url)
  } else {
    if (windowRef.current) windowRef.current.close();
    // Open a new window and store a reference to it
    windowRef.current = window.open(url);

    // if (!newWindow) {
    //   console.error("Window failed to open. Check if it's being blocked by a popup blocker.");
    // }

// doing it thru form ::

/*** 
// Create a form element
const form = document.createElement('form');
form.method = 'POST';
form.action = 'http://my.site.com';

// Create a textarea element to hold the JSON data
const textarea = document.createElement('textarea');
textarea.name = 'jsonData';
textarea.value = jsonData;

// Append the textarea to the form
form.appendChild(textarea);

// Submit the form in a new window/tab
const newWindow = window.open('', '_blank');
if (newWindow) {
  newWindow.document.body.appendChild(form);
  form.submit();
} else {
  console.error('Unable to open new window/tab');
}
***/



  }
    
  return null;
  // return (
  //   <button onClick={sendData}>
  //     Send Data
  //   </button>
  // );
}

export function SoundBeep ({type='triangle', secs=0.1}) {
  //  'sine', 'square', 'sawtooth', 'triangle'
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillator.connect(gainNode);
    gainNode.connect(audioContext.destination);

    oscillator.type = type; // You can change the type to 'sine', 'square', 'sawtooth', 'triangle'
    oscillator.frequency.setValueAtTime(440, audioContext.currentTime); // Set frequency to 440Hz (A4)
    gainNode.gain.setValueAtTime(0.1, audioContext.currentTime); // Adjust volume

    oscillator.start();
    oscillator.stop(audioContext.currentTime + secs); // Beep for 0.1 seconds
  };



export class Utils_Front { // compare with Utils_Back on the client - should be the same?

  static ErrorCode = { // K E E P   I N   S Y N C Between Front Client and the Back Server !!
    // everything that is >= 0 is just an info or a warning
    // CONVENTION: with all not-negative error code you can proceed

    Network_Error: -3,
    Exception_On_Db: -2,
    Cache_Is_Locked : -1, // meaning come later
  
    //----------------- proceed codes : -----------

    Just_An_Info_Message : 0, // a generic info for example: .. a copout for unknown yet mess
    Cache_Was_Reloaded: 1, // kinda a warning message - client should inform user that data has been updated and proceed??
    OpenAi_Error: 2,

  }



  // result.error.code == Utils_Front.ErrorCode.Just_An_Info_Message

    
  static fixedDateEncodingIf2(dt, returnNullIfUnchanged = false) { // coming from server
    if (typeof dt === 'string') 
      return new Date(dt)
    return (returnNullIfUnchanged ? null : dt)
  }

  static isMicrosoftDateFormat(str) { //chatGpt 3.5
    const regex = /^\/Date\(\d+\)\/$/;
    return regex.test(str);
  }

  static FixIfDateString(dateString) {
    if (! dateString) return dateString
    if (! typeof variable === 'string') return dateString

    if (this.isMicrosoftDateFormat(dateString))
        return new Date(parseInt(dateString.slice(6, -2)))
    else
        return dateString

        //const dateString = '/Date(1689830229000)/';
}


static  roughValueOf(val, delta) { // chatgpt advice

    // const factor = 1/ 20 
    // const delta = 0.05
    // const val = 0.624999999999
    const factor = Math.round(1/delta)
    //const valR = Math.round(val/delta) * delta
    const valR_2 = Math.round(val * factor) / factor
    //console.log(valR + '\n' + valR_2); 
    return valR_2   

}

static roundDateOf(dt) {
  return (new Date(dt)).setHours(0, 0, 0, 0);
}
  static DateFloorOf(dt) {
    // dt.setHours(0);
    // dt.setMinutes(0);
    // dt.setSeconds(0);
    // dt.setMilliseconds(0);
    // return dt
    //let d = new Date().setHours(0, 0, 0, 0);
    return new Date((new Date(dt)).setHours(0, 0, 0, 0)) //new Date(dt.setHours(0, 0, 0, 0))
  }

  static DateScoreSort(arr) {

    arr.sort(function(e1, e2) {
      const dt1 = this.DateFloorOf(e1.dt)
      const dt2 = this.DateFloorOf(e2.dt)
      if (dt1 < dt2) return 1 //
      if (dt1 > dt2) return -1 // 
      return - (e1.score - e2.score)
      })
  }

  // static DateDifference_Days(dtFrom, dtTo) {
  //   let diffInMills = dtTo - dtFrom;
  //   return  Math.ceil(diffInMills / (1000 * 60 * 60 * 24));
  // }
  static DateDifference_Days(dtFrom, dtTo) { //gpt :()
    // Strip the time part from both dates
    let dateFrom = new Date(dtFrom.getFullYear(), dtFrom.getMonth(), dtFrom.getDate());
    let dateTo = new Date(dtTo.getFullYear(), dtTo.getMonth(), dtTo.getDate());
  
    // Calculate the difference in milliseconds
    let diffInMills = dateTo - dateFrom;
  
    // Convert the difference to days
    return Math.ceil(diffInMills / (1000 * 60 * 60 * 24));
  }

  static DateBackOff(days) {
    const res = new Date(); // Get the current date and time
    res.setHours(0, 0, 0, 0); // Set the time to midnight
    res.setDate(res.getDate() - days); // Subtract n days
    return res;
  }

////////////////////////////////////

  static Fading(x, dropAt, dropToVal ,  minVal,  maxVal = 1.0) {

    if (x == null) { // test
      console.log('myBase:')
      for (let pNumber = 0; pNumber < 20; pNumber++) {
        if (minVal == undefined) minVal = 0.2
        if (maxVal == undefined) maxVal = 1
        if (dropAt == undefined) dropAt = 4
        if (dropToVal == undefined) dropToVal = (minVal + maxVal) * 0.5
        console.log(pNumber + ' : ' + this.Fading(pNumber, dropAt, dropToVal, minVal, maxVal ))
      }
      return
    }

    if (x <= 0) return maxVal // i.e. left of zero will be flat maxVal

    const h = dropAt
    const hVal = dropToVal
    const slope = (-1/h**2) * Math.log((hVal - minVal) / (maxVal - minVal))

    const res = minVal +  (maxVal - minVal) * Math.exp(-slope * x ** 2)
    return res 
  }



  static Setwork(indexedSet, index, ops) {
    let cpy=new Set(indexedSet)
  
    if (!Array.isArray(ops)) ops = [ops]
    if (!Array.isArray(index)) index = [index]
  
    let isExclusive = false
    for (const op of ops) {
      if (op == 'clear') { cpy = new Set(); continue }
      for (const idx of index) {
        if (op == 'exclusive') {
          // erase all that are not index
          const restoreIndex = cpy.has(idx)
          cpy = new Set()
          if (restoreIndex) cpy.add(idx)
  
        
  
        } else if (op == 'flip') {
          if (cpy.has(idx)) cpy.delete(idx)
          else cpy.add(idx)
  
        } else if (op == 'on') {
          cpy.add(idx)
  
        } else if (op == 'off') {
          cpy.delete(idx)
  
        } else {
          // ignore for now
          return indexedSet
        }
      }
    }
  
    return cpy
  }

}