//import React from "react"
import { useState, useRef, useEffect, useLayoutEffect, createContext } from 'react';
import axios from "axios"
import {Modal123, BlobDisplay, Utils_Front, SoundBeep} from "../Utils/Misc2.js"
import {FeedbackDialog} from "../Utils/Feedback.js"
import {DbgDialog, DbgMore} from "../Utils/DbgDialog.js"
import { DotDotDot, UseEventListener} from "../Utils/MiscForApp.js"
import {NormArr} from "../Utils/Misc.js"

import {ScratchBackUrl} from '../Utils/gptCall.js'

import {Search_ToArray, HiText, HighlightText, HiTextComp, HiGetParagraphs, HtmlToSpan
  , QMatching, QMatchingWeed, QMatching1} from '../Utils/HiText.js'

import { MenuUniversal, MenuUniSt2, St2s_by_Fullname, Fullname_By_St2s
    , SortMenu} from "../Utils/Menus.js"


import { ThemeProvider, createTheme } from '@mui/material/styles'; // for helpMode ..
// not supprted anymore import { makeStyles } from '@mui/styles'


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, Input, InputAdornment, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search'; // for text search

// not foundd ?import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'; // for sem search ?

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// for Sort
import ScheduleIcon from '@mui/icons-material/Schedule';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SortIcon from '@mui/icons-material/Sort'; // for opposite relevance/date
import FilterListIcon from '@mui/icons-material/FilterList'; // for relevance/date

import BrainIcon from '@mui/icons-material/Psychology'; // This is an example, MUI might not have a specific "semantic search" icon.

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

//import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ClearIcon from '@mui/icons-material/Clear';


import './ElraiProto.css';


const HelpModeContext = createContext(false);

export default function ElraiProto() {
  const [helpModeOuter, setHelpModeOuter] = useState(false);

  const theme = createTheme({
    components: {
      MuiTooltip: {
        defaultProps: {
          arrow: true, // Ensure arrow is displayed
        },
        styleOverrides: {
          tooltip: {
            //mode:'light'
             backgroundColor: 'white' //'#333333', // Remove !important for better specificity
            , color: 'black' //'#ffffff', // Remove !important for better specificity
            , border: '1 px black'
            , borderRadius: '4px' // Add border radius for rounded corners
            , border: '1px solid black' // Add black border
            , arrow:true
          },
        },
      },
    },
  });

 

  //const classes = useStyles();
  // const toggleHelpMode = () => {
  //   setHelpMode(!helpMode);
  // };
  //const [helpMode, setHelpMode] = useState(false);

  // return ( <>

  //     <Button onClick={toggleHelpMode} variant="contained">
  //         {/* variant="contained" disableElevation> */}
  //       {helpMode ? 'In Help Mode' : 'Help'}
  //     </Button>
  //     </>
  //   // <Button
  //   //   sx={{
  //   //     backgroundColor: helpMode ? 'secondary.main' : 'primary.main',
  //   //     color: 'white',
  //   //     transform: helpMode ? 'scale(0.98)' : 'none', // Slightly scale down when pressed
  //   //     boxShadow: helpMode ? 'inset 0 3px 5px rgba(0,0,0,0.2)' : 3, // Use inset shadow for pressed effect
  //   //     ':hover': {
  //   //       backgroundColor: helpMode ? 'secondary.dark' : 'primary.dark',
  //   //       boxShadow: helpMode ? 'inset 0 3px 5px rgba(0,0,0,0.3)' : 4, // Darker inset shadow on hover when pressed
  //   //     }
  //   //   }}
  //   //   onClick={toggleHelpMode}
  //   // >
  //   //   {helpMode ? "Exit Help Mode" : "Enter Help Mode"}
  //   // </Button>


  // )

  return (
    <ThemeProvider theme={theme}>
       <HelpModeContext.Provider value={{ helpModeOuter, setHelpModeOuter }}>

        <CmbCombined /> 
        {/* helpMode={helpMode} setHelpMode={setHelpMode}/>  */}

       </HelpModeContext.Provider>
    </ThemeProvider>
      
  )
}

/* const AnItem_Border = "1px grey solid"
const Left_Pane_Border = 'none' //'3px blue solid'
const Right_Pane_Border = 'none' //'3px blue solid'
const Actor_List_Border = AnItem_Border //'none' //'3px blue solid '
const Item_List_Border =  'none' //'3px blue solid ' */

const OldWayHighlighting = false

const PainHeaders_Height = "30px"
const PainHeaders_VertDelta = "0px"
const PainHeaders_Border = 'none'
const SearchStrip_LefMargin = '8px'

const AnItem_Border = "1px grey solid"
const Left_Pane_Width = "240px"
const Left_Pane_Border = 'none' //'3px blue solid'
const Right_Pane_Border = 'none' //'3px blue solid'
const Actor_List_Border = AnItem_Border //'3px green solid '
const Item_List_Border =  'none' //'3px green solid '

///////////////////// better than in css ? ///////////////////////////
const Angry_Color = "#5F1202"  //"#5F1202"
const style_angry_title = {
  fontWeight: 650 //"bolder",
  ,fontSize: "16px"
}
const style_angry_quote = {
  //backgroundColor:"rgb(204 102 0 / 40%)"
  color: Angry_Color, //rgb(204, 102, 0)" //"#CC6600", 
  fontWeight:"bolder"
}

const HelpMode_Color = '#F5938F' //'lightred'

const style_expo_header =  {   
  margin: "10px 0px 10px 10px",
  marginLeft: SearchStrip_LefMargin,
  fontStyle:"italic",
  fontWeight:"500",
  
  // display: "flex",
  // justifyContent: "center",
  // padding: "0 20px",
  // textAlign: "center",
  // width: "100%" // or maxWidth: "some-value"
  }

const SearchStripIcon_Size = '18px'

const style_header_link ={ 
  fontSizeZZZ:"small",
  textDecoration: "underline",
  marginLeft: "10px"
}

const style_angry_control = { color: 'black', borderRadius: 0,
    
  //fontSize:"small",
  //widthZZZ: '30px',   // Smaller width
  //aspectRatio: '1 / 1', // i.e. square icon


  padding: '5px',  // Adjust padding as needed
  
  opacity: 0.9, 
  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',

  //boxSizing: 'border-box', // Include padding and border in the width and height
}

const style_angry_control_ActiveStyle = {
  fontSize: 'inherit', 
  opacity: 1,
  boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.8)'
}

const style_angry_control_InactiveStyle = {
  fontSize: 'inherit',
  opacity: 0.6,
  boxShadow: 'none'
}


const Default_Sort_String = 'Relevance Sort'
const Date_Sort_String = 'Date Sort'

//chat advice
// not supported anymore
// const useStyles = makeStyles((theme) => ({
//   pressedButton: {
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.secondary.contrastText,
//     boxShadow: 'none', // Remove shadow to make it look flat and pressed
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.dark,
//     }
//   },
//   normalButton: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.primary.contrastText,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     }
//   }
// }));
///////////////////////////
/* 
function expansionCheck(ud, udCallBack, index) {
  if ( ud.expandedIndexes == undefined || ud.expandedIndexes == null) return false
  return ud.expandedIndexes.has(index)
}
function expansionFlip(ud, udCallBack, index) {
  if (! ud.expandedIndexes) ud.expandedIndexes = new Set() 
  const idxes = new Set([...ud.expandedIndexes])
  if (idxes.has(index)) idxes.delete(index); else idxes.add(index)

  udCallBack({expandedIndexes: idxes})
}

function expansionFlipExclusive(ud, udCallBack, index) {
  if (! ud.expandedIndexes) ud.expandedIndexes = new Set() 
  let idxes = new Set([...ud.expandedIndexes])
  if (idxes.has(index)) idxes = new Set(); else idxes = new Set([index])

  udCallBack({expandedIndexes: idxes})

}

function expansionErase(ud, udCallBack, index = -1) {
  if (! ud.expandedIndexes) ud.expandedIndexes = new Set() 
  let idxes = new Set()
  udCallBack({expandedIndexes: idxes})
}

function expansionOp(op, ud, udCallBack, [indexes]) {
  if (! ud.expandedIndexes) ud.expandedIndexes = new Set() 
  const idxes = new Set([...ud.expandedIndexes])

  switch(op) {
    case 'flip':
      break;
    case '':
        break;
  }
  //if (idxes.has(index)) idxes.delete(index); else idxes.add(index)

  udCallBack({expandedIndexes: idxes})
}
 */
////////////////////////
function actorsToDict(cbList) {
  const dict={}
  for (let i in cbList) {
    const cb = cbList[i]
    for (let nm of cb.allNamesOf()) dict[nm] = cb
      //dict[cb.nm] = cb
  }
  return dict
}

function actorsImpsToList(actorsImpDict) {
  return impDictToList(actorsImpDict)
}

function ustateDictToList(ustateDict) {
  const xx = impDictToList(ustateDict)
  
  const yy = xx.map((entry)=> {
    if (!entry.nm.startsWith("us_")) return null
    entry.nm = entry.nm.substring(3)
    entry.nm = Fullname_By_St2s(entry.nm)
    return entry
  })

  const zz = yy.filter((entry)=> entry!= null)

  return zz
}

function impDictToList(impDict) {
  // compare (and werge with) dumpActors() in the -scrathback-
  let kvps = []
    if (impDict) {
      
      for (var nm in impDict) {
        const imp = impDict[nm]
        kvps.push({nm:nm, imp:imp})
      }
      kvps.sort(function(kvA, kvB) {
        return - (kvA.imp - kvB.imp)})
      //res = kvps.map((kv)=>{ return kv.nm})
    }
  
  return kvps
}


///////////////////////////////

function ppp(par='froggg') {
  return <div>{par}<b>frog</b>{'sdsad'}</div>
}

function jsxOf(htmlishText) {
  return <span dangerouslySetInnerHTML={{__html: htmlishText}} ></span>
}




// function getHiItems(items, actors, hiTextArr, bestParagraphOnly = true) {
//   // -- obsolete because of OldWayOfHighlighting

//   if (!hiTextArr || hiTextArr.length == 0 ) {
//     return items
//   }

//   const actorsDict = actorsToDict(actors) 

//   const resItems= items.map((eim, index) => {
    
//     let hiArr = hiTextArr

//     const resItem = {...eim}

//     let foundInText_Number = 0
//     let struct = null

//     struct = HiText(resItem.title, hiArr)
//     foundInText_Number += struct.nReplacements
//     if (struct.nReplacements) resItem.title = struct.replacedText
    
//     struct = HiText(resItem.quote, hiArr)
//     foundInText_Number += struct.nReplacements
//     if (struct.nReplacements) resItem.quote = struct.replacedText


//     if (hiArr && foundInText_Number == 0) { 

//       struct = HiText(resItem.txtIn, hiArr, bestParagraphOnly) // only best paragrpah
//       foundInText_Number += struct.nReplacements

//       if (struct.nReplacements) {
//         resItem.txtIn = struct.replacedText
//         resItem.needsExpansion = true
//       } else {
//         resItem.needsExpansion = false
//       }
      
//     }
//     return resItem
//   });

//   return resItems;
// }





function ItemListFull( {items, actors, keyCb, ud, udCallBack, siteMode
  // , allCompData, setAllCompData
  , selectItemIndex, handleSelectItem
  , expandItemIndex, handleExpandItem
  , handleSearch
}
) {
  

  const actorsDict = actorsToDict(actors) 
  return (  
    <div id="itemsDiv" className={'fullSiteMode'}
      style={{ border: Item_List_Border,
        overflowY: 'auto', listStyleType: 'none', paddingLeft: 0 }} >
      {/* { <ul style={{ overflowY: 'auto', listStyleType: 'none', paddingLeft: 0 }}> */}
        <ExpoHeader siteMode={siteMode} keyCb={keyCb}  ud={ud} udCallBack = {udCallBack}/>
        { 
          items.map((eim, index) => {
            //let  keyCb_Nm = (keyCb?keyCb.nm : null)

            let tempTitle = null
            let tempQuote = null
            let tempTxtIn = null
            if (OldWayHighlighting) {
             tempTitle = eim.title
             tempQuote = eim.quote
             tempTxtIn = null
            }


            return <ItemComp eim = {eim}
              index = {index} 
              normalBorder = {AnItem_Border}
              tempTitle = {tempTitle} tempQuote = {tempQuote} tempTxtIn = {tempTxtIn}
              selectItemIndex={selectItemIndex} handleSelectItem={handleSelectItem} 
              expandItemIndex={expandItemIndex} handleExpandItem={handleExpandItem}
              handleSearch={handleSearch}
              siteMode={siteMode}
              udCallBack = {udCallBack}
              keyCb = {keyCb} actors = {actors} actorsDict = {actorsDict}
            />


        })}
       
      {/* </ul>} */}
    </div>)
}

function GenActorLink(nm, actors, keyCb, udCallBack
    , cb = null, actorsDict = null) {
  // todo - a shame to do it on each link gen link
  // - so - look at the collers



  if (cb == null) {
    //if (actorsDict == null) actorsDict = actorsToDict(actors) // assertion ??
    cb = actorsDict[nm] // cb.nm == nm !?
  }

  if (cb == undefined || cb == null) {
    return (
      // <a href="">{
      //               'no actor in actorsDict?'
      //             }</a> 
        <span>{nm}</span> /* - not a link */
      )
  }

  const nameAppearance = keyCb && keyCb.nm === cb.nm 
    ? <b>{cb.nm}</b>
    : cb.nm;

  return (
  <a onClick={(e)=>{
                udCallBack({action:"actor", param:cb})
                e.preventDefault() // to avoidhref=""
              }} 
              href="">{
                nameAppearance
              }</a> 

  )
}

function GenFeatureLink(nm, features, keyFb, udCallBack
  , fb = null, featuresDict = null) {

  if (fb == null) {
    //if (actorsDict == null) actorsDict = actorsToDict(actors) // assertion ??
    fb = featuresDict[nm] 
  }

  if (fb == undefined || fb == null) {
    return (
      // <a href="">{
      //               'no actor in actorsDict?'
      //             }</a> 
        <span>{nm}</span> /* - not a link */
      )
  }

  const nameAppearance = keyFb && keyFb.nm === fb.nm 
    ? <b>{fb.nm}</b>
    : fb.nm;

  return (
  <a onClick={(e)=>{
                udCallBack({action:"feature", param:fb})
                e.preventDefault() // to avoidhref=""
              }} 
              href="">{
                nameAppearance
              }</a> 

  )
}


function FeatureList({ features, keyFb, udCallBack, siteMode} ) {

  return (
    <div id="featuresDiv" className={'fullSiteMode'}
      style ={{ border: Actor_List_Border,   overflow: 'auto' , listStyleType: 'none', paddingLeft: 0 }} 
    >

      { // displayed list of actors
        features == undefined ? <div> no features? </div> :
        features.map((fb, index) => {
        //const { icon, bgColor , iconName} = getIconAndColor(u.auth);
          return (
          <div key={index} style={{margin:"4px"}}>
            <span>{
              (siteMode.has('dbgMode') ? 
              Number2String(fb.aggImp) //((fb.aggImp == null) ? '???' : fb.aggImp.toFixed(2).toString())
                + ' ' : '') 
                + fb.eimIdxes.length.toString()
            } </span>
            {GenFeatureLink(null, features, keyFb, udCallBack, fb, null)}

          </div>
          )
      })}

    </div>
  );
}


function Number2String(n, nPositions = 2) {
  if (n == null ) // works with undefined as well
    return '???'
  else
    return n.toFixed(nPositions).toString() 
}
function ActorList({ actors, keyCb, udCallBack, siteMode} ) {
    return (
      <div id="actorsDiv" className={'fullSiteMode'}
        style ={{ border: Actor_List_Border,   overflow: 'auto' , listStyleType: 'none', paddingLeft: 0 }} 
      >

        { // displayed list of actors
          actors.map((cb, index) => {
          //const { icon, bgColor , iconName} = getIconAndColor(u.auth);
            return (
            <div key={index} style={{margin:"4px"}}>
              <span>
                
                {/* {
                (siteMode.has('dbgMode') ? Number2String(cb.aggImp)
                  + (cb.nScore? 
                     ' ' + Number2String(cb.nScore) + ' '
                     : ''
                    )
                  : '') + Number2String(cb.aggImp) //+ cb.eimIdxes.length.toString()
                }  */}
              

              <small> <>{Number2String(cb.aggImp)}</> </small>
              { siteMode.has('dbgMode')
                && 
                (<> <small>{Number2String(cb.nScore) + ' ' + cb.eimIdxes.length.toString()}</small> </>)
              }
   
              
              
              </span>
              {GenActorLink(null, actors, keyCb, udCallBack, cb, null)}

            </div>
            )
        })}

      </div>
    );
}

// pushe it outside of components ??!!
function isInSemanticMode(siteMode) {
  return !siteMode.has('searchTextualImplied')
}

// function TtipHelper() {
//   return <DbgMore isLong={$$.helpMode} htmlKey='logo' udCallBack={udCallBack}/>

// }
function CmbCombined() { //{helpMode, setHelpMode}) { 
  // const [ttipExp, setTtipExp] = useState('blah blah')
  // const 

  //how to deal with serializable sets
  //https://farzadyz.com/blog/simple-serializable-maps-and-sets-in-javascript

  const [stateHistory, setStateHistory] = useState([])
  const [$$,set$$] = useState({
    //xxx : new CbItem(),
    historyIndex: 0
    //,historyIndexMin: 0 // i.e. blow that there is nothing stored
     
    ,queryText: ''
    ,geoName: null

    //,actors: []

    ,dtCache: null

    ,isWorking: false
    ,items: []
    ,actors: []


    ,features: []
    ,siteMode: new Set(['searchTextualImplied'])
    ,keyCb: null
    ,queryGrayed: false
    ,advisoryMessage: null
    
    ,filter: {
              score:{$gte:0.0} // design note [front filter score shoul be low]
                  // the same result as with {$gte:0.5} 
                  // - because it sorts by date and score and liit i high!!

            , dt: null // see design note [null is default date interval in the back]
              // !! {$gt:Utils_Front.DateBackOff(DaysBack)} //CONSTPARAM ..new Date("2023-10-01")
            , fParams:{forceExtract:false
            , features:null // if it is true then cblist returned for very query text or vector
            , fade:{dropAt:2, dropAtVal:0.8, minVal:0.5}
            }
          }
    ,options: {}
    ,showSortOptions: false
    ,activeSort: 'default'
    ,universalDict: {}
    ,toolTipSortEnabled: true
    ,sendFeedbackDialogOpen: false
    ,dbgDialogOpen: false
    ,selectItemIndex: new Set([])
    ,expandItemIndex: new Set([])

    , helpMode: false
    , userLabel: null // to identify who i sent it to .. and track .. no use .. haha
    //,pOnlyIndex: new Set([])
  } )
  
  const $$change = (variableName, newValue) => {
    // e.g. setStates('filter', newFilter)

    // not waiting for react !!!!!!!!!!!!!!!!!!!!!!!!!
    $$[variableName] = newValue

    // and now let react do it ..
    set$$(prevState => ({
      ...prevState,
      [variableName]: newValue
    }));
  }; 

/* 

function PreCloneObject(obj) {
    if (obj === null || typeof obj !== 'object' || obj instanceof Date || obj instanceof Set) {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(item => PreCloneObject(item));
    }
    const cloned = {};
    for (const key in obj) {
        cloned[key] = PreCloneObject(obj[key]);
    }
    return cloned;
}

function PreProcessState(obj) {
  for (const key in obj) {
    if (obj[key] instanceof CbItem) { 
            //(obj[key] && obj[key].$$jsonFrom && typeof obj[key].$$jsonFrom === 'function') {
      let className = null
      if (obj[key] instanceof CbItem) className = 'CbItem'
      //if (typeof obj === 'CbiItem') className = 'CbItem'
      obj[key] = { _type: className, _value: PreProcessState(obj[key]) };

    } else if (obj[key] instanceof Date) {
      // Convert Date to your desired format
      obj[key] = { _type: 'Date', _value: obj[key].toISOString() };

    } else if (obj[key] instanceof Set) {
      // Handle Set similarly
      obj[key] = { _type: 'Set', _value: Array.from(obj[key]) };

    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      // Recursively process for nested objects
      PreProcessState(obj[key]);
    }
  }
  return obj
}

function CpyState(obj) {

  if (obj === null) return null;

  if (obj instanceof Date) {
    // Convert Date to your desired format
    return { _type: 'Date', _value: obj.toISOString() };
  }

  if (obj instanceof Set) {
    // Handle Set similarly
    const arrayClone = [] 
    for (const a of obj) {
      arrayClone.push(CpyState(a))
    }
    return { _type: 'Set', _value: arrayClone }; // 
  }

  if (obj instanceof Array) {
    // Handle Set similarly
    const arrayClone = [] 
    for (const a of obj) {
      arrayClone.push(CpyState(a))
    }
    return arrayClone; // 
  }

  // laternative and more elegant for arrys :
  if (Array.isArray(obj)) {
    return obj.map(item => CpyState(item));
  }   

  

  // if (obj instanceof QMatchingWeed) {
  //   const x = 1
  // }
  

  // if (obj instanceof CbItem) { //(obj[key] && obj[key].$$jsonFrom && typeof obj[key].$$jsonFrom === 'function') {
  //   let className = null
  //   if (obj instanceof CbItem) className = 'CbItem'
  //   //if (typeof obj === 'CbiItem') className = 'CbItem'
  //   return { _type: className, _value: PreProcessState(obj[key]) };


  if (typeof obj == 'object') {
    const objClone = {};
    for (const key in obj) {
      if (key == 'items') {
        const x = 1
      }

      objClone[key] = CpyState(obj[key]);
    }

    if (obj instanceof CbItem) {
      return { _type: 'CbItem', _value: objClone }; // 

    } else if (obj instanceof QMatchingWeed) {
      return { _type: 'QMatchingWeed', _value: objClone }; // 

    } else if (obj instanceof QMatching1) {
      return { _type: 'QMatching1', _value: objClone }; // 

    } else {
      return objClone;
    }

    
  }

  // what's left - 
  return obj

  
}


function PostProcessState(obj) {
    for (const key in obj) {
        const value = obj[key];
        if (value && typeof value === 'object') {
            // Check for custom serialized types and reconstruct them
            if (value._type === 'CbItem') {
                obj[key] = new CbItem()
                obj[key].$$jsonFrom(value._value);

            } else if (value._type === 'QMatchingWeed') {

              const xxx = PostProcessState(value._value);

              obj[key] = new QMatchingWeed()
              obj[key].$$jsonFrom(xxx);

              obj[key] = null // avoiding problems for now - hell with the highlightng!!!

            } else if (value._type === 'QMatching1') {
              obj[key] = QMatching1.$$Create(value._value)
              
              obj[key] = null // avoiding problems for now - hell with the highlightng!!!


            } else if (value._type === 'Set') {
                obj[key] = new Set(value._value);
            } else if (value._type === 'Date') {
                obj[key] = new Date(value._value);
            } else {
                // If it's not a custom serialized type, recursively process the object
                PostProcessState(value);
            }
        }
    }
    return obj;
}
 */

  const $$clear = () => {
    setStateHistory([])
  } 

  const $$save = () => {
   
    const newStateHistory = [...stateHistory]
    if (stateHistory.length >= 3) newStateHistory.shift() // removing first element
    newStateHistory.push({...$$}) // - at least a SHALLOW copy!
    setStateHistory(newStateHistory);
    return;
/* 

    // const deepCopy = PreCloneObject($$)
    // PreProcessState(deepCopy)

    const deepCopy = CpyState($$)



    const str = JSON.stringify(deepCopy)
    // const str = JSON.stringify($$, (key, value) => {

    //   if (value instanceof Date) {
    //     return { _type: 'Date', _value: value.toISOString() };
    //   }

    //   if (value instanceof Set) {
    //     return { _type: 'Set', _value: Array.from(value) }; // Marking and converting Set to an array
    //   }
    //   return value;
    // });


    const key = '$$_' + ($$.historyIndex)
    try { window.localStorage.setItem(key,str)
      
      // advance to a new hist postition
      $$change('historyIndex', $$.historyIndex + 1)

      // should i really remove the history item? Maybe I can move forward to it .. later
      // window.localStorage.removeItem(key,str)

      return true
    } catch(e) {
      if (e.name === 'QUOTA_EXCEEDED_ERR') 
        alert ('Not enough memory to save new state')
        
      return false
      
    } */
  };
  
  // const $$keepLimit = () => {
  // }

  const $$length = () => {
    return stateHistory.length 
  }

  const $$restore = () => {
    if (stateHistory.length == 0) { //($$length() == 0) {
      // refresh !!
      handleRefresh()
    } else {
      const newStateHistory = [...stateHistory]
      const popedState = newStateHistory.pop()

  
      const oldState = popedState //newStateHistory[newStateHistory.length-1];
      setStateHistory(newStateHistory);

      //design note [ui changes have to be  passed to queries - but how to restore their previous values?]
      let prev_queryText = stateHistory.length == 1 ? '' : newStateHistory[newStateHistory.length - 1].queryText
      let prev_geoName = stateHistory.length == 1 ? '' : newStateHistory[newStateHistory.length - 1].geoName
        if (prev_geoName == '') prev_geoName = null
      let prev_keyCb =  stateHistory.length == 1 ? null : newStateHistory[newStateHistory.length - 1].keyCb


      oldState.queryText = prev_queryText
      oldState.geoName = prev_geoName
      oldState.keyCb = prev_keyCb

      oldState.isWorking = false //!!! it $$saves is to true efore
      set$$(oldState)
    }
    return;

/* 
    if ($$.historyIndex === 0) return false // i.e. caller would know

    // get rid of previous storage?
    const prevKey = '$$_' + ($$.historyIndex)
    if (window.localStorage.getItem(prevKey) === null) {
      // ... ??
    } else {
      window.localStorage.removeItem(prevKey)
    }

    const key = '$$_' + ($$.historyIndex - 1)
    //const str = null
    try { const str = window.localStorage.getItem(key)
      if (str === null) return false;

      const restoredState = JSON.parse(str)
      PostProcessState(restoredState)
      // const restoredState = JSON.parse(str, (key, value) => {

      //   //if (key === 'dtCache') return new Date(value); // Convert ISO string to Date


      //   if (value && typeof value === 'object') {
      //     if (value._type === 'Set') {
      //       return new Set(value._value);
      //     }
      //     if (value._type === 'Date') {
      //       return new Date(value._value);
      //     }
      //   }

      //   return value;

      // });


      set$$(restoredState);
      return true
    } catch(e) {
      //if (e.name === 'QUOTA_EXCEEDED_ERR')
        alert ('Failed to retrieve previous state')
        // what should i do here with the lcal storage?
        return false
    } */
  };





  //////////////////////////////////////////////
  //const [isWorking, setIsWorking] = useState(false)

  //const [items, setItems] = useState([]);
  //const [actors, setActors] = useState([]); // a cbList .. i have to convert it to cbDict for lookups?
  //const [features, setFeatures] = useState([]); // a cbList .. i have to convert it to cbDict for lookups?

  //const [siteMode,setSiteMode] = useState(new Set(['searchTextualImplied']))
  // not necessary now - const [searchTypeMode, setSearchTypeMode] = useState(new Set())

  //// design note [actor/search duality] - Either "key actor" is found OR "search
  //const [keyCb, setKeyCb] = useState(null); // in the current single selection model - the selected name
  //const [hiTextArr, setHiTextArr] = useState(null) // - obsolete, remove later
  //const [queryGrayed, setQueryGrayed] = useState(false); // this is Indication of Duality see design note [actor/search duality] 

  //const [advisoryMessage, setAdvisoryMessage] = useState(null)
  //const [dtCache,setDtCache] = useState(null)

  // things for mongo
  const DaysBack = 3 // i.e. today and DaysBack back
  // const [filter, setFilter] = useState({
  //         score:{$gte:0.5}
  //       , dt: null // see design note [null is default date interval in the back]
  //         // !! {$gt:Utils_Front.DateBackOff(DaysBack)} //CONSTPARAM ..new Date("2023-10-01")
  //       , fParams:{forceExtract:false
  //       , features:null // if it is true then cblist returned for very query text or vector
  //       , fade:{dropAt:2, dropAtVal:0.8, minVal:0.5}
  //       }
  //     }); 
  //const [options, setOptions] = useState({});

  // 
  //const [showSortOptions, setShowSortOptions] = useState(false);
  //const [activeSort, setActiveSort] = useState('default'); // e.g., 'date', 'title', etc.


    // 
    //const [universalDict, setUniversalDict] = useState({}) //{expansionIndexes:new Set()})

   //
  //const [toolTipSortEnabled, setToolTipSortEnabled] = useState(true)
   
    //const queryElRef = useRef();
    const filterInputRef = useRef();
    const blobWindowRef = useRef(null); // for
   

  // compeetes with udCallBack
  // const [allCompData, setAllCompData] = useState({expandedIndex:null // 
  // })
  ///////////////////////////////////
    //const [sendFeedbackDialogOpen, setSendFeedbackDialogOpen] = useState(false);
    const handleSendFeedback = (e) => {
      e.preventDefault(); // Prevent the default anchor action.
      $$change('sendFeedbackDialogOpen',true); // Open the dialog.

    }
    //const [dbgDialogOpen, setDbgDialogOpen] = useState(false);
    const handleDbgDialogSubmit = (e) => {
      e.preventDefault(); // Prevent the default anchor action.
      $$change('dbgDialogOpen', true); // Open the dialog.

    }



  

    ////////////////////////////

    const onSubmitFeedback = async ({email, feedback}) => {
      const postData = {action:'sendEmail'
        , subject: 'AngryPoliticsDaily Feedback'
        , txt : 'Email: ' + email + '\n' + feedback}
      const result = await Get_Generic({postData}) 
      if ($$.siteMode.has('dbgMode')) BlobDisplay(result, blobWindowRef)
    }

    const onEmset = async ({nm=null, idxs=null, action='emsetDefine', op=null}) => {
      if (nm == null) {
        //nm = queryElRef.current.value.trim()
        nm = $$.queryText.trim()
      }

      let postData = null
      if (op != null) { //  package the command line

        postData = {action:action
          , nm: op + ':' + nm
        }

      } else  if (idxs == null) { // collect selected items, or all 
        const takeAll = ($$.selectItemIndex.size == 0) 

        idxs = $$.items.map((eim,index) => {
  
          if (takeAll || $$.selectItemIndex.has(index)) return eim.idx;
          else return null

        }).filter((idx)=> {return idx != null} )
        // for (const eim of items) {
        //   idxs.push(eim.idx)
        // }

        postData = {action:action
          , nm: nm
          , idxs: idxs
        }
      }


      const result = await Get_Generic({postData, fullEntryPoint : '/Varias'}) 
      if ($$.siteMode.has('dbgMode')) {
        if (result.error == null && result.data.eimList) {
          $$change('items', result.data.eimList) //  setItems(result.data.eimList)
          if (result.data.cbList) $$change('actors',CbItem.createCbList(result.data.cbList))
        } else {
          BlobDisplay(result, blobWindowRef)
        }
      }
    }
  //////////////////////////////////




  function getRoughStats(eim, searchDelta, maxBsort = 1000) {
    const scoreDelta = 0.05 // becuase it ranges from 0 to bout 0.6
    return { 
      score : Utils_Front.roughValueOf(eim.score, scoreDelta),
      search : (eim.searchScore == undefined || eim.searchScore == 0 ? 0 : Utils_Front.roughValueOf(eim.searchScore, searchDelta)),
      dt :  Utils_Front.DateFloorOf(eim.dt),
      bSort : (eim.bSort == undefined ? maxBsort + 1 : eim.bSort) // i.e. unknow mena s put last
    }
  }




  function NormArr_Fill(items, searchDelta) {
    const arr = [] //DateDifference_Days

    const startDate = Utils_Front.DateFloorOf(new Date('2023-01-01'))
    for (let eim of items) {
      const r = getRoughStats(eim, searchDelta, items.length )
      // fields will retain original keys but will be just numbers!!!!!!
      const record = {
        obj: eim,
        score : r.score, // the more the better
        search : r.search, // the more the better
        dt: Utils_Front.DateDifference_Days(startDate, r.dt),
        bSort : -r.bSort // the less the better
      }
      arr.push(record)
    }
    return arr
  }

  function sortItems(sortId, items) {
    return sortItemsPlus(sortId, $$.siteMode, items)

  }

  function sortItemsPlus(sortId, searchTypeSet, items) {
    console.log('sortItems: ' + sortId)
    let sortedItems = null // [...items]

    // fadeway idea - see my chat with gpt
    console.log('(((')
    console.log('Sorting ' + sortId + ', modes : ' + [...searchTypeSet].join(','))
    console.log(')))')

    let searchDelta = null    


    let toSortItems = [...items]

    switch(sortId){
      case 'default' : {

        if (! $$.keyCb == null) { // items are sort as they should be ...
          const x = 1 // actually it does not even come here!!
          // and see design note [why not to keep copy of refresh on the cient]
        } if (searchTypeSet.has('searchTextual')) { // 
          const textSearchDelta = 0.1 // because monogo's search returns 2- 10
          const searchDelta = textSearchDelta
          const nArr = new NormArr(NormArr_Fill(toSortItems, searchDelta))
          sortedItems = nArr.sort(['search', 'dt','score','bSort'])

        } else if (searchTypeSet.has('searchSemantic')) {
          searchDelta = 0.01 // because monogo's search returns 0.80-0.99
          const nArr = new NormArr(NormArr_Fill(toSortItems, searchDelta))
          sortedItems = nArr.sort(['search', 'dt','score','bSort'])

        } else { // no search}
          const nArr = new NormArr(NormArr_Fill(toSortItems, 0))
          sortedItems = nArr.sort(['bSort','score','dt','search'])
        }
       
         break
      }
      case 'date' : {

        if (searchTypeSet.has('searchTextual')) { // 
          const textSearchDelta = 0.1 // because monogo's search returns 2- 10
          const searchDelta = textSearchDelta
          const nArr = new NormArr(NormArr_Fill(toSortItems, searchDelta))
          sortedItems = nArr.sort(['dt','search','score','bSort'])
  
        } else if (searchTypeSet.has('searchSemantic')) {
          searchDelta = 0.01 // because monogo's search returns 0.80-0.99
          const nArr = new NormArr(NormArr_Fill(toSortItems, searchDelta))
          sortedItems = nArr.sort(['dt', 'search','score','bSort'])

        } else { // no search}
          const nArr = new NormArr(NormArr_Fill(toSortItems, 0))
          sortedItems = nArr.sort(['dt', 'bSort','score','search'])
        }
        
        break
      }
    }

    return sortedItems
  }



  function fixDateEncoding(eimList) {
        // detect if eimList has screwdUp dates - strings instead of dates and fixes it
        if (eimList && eimList.length > 0 && typeof eimList[0].dt === 'string' ) {
          eimList = eimList.map((eim)=> {
            const fixedDt = Utils_Front.fixedDateEncodingIf2(eim.dt, true) // i.e. ..
            //if (typeof eim.dt === 'string') eim.dt = new Date(eim.dt)
            if (fixedDt) eim.dt = fixedDt
              //not eim.dt = Utils_Front.FixIfDateString(eim.dt)
            return eim
          })
        }
    return eimList
  }


/////////////////////////////////
/* function setwork_moved(indexedSet, index, ops) {
  let cpy=new Set(indexedSet)

  if (!Array.isArray(ops)) ops = [ops]
  if (!Array.isArray(index)) index = [index]

  let isExclusive = false
  for (const op of ops) {
    if (op == 'clear') { cpy = new Set(); continue }
    for (const idx of index) {
      if (op == 'exclusive') {
        // erase all that are not index
        const restoreIndex = cpy.has(idx)
        cpy = new Set()
        if (restoreIndex) cpy.add(idx)

      

      } else if (op == 'flip') {
        if (cpy.has(idx)) cpy.delete(idx)
        else cpy.add(idx)

      } else if (op == 'on') {
        cpy.add(idx)

      } else if (op == 'off') {
        cpy.delete(idx)

      } else {
        // ignore for now
        return indexedSet
      }
    }
  }

  return cpy
}
 */
//const [selectItemIndex, setSelectItemIndex] = useState(new Set([]))
function handleSelectItem(e, index, op) {
 // if (e) e.preventDefault() - it blocks hrefs 
 
  const cpy = Utils_Front.Setwork($$.selectItemIndex, index, op) 
  $$change('selectItemIndex', cpy) //setSelectItemIndex(cpy)

  return
}

//const [expandItemIndex, setExpandItemIndex] = useState(new Set([]))
function handleExpandItem(e, index, op) {
  if (e) e.preventDefault()

  const cpy = Utils_Front.Setwork($$.expandItemIndex, index, op) 
  $$change('expandItemIndex',cpy) //setExpandItemIndex(cpy)

  return
}

// //const [pOnlyIndex, setpOnlyIndex] = useState(new Set([]))
// function handlePOnly(e, index, op) {
//   if (e) e.preventDefault()

//   const cpy = Utils_Front.Setwork($$.pOnlyIndex, index, op) 
//   $$change('expandItemIndex', cpy) //setExpandItemIndex(cpy)

//   return
// }

/////////////////////////////////


  // function setActiveSortWrapper(sortId) {
  //   console.log('setActiveSortWrapper: ' + sortId)
  //   setActiveSort(sortId)
  //   setShowSortOptions(false)

  //   const sortedItems = sortItems(sortId, items)
    
  //   setItemsWrapper0(sortedItems, 'from sorting')

  // }


  // function setItemsWrapperSort(eimList,  whoCalled) {
  //   let sortedEimList = fixDateEncoding(eimList)
  //   sortedEimList = sortItems(activeSort, sortedEimList) // - so they are in proper order
    
  //   // wipes out selections and expansions
  //   setItemsWrapper0(sortedEimList, whoCalled)

  //   return sortedEimList // so the caller would know result right away
  // }

  function finishWithSort(sortId, searchTypeset, eimList,  whoCalled) {

    let sortedEimList = fixDateEncoding(eimList)
    sortedEimList = sortItemsPlus(sortId, searchTypeset, sortedEimList) // - so they are in proper order
    
    // wipes out selections and expansions
   //setItemsWrapper0(sortedEimList, whoCalled)
   $$change('items', sortedEimList) //setItems(sortedEimList);
   handleExpandItem(null,null,'clear')
   handleSelectItem(null,null,'clear')
   //expansionErase(universalDict, udCallBack) // hopefully just changes part of ud

    return sortedEimList //
  }



  function expandHighlightsIfNecessary(eimList) {
    const indexesToExpand = []
    for (let index = 0; index < eimList.length; index++) {
      const eim = eimList[index]
      const weed = eim.weed
      if (weed) {
        if (weed.isTitleOrQuoteHighlighted() // already
            || ! weed.isTextHighlighted() // i.e. expansion will show nothing
           ) {
          // no need to expand
        } else {
          indexesToExpand.push(index)
        }
      }
    }

    handleExpandItem(null,indexesToExpand,['clear', 'on']);
    return indexesToExpand // just in case 
  }

  function setItemsWrapperPlus(sortId, searchTypeSet, eimList, textSearch, whoCalled) {

    let expandAfterSorting = ! (textSearch == undefined || textSearch == null || textSearch.trim() == '')

    //const sortedEimList = setItemsWrapperSort(eimList, whoCalled) 
    const sortedEimList = finishWithSort(sortId, searchTypeSet,eimList, whoCalled) 
       // -- wipes out expansions and selections, by the way?

    // expand those that have neither title highlighted nore quote?
      // do i really want it ??
    if (expandAfterSorting && false) // - Hell with it - to expansive to show all of them
      expandHighlightsIfNecessary(sortedEimList) // actually jsut MARKS for expansion
    
  }

  // function setItemsWrapper0(finalEimList, whoCalled) {
  //   setItems(finalEimList);
  //   handleExpandItem(null,null,'clear')
  //   handleSelectItem(null,null,'clear')
  //   //expansionErase(universalDict, udCallBack) // hopefully just changes part of ud
  // }

 //////////////////////////////////////////////////////

//-------------------------------------------------
function isWorkingAnim(working, dtCacheFromServer) {
  $$change('advisoryMessage',null) //setAdvisoryMessage(null) // '!!!!
  $$change('isWorking', working) // - changing state
  if (dtCacheFromServer) $$change('dtCache', dtCacheFromServer) // setDtCache(dtCacheFromServer)
}

//----------------------------------------------------
  


  function changeSiteMode_Atomic(orders) {
    const newSiteMode = new Set($$.siteMode); // a copy of the current state
    for (let order of orders) {
      changeSiteMode_Op(newSiteMode, order.mode, order.op)
    }
    // console.log("=== changing site mode to: " + [...newSiteMode].join(', '))
                     
    $$change('siteMode', newSiteMode) //setSiteMode(newSiteMode);

/*     
    // now, and clunky, transport searchTypes from siteMode to searchTypeMode
    NOT necessary now
    let newSearchTypeMode = new Set()
    if (newSiteMode.has('searchSemantic')) {
      newSearchTypeMode.add('searchSemantic')
    } else if (newSiteMode.has('searchTextual')) {
      newSearchTypeMode.add('searchTextual')
    }
    setSearchTypeMode(newSearchTypeMode); 
*/

    return newSiteMode
  }

  function changeSiteMode_Op(modeSet, modeStr, op = 'flip') {

    if (op == 'add') {
      modeSet.add(modeStr)
    } if (op == 'delete') {
      modeSet.delete(modeStr)
    } else if (op == 'flip') {
      if (modeSet.has(modeStr)) modeSet.delete(modeStr); else modeSet.add(modeStr)
    }

    // console.log("=== changing site mode to: " + [...newSiteMode].join(', '))

    return modeSet    
  }


  //-----------------------------------------------

  // function getFilterJson_OLD() {
  //   try { 
  //     let  fJson = null

  //     if (filterInputRef.current === null) {
        
  //       fJson = null
  //     } else if (filterInputRef.current === undefined) {
  //       fJson = filter
  //     } else {
  //       const fStr = filterInputRef.current.value
  //       fJson = JSON.parse(fStr)
  //     }

  //     return fJson
  //   } catch(e) {
  //     AlertMessage('getFilterJson\n' + e.message); return null;
  //   }
  // }


  function addToMongodbFilter() {
        // $or: [
                //     { 'props': { $exists: false } }, // Includes documents where 'props' does not exist
                //     { props : {$elemMatch: { 'theme': "geo", 'kind':"us_state", 'nm': "PA", 'val': { $gt: 0.8 } } }}
                //   ]\\ 

    //const st2 = $$.st2 //{ $in: ['NH'] }
    const st2_Array = St2s_by_Fullname($$.geoName)




    if ($$.geoName == null) return null // i.e. for 'all states'
    if (st2_Array == null) return null // could be .. 'all states'
    const ff = {$and: [
        { props : {$elemMatch: {  theme: "geo" 
                                  , kind:"us_state"
                                  //, nm: { $in: [st2] } // st2
                                  , nm: { $in: st2_Array }
                                  , val: { $gt: 0.66 } 
                                } 
                  }
        }
      ]
    }
          
    return ff
    

  }

  function getFilterJson() {
    try { 
      let  fJson = $$.filter 
      let fStr = null 

      if (filterInputRef.current === null || filterInputRef.current === undefined) {
        // happens becuase filterInputRef is not managed?
        if (fJson == null) {
          return fJson // >????????????????
        }

        fStr = JSON.stringify(fJson) // - so it can be parsed backed into json below
      } else {
        fStr = filterInputRef.current.value
      }

      fJson = JSON.parse(fStr)
      fJson.fParams['addToMongodbFilter'] = addToMongodbFilter()

      //fJson.fParams['userLabel'] = $$.userLabel - added to postdata directly in get_elrais


      return fJson
    } catch(e) {
      AlertMessage('getFilterJson\n' + e.message); return null;
    }
  }


  const AlertMessage=(msg, asIs = false)=>{
    
    if (msg == null) {
      $$change('advisoryMessage',null)
      return 
    } 

    SoundBeep({type:'triangle', secs:0.2})
    if ( asIs || $$.siteMode.has('dbgMode') ) { 
      $$change('advisoryMessage',msg) //setAdvisoryMessage(msg)
    } else {
      $$change('advisoryMessage',"Server Error. \n Please try to reload later.")
    }
  }

  const fetchData = ({sort = null, siteMode=null, filter = null, udCallBack = null, raison = null}) => {

    const someSortId = sort ?? $$.activeSort
    const someSiteMode  = siteMode ?? $$.siteMode
    
    //const fStr = filterInputRef.current.value
    try {

      const fJson = getFilterJson()
      if (fJson == null) return // the alert is already displaeys

      // let action = 'getCurrent'
      // if (fJson.dbg) {
      //   action = fJson.action
      //   delete fJson.action // so thta mongo wouldn't get upset
      // }
    
      $$change('filter', fJson) // filter value will  be updated on the next react cycle??

      Get_Elrais({ filter:fJson, options:$$.options, action:{fn:'getCurrent'}
        , fnIsWorking:isWorkingAnim, dtCacheClient:$$.dtCache, userLabel: $$.userLabel, udCallBack:udCallBack, raison:raison
        }
      ).then((result) => {

        if (result.error) {
          if (! dealWithBackError(result)) return // i.e error was severe
        }
        // if (result.error) {
        //   AlertMessage('fetch Data\n' + result.error.message)
        // } else {
        const{eimList, cbList, fbList} = result.data

        const NoSearch = null // scratch
        setItemsWrapperPlus(someSortId, someSiteMode, eimList, NoSearch, "fetchData")

        $$change('actors',CbItem.createCbList(cbList))
        featureSortAndSet(fbList, fJson)
     
      })
        .catch((error) => {
          AlertMessage('fecthData 2\n' + error.stack); return;
        });

    } catch(e) {
      AlertMessage('fecthData 3\n' + e.message); return;
    }
  };




function multiSearchOr(text, searchWords){
    var rex = new RegExp(searchWords.join("|"),"gi");
    const xxx = text.match(rex) //searchExp.search(text)
    const yyy = rex.exec(text) //searchExp.search(text)
    return (rex.test(text))?"Found!":"Not found!";
}


  function actorsMerge(actors) {
    // from bottom up
    const mergedActors = CbItem.CbMerge(actors)
    return mergedActors

  }


  function WeedTheItems(textSearch, eimList) {
    const eimListWithWeed = []
    for (let eim of eimList) {

      const weed = QMatching.QueryMatchEval(textSearch, eim.title, eim.quote, eim.txtIn)
      const eimWeeded = {...eim, weed: weed}
      eimListWithWeed.push(eimWeeded)
    }
    return eimListWithWeed
  }

  function TextSearchWeeding(textSearch, eimList) {
    //// crucial - Bill Barr should not be found but Help Israel .. should even with 'help'?

    //#todo - use mmm.pLastResort
    const MinimumNumberToTake = 3
    const eimListWithWeed = WeedTheItems(textSearch, eimList)
    // for (let eim of eimList) {

    //   const weed = QMatching.QueryMatchEval(textSearch, eim.title, eim.quote, eim.txtIn)
    //   const eimWeeded = {...eim, weed: weed}
    //   eimListWithWeed.push(eimWeeded)
    // }
    eimListWithWeed.sort((eim1, eim2)=> {return (-eim1.weed.dropScoreOf() + eim2.weed.dropScoreOf())
      //-eim1.weed.pBest + eim2.weed.pBest
      
      
    }) 

    const eimListWeeded = []
    for (let eimWeeded of eimListWithWeed) {
      const weed = eimWeeded.weed

      // modify the searchScore by the match!
      // actually just change it!
      eimWeeded.searchScore = weed.dropScoreOf() // pBest
      if ( eimListWeeded.length < MinimumNumberToTake 
        ||  weed.pBest  >= 0.99 *  weed.pAcceptable ) { //* weed.pDrop) {
        eimWeeded.weed.highlight(0) // weed.pAcceptable)
        eimListWeeded.push(eimWeeded)
      }
    }

    
    // for (let eim of eimList) {
    //   const mmm = QMatching.QueryMatchEval(textSearch, eim.title, eim.txtIn)
    //   if ( eimListWeeded.length < MinimumNumberToTake ||  mmm.pBest  >= 0.99 *  mmm.pAcceptable * mmm.pDrop) {
    //     eimListWeeded.push(eim)
    //   }
    // }

    return eimListWeeded
  }

  const doSearch = (textSearch, isSemantic) => {
    try{
      
      const searchType = (isSemantic? 'vectorSearch':'textSearch') //'mergeActors'
      const action = {fn:searchType, params:{ query:textSearch}}

      const fJson = getFilterJson();
      //const displayUpdatedFeatures = fJson.fParams.features
      

      Get_Exp({ filter:fJson, 
        options:null, action:action, fnIsWorking: isWorkingAnim, dtCacheClient:$$.dtCache, userLabel: $$.userLabel, udCallBack:udCallBack})
        .then((result) => {
          if (result.error) {
            dealWithBackError(result)
            //AlertMessage('handleSearch 2\n' + result.error.message)
          } else {
            const{eimList, cbList, fbList} = result.data // see indicate_no_change_in_actors on server
            if (eimList == null) {
              // nochange i eims
            } else {
              
              let newSiteMode = null
              if (isSemantic) {
                newSiteMode = changeSiteMode_Atomic(
                  [
                    {mode:'searchSemantic', op:'add'},
                    {mode:'searchTextual', op:'delete'},
                    {mode:'searchTextualImplied', op:'delete'},
                  ]
                )
              } else {
                newSiteMode = changeSiteMode_Atomic(
                  [
                    {mode:'searchSemantic', op:'delete'},
                    {mode:'searchTextual', op:'add'},
                    {mode:'searchTextualImplied', op:'add'},
                  ]
                )
              }
              

              // see dessign note [textSearch result order] Callers expect the order will be by score??? - or let them do it
              // on the back
//in both cases (because we may weed only the best andorder matters:)
              eimList.sort((e1,e2) => {return e2.searchScore - e1.searchScore} ) 
              
              let eimListWeeded = []
              // heightlight only for textual, not semantic
              if (isSemantic) {
                // no weeding!!!
                eimListWeeded = eimList
              } else {  // crucial - Bill Barr should not be found but Help Israel .. should even with 'help'?
                eimListWeeded = TextSearchWeeding(textSearch, eimList)
              }

              const textToHighlight = (isSemantic ? null : textSearch) // it is silly to highligh tred a lot of stuff ..
              setItemsWrapperPlus($$.activeSort, newSiteMode, eimListWeeded, textToHighlight, "get_ex")

      
              {
                $$change('keyCb',null) //??NOPE!!!   Key Cb stays?? even on serach results??? - should i leave him, selected, invisible on in order to return?


                // // highlight actor in items!
                // if (false) { // done in wrapper plus
                // const h = Search_ToArray(textSearch)
                // setHiTextArr(h)
                // } 

                // already indicate change in duality
                // but this thing has been done already on the click?
                $$change('queryGrayed',false) // see design note [actor/search duality]

                
                const displayUpdatedCbList = true
                if (displayUpdatedCbList) {
                  if (cbList) {  // otherwise no change
                    $$change('actors',CbItem.createCbList(cbList));
                  }
                }

                //if (displayUpdatedFeatures) {
                  //if (fbList) {  // otherwise no change
                    featureSortAndSet(fbList, fJson)
                  //}
                //}

              }
            }
              
          return 
        }

        // if (cbList == null) { // no supposed to return actors on search
        //   // nochange i actors
        // } else {
        //   $$change('actors',CbItem.createCbList(cbList));
        // }
      
      })
      .catch((error) => {
        AlertMessage(error.message); return;
      });
      }  catch(e) {
        AlertMessage(e.message); 
      }
      return true // i.e. submitted to the back
  }

  const handleSearch = (e, isSemantic = false, searchString = null) =>  {

    if (e) e.preventDefault()

    // make sure that previous alert message is gone :
    AlertMessage(null)


    if (isSemantic) {
      // make sure that no st2 - goes to all states
      onSt2(null) ; //  $$.change('geoName',null)
    }

    const searchType = (isSemantic? 'vectorSearch':'textSearch') //'mergeActors'
    

    //////////////////////
      let textSearch = searchString ?? $$.queryText //queryElRef.current.value
      if (searchString != null) {
        //queryElRef.current.value = searchString
        $$change('queryText',searchString)
      }

      // NOTICE that filter is null - see design note [..] in the back server

      if (textSearch.trim() == '') {
        //just make sure that implied mode is correct ???
        if (isSemantic) {
          changeSiteMode_Atomic(
            [
              // {mode:'searchSemantic', op:'add'},
              // {mode:'searchTextual', op:'delete'},
              {mode:'searchTextualImplied', op:'delete'},
            ]
          )
        } else {
          changeSiteMode_Atomic(
            [
              // {mode:'searchSemantic', op:'delete'},
              // {mode:'searchTextual', op:'add'},
              {mode:'searchTextualImplied', op:'add'},
            ]
          )
        }
        
        return false // nothing to search
      }

      try{
      
      

      const action = {fn:searchType, params:{ query:textSearch}}

      const fJson = getFilterJson();
      //const displayUpdatedFeatures = fJson.fParams.features
      

      Get_Exp({ filter:fJson, 
        options:null, action:action, fnIsWorking: isWorkingAnim, dtCacheClient:$$.dtCache, userLabel: $$.userLabel, udCallBack:udCallBack})
        .then((result) => {
          if (result.error) {
            dealWithBackError(result)

          } else {
            const{eimList, cbList, fbList} = result.data // see indicate_no_change_in_actors on server
            if (eimList == null) {
              // nochange i eims
            } else {
              
              let newSiteMode = null
              if (isSemantic) {
                newSiteMode = changeSiteMode_Atomic(
                  [
                    {mode:'searchSemantic', op:'add'},
                    {mode:'searchTextual', op:'delete'},
                    {mode:'searchTextualImplied', op:'delete'},
                  ]
                )
              } else {
                newSiteMode = changeSiteMode_Atomic(
                  [
                    {mode:'searchSemantic', op:'delete'},
                    {mode:'searchTextual', op:'add'},
                    {mode:'searchTextualImplied', op:'add'},
                  ]
                )
              }
              

              // see dessign note [textSearch result order] Callers expect the order will be by score??? - or let them do it
              // on the back
//in both cases (because we may weed only the best andorder matters:)
              eimList.sort((e1,e2) => {return e2.searchScore - e1.searchScore} ) 
              
              let eimListWeeded = []
              // heightlight only for textual, not semantic
              if (isSemantic) {
                // no weeding!!!
                eimListWeeded = eimList
              } else {  // crucial - Bill Barr should not be found but Help Israel .. should even with 'help'?
                eimListWeeded = TextSearchWeeding(textSearch, eimList)
              }

              const textToHighlight = (isSemantic ? null : textSearch) // it is silly to highligh tred a lot of stuff ..
              setItemsWrapperPlus($$.activeSort, newSiteMode, eimListWeeded, textToHighlight, "get_ex")

      
              {
                $$change('keyCb',null) //??NOPE!!!   Key Cb stays?? even on serach results??? - should i leave him, selected, invisible on in order to return?


                // // highlight actor in items!
                // if (false) { // done in wrapper plus
                // const h = Search_ToArray(textSearch)
                // setHiTextArr(h)
                // } 

                // already indicate change in duality
                // but this thing has been done already on the click?
                $$change('queryGrayed',false) // see design note [actor/search duality]

                
                const displayUpdatedCbList = true
                if (displayUpdatedCbList) {
                  if (cbList) {  // otherwise no change
                    $$change('actors',CbItem.createCbList(cbList));
                  }
                }

                //if (displayUpdatedFeatures) {
                  //if (fbList) {  // otherwise no change
                    featureSortAndSet(fbList, fJson)
                  //}
                //}

              }
            }
              
          return 
        }

        // if (cbList == null) { // no supposed to return actors on search
        //   // nochange i actors
        // } else {
        //   $$change('actors',CbItem.createCbList(cbList));
        // }
      
      })
      .catch((error) => {
        AlertMessage(error.message); return;
      });
      }  catch(e) {
        AlertMessage(e.message); 
      }
      return true // i.e. submitted to the back
    /////
    
  }


  const handleExp2 = ({actionFn='exp',actionParams}) => {

    //const query = queryElRef.current.value
    const query = $$.queryText.trim()

    //if (params == null) params = query

    let action = null
    if (actionFn == 'snapStore') {
      action = {fn:'neverMind', params:{ snapStore:true, snapName:query, forceExtract: true}}
    } else if (actionFn == 'snapLoad') {
      action = {fn:'neverMind', params:{ snapLoad:true, snapName:query, forceExtract: true}}
    }

    try {
      const fJson = getFilterJson()
      Get_Exp({ filter:fJson, options:null
          , action, fnIsWorking:isWorkingAnim, dtCacheClient:$$.dtCache, userLabel: $$.userLabel, udCallBack:udCallBack}).then((result) => {
        if (result.error) {
          BlobDisplay(result.error, blobWindowRef)
        } else {
          BlobDisplay(result.data, blobWindowRef)
        }  
      });
    } catch(e) {
      AlertMessage(e.message); return;
    }
    return;
  }

  const handleExp = (param) => {

    if (param == 'varia') { // a simple way to go my other page
      //window.open("/textareapage");
      window.open("/VariaProto");
      return
    }

    if (param == 'tpage') { // a simple way to go my other page
      window.open("/textareapage");
      return
    }

    if (param == 'test') {
      $$change('advisoryMessage',"test advisory message")
      $$change('items', []) //setItems([])
      return
    }

    if (true) { // testing reation of th esystem on obsolete actor:
      let cbHumptyDumpty = new CbItem()
      cbHumptyDumpty.nm = "Humpty Dumpty"
      // {
      //   "nm": "Humpty Dumpty",
      //   "syns": [],
      //   "aggImp": 0.1274992774028285,
      //   "eimIdxes": []
      // }
      
      const udHumptyDumpty = {
        "action": "actor",
        "param": cbHumptyDumpty 
      }
      udCallBack(udHumptyDumpty )
      return
  }
  

    // const mergedActors = actorsMerge(actors)
    // $$change('actors',mergedActors)
    // return;

   //alert(JSON.stringify(universalDict['selectChange'],null,2))


/* 
    now it is done on @i
    const selectIndexArr = [...universalDict['selectChange']].sort();

    const selectItems = selectIndexArr.map((i, index) => items[i])

    BlobDisplay(selectItems, blobWindowRef)

    return */
  

 /*    alert(ScratchBackUrl)
    HiText(" the economy isn't the best but is not  bad ether", "the best economy ever",  true)
    return */

    const actionFn = 'aggregate'
    const textSearch = $$.queryText // queryElRef.current.value
    const action = {fn:actionFn, params:{ query:textSearch}}
    try {
      const fJson = getFilterJson()
      Get_Exp({ filter:fJson, options:null, action, fnIsWorking:isWorkingAnim, dtCacheClient:$$.dtCache}).then((result) => {
        if (result.error) {
          AlertMessage(result.error.message) // ??
        } else if (actionFn == 'mergeActors') {
          const mergedCbListRaw = result.data
          const mergerActors = CbItem.createCbList(mergedCbListRaw)
          $$change('actors',mergerActors)
        } else {
          BlobDisplay(result.data, blobWindowRef)
        }
        
      });
    } catch(e) {
      AlertMessage(e.message); return;
    }
    return;


    //let searchArray = queryElRef.current.value.split(' ')
    let searchArray = $$.queryText.split(' ')
    // remove excessiv spaces
    searchArray = searchArray.filter((word)=> {return (word != '')}); // https://stackoverflow.com/questions/24806772/how-to-skip-over-an-element-in-map
    //const res = HighlightText(" Nikki damn it Haley",["Nikki", "xxx", "Haley"])
    const res = HighlightText(filterInputRef.current.value,searchArray)//["Nikki", "xxx", "Haley"])
    AlertMessage(res.replacedText)
    return
    
    // try {
    //   const fJson = getFilterJson()
    //   Get_Exp({ filter:fJson, options }).then((result) => {
    //     if (result.error) {
    //       BlobDisplay(result.error, blobWindowRef)
    //     } else {
    //       BlobDisplay(result.data, blobWindowRef)
    //     }
        
    //   });
    // } catch(e) {AlertMessage(e.message); return;}
  };

  const handleFilter = () => {
    // Apply the filter logic here
    // For example:
    const newFilter = { source: 'Filtered Source' } // ignored , see inside fetchDta
    $$change('filter',newFilter);
    fetchData({sort:$$.activeSort, siteMode:$$.siteMode, filter:newFilter, udCallback:udCallBack
      , raison:'filter'});
  };

  // const handleSort = () => {
  //   // Apply sorting logic here
  //   // For example:
  //   //const sortedItems = [...items].sort((a, b) => a.title.localeCompare(b.title));
    
  //   // now set and sort
  //   setItemsWrapperSort(items, "handleSort");
  // };


  const handleRefresh = () => {
    //if (OldWayHighlighting) setHiTextArr(Search_ToArray(null)) // obsolete
    //setQueryGrayed(true) // see design note [actor/search duality]
    $$change('keyCb',null) // in this case it's safe to do it here - see design note [async calls and set* useState()]
    
    // get rid of $$save()d history?
    $$clear()


    // optimistically ?
    const newSiteMode = changeSiteMode_Atomic(
      [
        {mode:'searchSemantic', op:'delete'},
        {mode:'searchTextual', op:'delete'},
        {mode:'searchTextualImplied', op:'add'}, // default is a normal search
      ]
    )

    // if search field is not empty then gray it out
    //const textSearch = queryElRef.current.value
    const textSearch = $$.queryText.trim()
    if (textSearch && ! (textSearch.trim() == '')) {
      $$change('queryGrayed',true)
    } else { // in case it's grayed out make it clear
      $$change('queryGrayed',false)
    }
    
    fetchData({sort:$$.activeSort, siteMode:newSiteMode, raison:'refresh'});
    /* design note [async calls and set* useState()]
    - looks like async call's completion comes first??
    and that meand that the on completion the same state may be 
      reset unknowingly ignoring pre async set* call
     */
  };

  const handleFilterInputChange = () => {};

  function featureSortAndSet(fbList, fJson) {
    const displayUpdatedFeatures = fJson &&fJson.fParams.features
    if (!displayUpdatedFeatures || fbList == null ) {
      $$change('features',null) // restoring display

    } else { //} if ( fbList) {

      fbList.sort((f1, f2)=> {
        const f1_prefix = f1.nm.split('_')[0] // like cat_Elections or us_Texas
        const f2_prefix = f2.nm.split('_')[0]
        let cmp = f1_prefix.localeCompare(f2_prefix)
        if (cmp == 0) {
          cmp = -(f1.aggImp - f2.aggImp)
        }
        return cmp

      })
      const feats = CbItem.createCbList(fbList)
      $$change('features',feats)

    } // else {
    //   setFeatures(null) // restoring display
    // }

  }
 
  function OutsideOp({op, params}) {
    // returns true if op is done
    if (params == undefined || params == null) return true
    const fJson = getFilterJson()


    const q = params.q
    switch (op) {
    case 'st':  handleSearch(null, false, q); return true
    case 'ss':  handleSearch(null, true, q); return true
    case 'refresh': handleRefresh(); return true

    case 'view' :
      // clean
      $$change('keyCb',null);
      $$change('selectItemIndex',new Set()) // setSelectItemIndex(new Set()); 
      $$change('expandItemIndex', new Set()) //setExpandItemIndex(new Set()); 

      const {eimList, cbList, fbList, vname = null, expName = null} = params.data
          if (eimList == null) {
            // nochange i eims
          } else {
            // actors should be highlighted as well
            const eimListWithWeed = eimList //WeedTheItems(cb.nm, eimList) 
            //for (const eimWeeded of eimListWithWeed) eimWeeded.weed.highlight(0)

            const NoSearch = null  // for actor??
            setItemsWrapperPlus($$.activeSort, $$.siteMode, eimListWithWeed, NoSearch, "actor action");
            if (expName) {
              //queryElRef.current.value = expName
              $$change('queryText',expName)
            }
          }

          if (cbList) {  // otherwise no change
            $$change('actors',CbItem.createCbList(cbList));
          }
          //if (fbList) {  // otherwise no change
            featureSortAndSet(fbList, fJson)
          //}
     
          return true
    default: return false
    }
    return false // i.e. op is not done
  }


  useEffect(() => {


   

    const url = new URL(window. location. href)
/*     const booleanFlags = [];
    for (const [key, value] of searchParams) {
    if (value === '') {
        booleanFlags.push(key);
    } */
    const op = url.searchParams.get('op')
    const searchString = url.searchParams.get('q')

    const st2 = url.searchParams.get('st2')
    if (st2) {
      $$change('geoName', st2)
    }

    //console.log('op: ' + op + ', q: ' + searchString)
    if (url.searchParams.has('fm')) 
      changeSiteMode_Atomic([{mode:'dbgMode'}, {mode:'fullMode'} ])

    let userLabel = url.searchParams.get('ul')
    if (userLabel == null) if (url.hostname == 'localhost') userLabel = 'me'
    if (userLabel) $$change('userLabel', userLabel)
    
      

    if (op && searchString) {
      OutsideOp({op: op, params: {q:searchString}})
      return
    // { switch(op) {
    //   case 'st':  handleSearch(null, false, searchString); return
    //   case 'ss':  handleSearch(null, true, searchString); return
    // }
  
    }

    const startWithDebugging = false
    if (startWithDebugging) {
      const fJson = getFilterJson()
      fJson.fParams.features = true
      $$change('filter',fJson)
      changeSiteMode_Atomic([{mode:'dbgMode'}, {mode:'fullMode'} ])
    }


    fetchData({raison:'open'}); // for initial load only



    //////////////////////////
    function getCurrentItems() { // to use inside closure
      return $$.items
    }


    const handlePostMessage = (event) => {

      if (window.location.origin === event.source.location.origin &&
        window.location.pathname === event.source.location.pathname) {
        // More likely to be from the same window
        return
      }


      //console.log("TARGET: from origin : " + event.origin + ", to : " + window.location.origin);
      console.log('POST SOURCE: ' + event.source.location.pathname)
      // if (event.origin == window.location.origin) {
      //   // Check the origin to ensure security
      //   return;
      // }
      // if (true) {} else
      // if (
      //   event.source.location.pathname != '/textareapage'
      //   ||
      //   event.source.location.pathname != '/variaproto'
      // ) return 

      if ( event.source.location.pathname == '/elraiproto') return // sending itslef in infinite loop? 

      // Handle the incoming message
      const receivedMessage = event.data;
      console.log("TARGET: Received message: " + receivedMessage);

      OutsideOp(receivedMessage)

      const responseObj = {items:getCurrentItems()}
      // Send acknowledment
      event.source.postMessage(
          //"hi there yourself!  the secret response " + "is: rheeeeet!",
          responseObj,
          event.origin,
        );
    };

    if (url.searchParams.has('_posting')) {// see design note ['_posting' message]
      window.addEventListener("message", handlePostMessage)
      return () => {
        window.removeEventListener("message", handlePostMessage);
      };
    }
    /////////////////////////

  }, []);


/*   infinite loop trap - not ncecessary now
  useEffect(() => {
    const sortedItems = sortItemsPlus(activeSort, searchTypeMode, items)
    //setItemsWrapper0(sortedItems, "from use effect")
    setItems(sortedItems);

    // erase all expansions and selections
    handleExpandItem(null,null,'clear')
    handleSelectItem(null,null,'clear')

    // ???
    // if (expandAfterSorting && false) // - Hell with it - to expansive to show all of them
    //   expandHighlightsIfNecessary(sortedEimList) // actually jsut MARKS for expansion
  },[items, activeSort,searchTypeMode]) 
*/
  

    // by default is set up for the whole window
    UseEventListener('keydown', (event) => { //omg
      // if (event.key === "Delete" || event.key === "del") {
      //   alert(' delete jey is pressed')
      // }
      //if (event.ctrlKey && event.shiftKey) console.log(' control shift hit ')

      // Howwever, for the question mark (?), the key detection can be a bit tricky because it usually requires a combination of Shift + / on standard keyboards. Therefore, you'll need to check for the Shift key and the / key simultaneously.
      if (event.ctrlKey && event.shiftKey && event.key === "?") {
        //changeSiteMode('dbgMode'); 
        changeSiteMode_Atomic([{mode:'dbgMode', op:'flip'}])
        console.log('siteMode: ' + [...$$.siteMode].join(','))
      }
      if (event.ctrlKey && event.shiftKey && event.key === ">") {
        $$change('dbgDialogOpen',true)
      }


      // what is e.nativeEvent.button??? as chat
      // if (e.nativeEvent.button === 0) {
      //   console.log('Left click');
      // } else if (e.nativeEvent.button === 2) {
      //   alert('Native Right click');
      // }


    });



  function dealWithBackError(result) {
    // returns true if caller can proceed
    
    //dealt with the problem and c
    if (result.error == null) return true

    if (result.error instanceof  String || typeof(result.error) == 'string') {
      // assume that result.error is a string
      $$change('advisoryMessage',result.error) // really??, not alert???
      return false
    }

    if (result.error.code  == undefined) {
      // assume that result.error is a string
      $$change('advisoryMessage',result.error.message) // really??, not alert???
      return false
    }
    switch (result.error.code) {
      case Utils_Front.ErrorCode.Cache_Was_Reloaded:
        $$change('advisoryMessage','Data was reloaded.')
        return true

      // - the only good message, the rest is bad?

      case Utils_Front.ErrorCode.Just_An_Info_Message:
        $$change('advisoryMessage',result.error.message) // really??, not alert???
        return false
      case Utils_Front.ErrorCode.Cache_Is_Locked:
        $$change('advisoryMessage','Data updating. Retry soon.')
        return false 

      case Utils_Front.ErrorCode.OpenAi_Error:
        $$change('advisoryMessage','Ai Error. Retry later.')
        return false 

      default: // catch for all other bads
        $$change('advisoryMessage','Please retry later.')
        return false
    }
  }

  const udCallBack = function(universalDictChange) {

    // react on 
    const action = universalDictChange['action']
    const param  = universalDictChange['param']


    if (action == 'get') { // the last resort - to pass any state deem inside components - they all have access to udCallBack
      if (param == '$$') {
        return $$
      }
    }

    if (action == '$$change') {
      $$change(param.nm, param.val)
      if (param.nm == 'activeSort') { // i have to manually prod it alog
        finishWithSort(param.val, $$.siteMode, $$.items, "by ...")

      }
      return $$
    }


    // save changes
    const cpyUniversalDict = {...$$.universalDict}
    Object.keys(universalDictChange).forEach((key,index) => {
      cpyUniversalDict[key] = universalDictChange[key]  
    });
    $$change('universalDict', cpyUniversalDict)




    if (action == 'refresh') {
        if (param) {
          // see if i have to do additional cleaning e.g states
          if (param.clearGeoNames) onSt2(null)
          if (param.clearIntelligently) {
            // first clear search string if any
            // then (if search string was empty - clear geo restrictions)
            //if (false) {
            if ($$.queryText && $$.queryText.trim() != '' ) { //|| $$.queryGrayed) {
              // gray the text out so it does not participate
              $$change('queryText', '') //$$change('queryGrayed',true)
            } else if ($$.geoName) {
              // restore 'all states'
              onSt2(null)
            }
            
          }
        }
        handleRefresh() 
        return
            
    } else if (action == '$$save') {
      $$save();
      return;

    } else if (action == '$$length') {
      return $$length();

    } else if (action == '$$back') {
      $$restore();
      return;
      
    } else if (action == 'st2change') {
      onSt2(param)
      return;

    } else if (action == 'offHelpMode'){
      $$change('helpMode', false)
    } else if (action == 'actor') {
      const cb = param // a castrated front end bucket

      // optimistically : 
      if ($$.keyCb && cb == null) { // erase keyCb via refresh
        handleRefresh()
        return

      } else if ($$.keyCb && cb.nm == $$.keyCb.nm) {
        // don't do it here - it is kinde misleading - 
        //handleRefresh()
        //return 
        //instead:
      } else {

        // sesign note [optimistically cb vs pessimistically]: change selected actor and highlight him in items
        // part of it do here part on receiving results
        $$change('keyCb',cb)
        // const h = Search_ToArray(cb.nm)
        // setHiTextArr(h)
        // ACTUALLy - do it on success.

        // Already!! indicate change in duality
        $$change('queryGrayed',true) // see design note [actor/search duality]
      }
   
      if (false) {
        // design note [why not to keep copy of refresh on the cient]
        // ... items here are aready polluted by previous [potenitally searches etc - I have to go to the backend for the source 
        // ... for now ]
        const keyArticles = ActorFilter_Front(cb.allNamesOf(), $$.items)
        $$change('items', keyArticles) //setItems(keyArticles)

      } else {
        // design note [keyActors]
        // the best thing is NOT to go the backend - do it here.. but i need some dicts .. do it later
        // the problem is that displayed actors may be absolete already - we may need to reload the actors pane? 
        // ACTUALLY filter should be our default filter so that backend can reload it if necessary??
        
        FetchActor({cb:cb, raison:'keyActor'} )


        // //const xxx = 
        // const currentFilter = getFilterJson()
        // Get_Elrais({ 
        //   filter:currentFilter, options:$$.options, action:{fn:'keyActors', keyActors:cb.allNamesOf()}
        //   , fnIsWorking:isWorkingAnim, dtCacheClient:$$.dtCache,  udCallBack:udCallBack, raison: 'keyActor'
        // }).then((result) => {

         
        //   if (result.error) {
        //     if (! dealWithBackError(result)) return
        //   }
            
        //   // see sesign note [optimistically cb vs pessimistically]
        //   //$$change('keyCb',cb)
        //   // if (OldWayHighlighting) {
        //   //   const h = Search_ToArray(cb.nm)
        //   //   setHiTextArr(h)
        //   // }
        

        //   const{eimList, cbList} = result.data
        //   if (eimList == null) {
        //     // nochange i eims
        //   } else {
        //     // actors should be highlighted as well
        //     const eimListWithWeed = WeedTheItems(cb.nm, eimList) 
        //     for (const eimWeeded of eimListWithWeed) eimWeeded.weed.highlight(0)

        //     const NoSearch = null  // for actor??
        //     setItemsWrapperPlus($$.activeSort, $$.siteMode, eimListWithWeed, NoSearch, "actor action");
        //   }

        //   if (cbList == null) {
        //     // nochange i actors
        //   } else {
        //     $$change('actors',CbItem.createCbList(cbList));
        //   }
          
        // })
        //   .catch((error) => {
        //     AlertMessage(error.message); return;
        // });
      }

    } else if (action == 'item') {
      AlertMessage(param.title)

    } else if (action == 'query') {
      AlertMessage(param)

    }
}

async function FetchActor({cb = null, raison = null}) {
  const currentFilter = getFilterJson()
  Get_Elrais({ 
    filter:currentFilter, options:$$.options, action:{fn:'keyActors', keyActors:cb.allNamesOf()}
    , fnIsWorking:isWorkingAnim, dtCacheClient:$$.dtCache, userLabel: $$.userLabel, udCallBack:udCallBack, raison: raison
  }).then((result) => {

   
    if (result.error) {
      if (! dealWithBackError(result)) return
    }
      
    // see sesign note [optimistically cb vs pessimistically]
    //$$change('keyCb',cb)
    // if (OldWayHighlighting) {
    //   const h = Search_ToArray(cb.nm)
    //   setHiTextArr(h)
    // }
  

    const{eimList, cbList} = result.data
    if (eimList == null) {
      // nochange i eims
    } else {
      // actors should be highlighted as well
      const eimListWithWeed = WeedTheItems(cb.nm, eimList) 
      for (const eimWeeded of eimListWithWeed) eimWeeded.weed.highlight(0)

      const NoSearch = null  // for actor??
      setItemsWrapperPlus($$.activeSort, $$.siteMode, eimListWithWeed, NoSearch, "actor action");
    }

    if (cbList == null) {
      // nochange i actors
    } else {
      $$change('actors',CbItem.createCbList(cbList));
    }
    
  })
    .catch((error) => {
      AlertMessage(error.message); return;
  });
}



function onSt2(geoName) {
  // check that the geoname is legit - ie in
  const x = St2s_by_Fullname((geoName))
  if (x === undefined) {
    AlertMessage(geoName + ' cannot be selected; only U.S. states can be restricted.', true)
    return;
  }

  // if no change then noop
  if($$.geoName == geoName) return

  // see design note [ui changes have to be  passed to queries - but how to restore their previous values?]
  $$change('geoName', geoName) //setSt2(st2)
  // -- that shou

  // if st change happened while keyActor then just retrieve the actor with new st2
  if ($$.keyCb != null) {
    FetchActor({cb:$$.keyCb, raison:null})
    return // not waiting for the back respnse
  }
  
  let textSearch = $$.queryText 
  if (textSearch.trim() == '') {
    // refill the items with refresh
    fetchData({sort:$$.activeSort, siteMode:$$.siteMode, udCallBack:udCallBack, raison: 'onSt2'});
  } else {
    const isSemantic = isInSemanticMode($$.siteMode) //!$$.siteMode.has('searchTextualImplied')
    
    doSearch(textSearch, isSemantic) // even if no search  text
  }
  //handleEnter()

  return

}

// function handleEnter(e = null) {
//   const textSearch = $$.queryText.trim()
//   // notice that filter is null - see design note [..] in the back server
//   if (textSearch.trim() == '') {
//     // refill the items with refresh
//     handleRefresh()
//   } else {
//     const isSemantic = !$$.siteMode.has('searchTextualImplied')
//     handleSearch(e, isSemantic)
//   }
//   return
// }

  const hiItems = $$.items // see items wrapper

  // // if items are empty the right pane resizes crazy
  // // so put something there ??
  // if (false && hiItems.length == 0) hiItems.push({
  //   link:null, title:"ssss"}
  // ) 

  //let SortIconTag = activeSort == 'date' ? 'FilterListIcon' : 'LowPriorityIcon'


  function Dbg2Event(xxx) {
    alert(xxx)
  }

  // ------------------- CmbCombined -----------------
  return (




  <div id="CmbDiv" style={{display: "flex"
    , cursor: $$.isWorking ? 'wait' : 'default'
    , flexDirection:"column"
    , flexWrap: "nowrap" // see another nowrap below - i am not sure how they interact .. but will do for nao
    //, width:"100%"
    //, width:"auto"
    , heightXXX: "100vh", height: $$.siteMode.has('fullMode') ? "100vh" : "auto"
    
    , backgroundColor: "white" // to overwite parent page ..
    //, padding:"10px 20px 0px 20px"
    
    }}>
    
    {/* Logo  & beta */}
    <div style={{
        //border:"1px solid green",
        }}>
      <h1 style={{ display:"inline-block", //so that text that follow will be on the same line
        
        
        // !!! I decided to make titel flash with upper left boundaries to the page - like i n realclear
        //margin: '5px 5px 5px 10px',   // 10 px should be EXACTLY like in the left margin in the left paine
      
      
        // textAlign: 'center'     // centers the text
      }} /* i need a span to limit nicely colored background */
      > <Tooltip title={ <DbgMore htmlKey='logo' isLong={$$.helpMode} udCallBack={udCallBack}/> } 
          enterDelay={$$.helpMode ? 0 : 2000} leaveDelay={200}
        > {/* interactive atribute seemingly is not necessary?*/} 
         

        <a href="" onClick={
          (e) => {e.preventDefault(); // otherwise just reload the page loosing states? .. maybe i should do that?
            handleRefresh();}}
        style={{ 
        color: "white", 
        fontSize:"20px",
        fontWeight: "600",
        fontStyle:"italic",
        
        backgroundColor: Angry_Color, //"#5F1202" 
        //color: Angry_Color,
        padding: '5px',  // Optional: To give a bit of space around the text
        //margin: '5px 5px 5px 5px'
        }}>Angry Politics Daily
        </a>
        </Tooltip>

        {/* ///////////////invisible//////////////// */}
        <DbgDialog
          isOpen={$$.dbgDialogOpen} onClose={() => $$change('dbgDialogOpen',false) }
          onSubmit={handleDbgDialogSubmit} /> 

        <span className="beta-at-header" style={{opacity:0.5}}>beta</span>
        {/* feedback link */}
        <a href="#" onClick={handleSendFeedback}
          className="beta-at-header send-feedback" style={style_header_link} 
        //   {{ 
        //   fontSizeZZZ:"small",
        //   textDecoration: "underline",
        //   marginLeft: "10px"
        // }}
        >Send Feedback</a>

        <FeedbackDialog isOpen={$$.sendFeedbackDialogOpen} onClose={() => $$change('sendFeedbackDialogOpen',false) }
          onSubmitFeedback={onSubmitFeedback} />

        {/* variant="contained" disableElevation> */} 
        {/* notice that it is LONG NOT in help mode!!! */}
        <Tooltip title={<DbgMore htmlKey='help_itself'  isLong={$$.helpMode} udCallBack={udCallBack}
          htmlShort={!$$.helpMode? 'Press to Enter Help Mode' : 'Press to Exit Help Mode' }
          noCancel={false}
          />} 
          enterDelay={$$.helpMode ? 0 : 50} leaveDelay={200} 
        >

        <div style={{display:'inline'
                , backgroundColor:$$.helpMode ? HelpMode_Color : 'revert'
                //, marginLeft: '10px'
                //, border :  $$.helpMode ? '2px solid ' + HelpMode_Color : 'revert'
                //, color: $$.helpMode ? HelpMode_Color : 'revert'
                }}
              >

            <a className="beta-at-header send-feedback" style={style_header_link} 
              
            
              href="#" onClick={()=>{$$change('helpMode', !$$.helpMode)}}>

  

                {$$.helpMode ? 'In Help Mode' : 'Help'}

            </a>
            
        </div>   

        </Tooltip>

      </h1>


      {/* ai addisted message */}
      <div className="beta-at-header"
          style={{
            //border:"1px solid green",

            // these pertains to text inside
            display: "flex",
            alignItems: "center", // centers vertically in the flex container
            justifyContent: "center", // centers horizontally in the flex container
    

            // this position the div itself to the right of it's container and takes up all the vertical space
            height : "20px"
          , float: "right"

          , fontSize:"12px"
          , color: Angry_Color
          , opacity: 0.7

          , marginRight:"10px"

          //, border:'1px red solid' // debugging

          , borderRadius: '10px', background: '#eae3e4', padding: '0px', 
          margin: "0px 10px 0px 10px"
        }}>
          <MenuUniSt2 onSelect={onSt2} geoName={$$.geoName} isDisabled={isInSemanticMode($$.siteMode)}/>
          &nbsp;
          Ai Automated
      </div>

      {/* debugging controls */}
      { (! $$.siteMode.has('dbgMode') ? null :
      <div id="explo" style={{fontSize:"12px", margin:"7px", whiteSpace: "nowrap"}}>

        
          
          <span>
            <button onClick={handleRefresh}>Rfrsh</button>
            {/* <button onClick={handleSort}>Srt</button> */}
            <button onClick={handleFilter}>Flt</button>

            <button onClick={()=>handleExp('tpage')}>@Exp</button>
            <button onClick={()=>handleExp('varia')}>@Varia</button>
            &nbsp;
            <button onClick={()=>onEmset({action:'emsetDeadend'})}>@Dead&uarr;</button>
            <button onClick={()=>onEmset({action:'emsetDefine'})}>@Soft&uarr;</button>
            <button onClick={()=>onEmset({action:'emsetOp', op:'build'})}>@Hard&uarr;</button>
            &nbsp;

            <button onClick={()=>onEmset({action:'emsetOp'})}>@mOP&uarr;</button>
            <button onClick={()=>handleExp2({actionFn:'snapLoad'})}>@s&uarr;</button>
            <button onClick={()=>handleExp2({actionFn:'snapStore'})}>@s&darr;</button>
            <button onClick={()=>{BlobDisplay($$.actors, blobWindowRef)}}>@a</button>
            <button onClick={()=>{BlobDisplay(
              $$.items.filter((item,index)=>{return ($$.selectItemIndex.has(index) ? true : false)})
              , blobWindowRef)}}>@i</button>

            {/* <button onClick={() => setHelpMode(!helpMode)}>
              {helpMode ? 'Exit Help Mode' : 'Enter Help Mode'}
            </button> */}
            <button onClick={() => $$change('helpMode', !$$.helpMode)}>
              {$$.helpMode ? 'Exit Help Mode' : 'Enter Help Mode'}
            </button>
          </span>
          
  
          <textarea
            rows={1}
            columns={20}
            name="parametersJSON" 
            ref={filterInputRef}
            
            style={{ 
            width: "100%",
            
            }}
            // value =  {JSON.stringify(filter)} 

              //         {
              // JSON.stringify(     {score:{$gte:0.5},  dt:{$gt:new Date("2023-10-01")}}
              // )} 
          >
            {/* i put it here, asa child. becaue i do not want to write handling procedure */}
            {
              JSON.stringify($$.filter)
            } 

          </textarea>
          <br/>&nbsp;<span>{[...$$.siteMode].join(', ') + '; ' + $$.activeSort}</span>
      </div>
    
      )}
    </div>


    <div id="cmbBothPanesDiv" style={{ 
      display: "flex", flexDirection:"row"
      //, widthZZZ:"100%"
      , height: "100%" 
      , padding:"10px 20px 0px 20px" // rita's "rcp/nyt" padding - 20 px from every side ..

      // Indicating helpMode
          //, backgroundColor: $$.helpMode?"#EDF3F6":"white" //
          , border :  $$.helpMode ? '2px solid ' + HelpMode_Color : 'revert' 
          , margin :  $$.helpMode ? '10px' : 'none' 
          
      }}>
      
      {/* Left Pane */}
      { ! $$.siteMode.has('fullMode') ? null
        :
      <div id="cmbLeftPane" 
        style={{
          border: Left_Pane_Border,
          minWidth: Left_Pane_Width, width: Left_Pane_Width,

              // 3 lines added
              flexGrow: 1, 
              display: "flex",
              flexDirection: "column",

              //alignSelf: "start", //makes 
              flex:"none",
        
          padding: "8px",
          paddingTop:PainHeaders_VertDelta,
          margin:"0px 0px 10px 0px", //no top; right margin is 0 to be closer to the right pane
          overflow:"auto",
          boxSizing: "border-box",

        }}>
        

        <div style={{
          // display: 'flex', flexDirection: 'row', 
          // alignItems: 'top',
          height: PainHeaders_Height
          , border: PainHeaders_Border
          , boxSizing: "border-box"
          , flex:"none"
          , verticalAlign:"middle"
          }}> 
            {/* blah blah left  */}
            {/* <input type="text" disabled value="Mentioned:"/> */}
            <Tooltip
              title={ 
                <DbgMore htmlKey='mentioned' isLong={$$.helpMode} udCallBack={udCallBack}
                   htmlShort={'Major mentions in articles'}
                />
              }
            >
              <a href="#" style={{backgroundColor:$$.helpMode ? HelpMode_Color : 'revert'}}>Mentioned:</a>
            </Tooltip>
            
            
        </div>


        {/* <h3 id="cmbLeftPane_header"  style={{marginTop:"1px"}}>
          Mentioned:
        </h3>  */}

        {!$$.siteMode.has('dbgMode') ? null 
          : <FeatureList features={$$.features}  keyCb={null} udCallBack={udCallBack} siteMode={$$.siteMode} />}

        <ActorList actors={$$.actors}  keyCb={$$.keyCb} udCallBack={udCallBack} siteMode={$$.siteMode} />
        
        
      </div> 
      } {/* of left pane */}

      {/* Right Pane */}
      <div id="cmbRightPane" style={{ /* width: "100%", */
              border: Right_Pane_Border,
              flexGrow: 1, /* i.e. take the rest of space ? */
              display: "flex",
              flexDirection: "column",

              
                        
              alignSelf: "start", //!!!!!! i.e what???

              
              //padding: "0px 0px 10px 0px", 
              //margin:"0px 0px 10px 0px",
              paddingTop:PainHeaders_VertDelta,
              overflow:"auto",
              boxSizing: "border-box"
      
              }}>

        {/* Search strip, with input and variety of buttons */}
        <div id="queryDiv" style={{ display: 'flex', flexDirection: 'row', 
          alignItems: 'center' 
          , height: PainHeaders_Height
          , border:PainHeaders_Border//1px solid magenta'
          //, border: '1px solid magenta'
          , marginLeft: SearchStrip_LefMargin // aligning XXX

          // exper
          , boxSizing: "border-box"

          //, overflowX: 'auto', // TestING to see when scrollbar appears
        }}>
    
          {/* start / container for input and "x" */}
          <BackButton udCallBack={udCallBack} isWorking={$$.isWorking} histLen={stateHistory.length} />
            {/* isDisabled={$$.isWorking || (stateHistory.length == 0)} /> */}

          <div className={$$.isWorking? 'animated' : null}
            style={{flexGrow: 1, display:"flex", alignItems:"center"
            , border: "1px solid #ccc"
            , borderBottom: ($$.isWorking? "2px solid #ccc" : "1px solid #ccc") // making botton slightly more noticable on animation
            , backgroundColor: ($$.queryGrayed ? '#E5E4E2' : 'initial')

            // exper
            , boxSizing: "border-box"
          }}> 

            <input spellCheck
                type="text"
                name="queryArea" 
                placeholder="search..."
                className={$$.isWorking? 'animated' : null}
                style={{ 
                    flexGrow: 2,       // allows the input to take the available space
                    //flexBasis: '50%', // Adjust as needed
                    boxSizing: "border-box",
                    outline: "none",
                    border: "0px solid #ccc", // so that it does not interfer with containe'rs
                    
                    //marginRight: '10px',  // adds a small space between input and button
                    backgroundColor: ($$.queryGrayed ? '#E5E4E2' : 'initial'),
                    color: ($$.queryGrayed ? '#9d9c9c' : 'initial'), // much grayer color, almost inviz
            
                    //borderBottom: "1px solid original" // this will be animated
                  }} 
                onKeyDown={(e)=>{

                  if (e.key == 'Enter') {
                    //AlertMessage('13 !!!')
                    //const textSearch = queryElRef.current.value
                    const textSearch = $$.queryText.trim()
                    // notice that filter is null - see design note [..] in the back server
                    if (textSearch.trim() == '') {
                      // refill the items with refresh
                      handleRefresh()
                    } else {
                      const isSemantic = isInSemanticMode($$.siteMode) // !$$.siteMode.has('searchTextualImplied')
                      handleSearch(e, isSemantic)
                    }
                  }

                }}


                onChange={(e)=>{
                  //const text = queryElRef.current.value;
                  const text = e.target.value;
                  $$change('queryText',text); // Update state with input value
                  
                  if (text.trim() == '') {
                    // if ($$.siteMode.has('searchSemantic')) { // remove mode
                    //   //changeSiteMode('searchSemantic')
                    //   changeSiteMode_Atomic([{mode:'searchSemantic', op:'flip'}])
                    //   handleRefresh()
                    // } else if ($$.siteMode.has('searchTextual')) { // remove mode
                    //   //changeSiteMode('searchTextual')
                    //   changeSiteMode_Atomic([{mode:'searchTextual', op:'flip'}])
                    //   handleRefresh()
                    // }
                    // simpler: remove search modes
                    changeSiteMode_Atomic([
                      {mode:'searchSemantic', op:'delete'},
                      {mode:'searchTextual', op:'delete'}
                    ])
                    
                  }
                }}
                onClick={(e)=>{$$change('queryGrayed',false)}} // see design note [actor/search duality]
                //ref={queryElRef}
                value={$$.queryText} // Bind input value to state
            />

            {/* the "x" */}
            <div class="clear-button" onClick={(e)=>{
              //queryElRef.current.value = ''; // erase text
              $$change('queryText','')
              handleRefresh(); // refill item list
            }}
              style={{ display:"inline",
              //backgroundColor: (queryGrayed ? '#E5E4E2' : 'initial'),
                //flex:1, margin: "-25px", 
                margin: "0px 5px 0px 5px", // so that 'x' is not on the very right
              cursor: "pointer",  color: "black"
              
              // exper
            , boxSizing: "border-box"
             }}
            >
              {/* an attempt to make  x disappear if no text .. {queryElRef.current.value.trim() == ''? null : <span>&times;</span>} */}
              &times;
            </div>

          </div>
          {/* - end / container for input and "x" */}

          {/* i need a little bit of space here - before buttons */}
          &nbsp;

          {/* two search buttons */}
          <div style={{
            //border: "1px solid navy",
            backgroundColor:"rgb(245 238 237)", // - find the select item color ..."lightgrey"
            flexShrink: 0, // telling the mobile browser not tu put them vertically
            }}>
            <Tooltip 
              // title={isInSemanticMode($$.siteMode) ? "Press for Textual search" : "Textual search"}
              title={ <DbgMore htmlKey='textual_search' isLong={$$.helpMode} udCallBack={udCallBack}
                 htmlShort={isInSemanticMode($$.siteMode) ? "Press for Textual search" : "Textual search"} 
              />}
              
            >
              <IconButton className={$$.siteMode.has('searchTextualImplied') ? 'buttonActiveStyle' : 'buttonIactiveStyle'}
                // style={{ color: Angry_Color, 
                // //opacity: 0.9, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)' 
                // }}
                style = {{...style_angry_control,
                  ...($$.siteMode.has('searchTextualImplied') ? style_angry_control_ActiveStyle : style_angry_control_InactiveStyle),
                  ...{color: Angry_Color, margin:'4px'},
                }}
              ><SearchIcon style={{ fontSize: SearchStripIcon_Size}}
                onClick={(e)=>{
                  $$change('activeSort', 'default') // reverting to default???
                  //changeSiteMode('searchTextualImplied','add') // will be done on async finish, see design note [async calls and set* useState()]
                  if (handleSearch(e,false)) // change graying only on success 
                    $$change('queryGrayed',false)}} id="searchButton">@t</SearchIcon></IconButton>
            </Tooltip>
            <Tooltip 
              // title={isInSemanticMode($$.siteMode) ? "Semantic search" : "Press for Semantic search"}>
              title={ <DbgMore htmlKey='semantic_search' isLong={$$.helpMode} udCallBack={udCallBack}
                htmlShort={isInSemanticMode($$.siteMode) ? "Semantic search" : "Press for Semantic search"} /> }
            >
              <IconButton className={$$.siteMode.has('searchTextualImplied') ? 'buttonInactiveStyle' : 'buttonActiveStyle'}
              //   style={{ color: 'navy', 
              //   //opacity: 0.9, boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)' 
              // }}
              style = {{...style_angry_control,
                ...($$.siteMode.has('searchTextualImplied') ? style_angry_control_InactiveStyle : style_angry_control_ActiveStyle),
                ...{color: 'navy', margin:'4px'},
              }}
              ><TroubleshootIcon  style={{ fontSize: SearchStripIcon_Size}}
                onClick={(e)=>{
                  $$change('activeSort','default') // reverting to default???
                  //changeSiteMode('searchTextualImplied','delete') // will be done on async finish, see design note [async calls and set* useState()]
                  if (handleSearch(e,true)) // change graying only on success 
                    $$change('queryGrayed',false)}} id="queryButton">@s</TroubleshootIcon></IconButton>
            </Tooltip>
          </div>

          {/* sort button code BEGIN */}

          <div style={{ position: "relative" 
            //, border:'1px solid green'
          }}>
            <SortMenu2 activeSort={$$.activeSort} isInHelpMode={$$.helpMode} udCallBack={udCallBack}/>
          </div>


          {/* sort button code END */}


          <Tooltip 
            title = { <DbgMore htmlKey='advanced_mode' isLong={$$.helpMode} udCallBack={udCallBack}
              htmlShort={!$$.siteMode.has('fullMode') ?'Switch to Advanced Mode':'Switch to Basic Mode'} 
            />}
          >
            <IconButton 

              style = {{...style_angry_control,...{color: Angry_Color}}}

              onClick={(e)=>{ 
                //changeSiteMode('fullMode')
                changeSiteMode_Atomic([{mode:'fullMode', op:'flip'}])
              }}>

              {$$.siteMode.has('fullMode') ? 
                          <KeyboardDoubleArrowLeftIcon fontSize="large" /> 
                        : <KeyboardDoubleArrowRightIcon fontSize="large" />}
            </IconButton>
          </Tooltip>

        </div> 
        {/* end of Search box */}

 

        {/* Advisory message */}
        {$$.advisoryMessage == null?null: <div className ="advisoryMessage" 
          styleXXX={{
          //flex:"none"
          //width:"auto"
          display: "inline-block" // only to the extent of the text
          //, border:"1px solid brown"
          , marginLeft:"0px"
          , fontWeight:"600"
          , color:Angry_Color
          //, backgroundColor:"lightgrey"
          }}>{$$.advisoryMessage}</div>}

        {/* Please wait */}
        {hiItems.length == 0 ? <div>
          <span> 
            
            {/* please wait - ONLY for initial stage - (i.e. no search or keyactor)
            the real wating is handel by isWaiting now */}
           {/* {
            $$.siteMode.has('searchTextual') || $$.siteMode.has('searchSemantic') || $$.keyCb != null  
            ? 'No Data' + ($$.st2 ? ' in ' + $$.st2 : '') :
            $$.isWorking ? 'Please Wait' : 'no data'} */}

              { $$.isWorking ? 'Please Wait' : (
                  <>
                    {'No data available'}
                    {/* {$$.st2 && (<> {' in '} <strong>{St2s_by_Fullname($$.st2)}</strong> </>)} */}
                    {$$.geoName && (<> {' for '} <strong>{$$.geoName}</strong> </>)}
                  </>)
              }
       
           {/* <ClearButton udCallBack={udCallBack} removeGeoSelection={$$.geoName != null}/> */}
           {/* no items may be caused by restrictive geonames */}
           <ClearButton udCallBack={udCallBack} param={
              {
                clearIntelligently:true,
                clearGeoNames:false
              }}
           />
          </span>

        </div> : null}

        {/* Item list in 2 modes */}
     
        {false || ! $$.siteMode.has('fullMode') ?
          <ItemList items = {hiItems} actors = {$$.actors} keyCb={$$.keyCb} 
            ud={$$.universalDict} udCallBack={udCallBack} 
            siteMode={$$.siteMode}
            // allCompData = {allCompData} setAllCompData = {setAllCompData}
            selectItemIndex = {$$.selectItemIndex} handleSelectItem = {handleSelectItem} 
            expandItemIndex = {$$.expandItemIndex} handleExpandItem = {handleExpandItem}
            handleSearch={handleSearch}
          />
          :
          <ItemListFull items = {hiItems} actors = {$$.actors} keyCb={$$.keyCb}
            ud={$$.universalDict} udCallBack={udCallBack} 
            siteMode={$$.siteMode}
            // allCompData = {allCompData} setAllCompData = {setAllCompData}
            selectItemIndex = {$$.selectItemIndex} handleSelectItem = {handleSelectItem} 
            expandItemIndex = {$$.expandItemIndex} handleExpandItem = {handleExpandItem}
            handleSearch={handleSearch}
          />
        }
     
        

      </div> {/* of right pane */}





    </div>

    
  </div>
  );
}
  

function cb_titleOf(cb, keyCb) {
  const struct = HiText(cb.title, keyCb.nm)
  return struct.replacedText
}

/* function cb_polconQuoteOf(cb, keyCb) {

  const struct = HiText(cb.quote, keyCb.nm)
  return struct.replacedText
 
  try {
    const rs = JSON.parse(cb.response)
    if (rs.polcon && rs.polcon.explication && rs.polcon.explication.quote) {
      return rs.polcon.explication.quote
    } else {
      return 'no quote'
    }
  } catch (error) {
    return error.message
  } 
} */

function cb_dateStringOf(cb) {
  //const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const dateOptions = { month: 'short', day: 'numeric' };
  const dateStr = new Date(cb.dt).toLocaleDateString(undefined, dateOptions);
  return dateStr
}





function cb_scoregOf(cb) {
  return Number2String(cb.score) // == null ? 0 : cb.score.toFixed(2)
}

function cb_searchScoreOf(eim) {
  let res = '..' + ( eim.bSort == undefined? 'U' : eim.bSort) // i.e. original bucket sort order
  
  let whatScoreToDisplay = eim.searchScore // on the beck see design note[cs is the search score for vector search]
  if (eim.csScore) whatScoreToDisplay = eim.csScore
  
  if (whatScoreToDisplay == null) return res;
  return res + '..' + Number2String(whatScoreToDisplay) //.toFixed(2) 
}


//===============================================

let outServerUrl = 'http://localhost:5000'


function crackFparams(filter) {
  // by the way - removes from filter for mongo my params that are errors for mongo
  let fParams = null

  if (filter == null) {//damn it
    return null //filter = getFilterJson()
  }

  if (filter.fParams === undefined) { 
    //
  } else {
    fParams = filter.fParams
    delete filter.fParams
  }
  return fParams
}
/////////////////////////////

async function Get_Generic({postData, fnOnFinally = null
    , fullEntryPoint = '/Generic'}) {
    // should return {error:.., data:...}
  let result = {error:null, data: null}


  outServerUrl = ScratchBackUrl
  
  try { // if (fnOnFinally) fnOnFinally(null);

    const response = await axios.post(outServerUrl + fullEntryPoint, postData);
    // - axios.get might trow an error from the back end by the caller should handle it

    result =  response.data // this data is NOT result.data

    

  } catch(e) {
      result = {error:{code:Utils_Front.ErrorCode.Network_Error, message:e.message}, data:null}
    
  } finally {
      if (fnOnFinally) fnOnFinally(result)
  }

  return result // / i.e. response."data" is response == {error:{}. data:{}}

}

async function Get_Elrais({filter, options, action=null, fnIsWorking
     , entryPoint = 'Electrai_LookupMany', dtCacheClient, userLabel = null, udCallBack=null, raison = null}) {
  //console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
  // try {

  outServerUrl = ScratchBackUrl

  // attach params to action
  const filterCpy = {...filter} // not messing wth the stat .. which dispayed, by the way
  const fParams = crackFparams(filterCpy) // my instruction to scrathback
  action.fParams = fParams

  // all fields for now .. 
  const postData = {action:action, filter: filterCpy, fields: {}, options: options
    , sort: null // see design note [back will use it's sorting]
    , dtCacheClient:dtCacheClient //see design note [dtCache is handled entirely here]
    , rain: {theme: 'trace', userLabel: userLabel, raison: raison} // - so tha the back knows who and why
    //, fParams : fParams
  }

  let dtCacheFromServer = null
  try { fnIsWorking(true);

    const response = await axios.post(outServerUrl + '/outServerArt/' + entryPoint, postData);
    // - axios.get might trow an error from the back end by the caller should handle it

    const result =  response.data 


    if (result.data && result.data.dtCache) // design note [dtCache is handled entirely here]
      dtCacheFromServer = Utils_Front.fixedDateEncodingIf2(result.data.dtCache)

    // Save the current state so that it can be restored
    if (udCallBack) udCallBack({action:"$$save", param:null})

    return result // / i.e. response."data" is response == {error:{}. data:{}}
  } catch(e) {
    return {error:{code:Utils_Front.ErrorCode.Network_Error, message:e.message}, data:null}
  } finally {
    fnIsWorking(false,dtCacheFromServer)
  }

};

async function Get_Exp({filter, options, action=null, fnIsWorking, dtCacheClient, userLabel, udCallBack=null, raison=null}) {
  
  return await Get_Elrais({filter:filter, options:options, action: action, fnIsWorking:fnIsWorking
    , entryPoint : 'Electrai_Exp', dtCacheClient: dtCacheClient, userLabel: userLabel, udCallBack:udCallBack, raison:raison}) 

  // outServerUrl = ScratchBackUrl

  // // attach params to action
  // const filterCpy = {...filter} // not messing wth the stat .. which dispayed, by the way
  // const fParams = crackFparams(filterCpy) // my instruction to scrathback
  // action.fParams = fParams

  // const postData = {action:action, filter: filterCpy, fields: {}, options: options
  //   , sort: null // see design note [back will use it's sorting]
  // }

  // try { fnIsWorking(true);
  //   const response = await axios.post(outServerUrl + '/outServerArt/Electrai_Exp', postData);
  //     // - axios.get might trow an error from the back end by the caller should handle it

  //   return response.data // i.e. returms {error:{}. data:P{}}
  
  // } finally {
  //   fnIsWorking(false)
  // }

};
////////////////////////////////////////////////
 
function ActorFilter_Front(keyActors, eimList //As List(Of ElectraiTem)
,  keyActorThreshhold  = 0.2) {//As List(Of ElectraiTem)
  // INEFFICTIENT!! design note [why not to keep copy of refresh on the cient]
  let keyArticles = [] //As New List(Of ElectraiTem)

  //Dim keyActor$ = "Ron Desantis" '"Chris Christie" '"Nikki Haley"

  //Dim keyActorTheshhold As Single = 0.8
  for (var i = 0; i < eimList.length; i++) {
    const eim = eimList[i]
    for (let keyActor in keyActors) {
      let actorImp = eim.actorsDict[keyActor]
      if (actorImp) {
        
        if (actorImp >= keyActorThreshhold) {
          eim.sort = actorImp * eim.score
          keyArticles.push(eim)
        }
      }
    } 
  }

  // now sort by imp*score
  keyArticles.sort(function(a, b) {
                    return - (a.sort - b.sort) //-a.sort.CompareTo(b.sort)
  })
          
  for (let i = 0; i < keyArticles.length; i ++) 
    keyArticles[i].bSort = i + 1 //!

  return keyArticles
}

/////////////////////////////// utils //////////////////////

/////////////////////////////// end of utils /////////////////



class CbItem { // just like class ElectraiTem 
  constructor() {
    this.nm = null
    this.syns = null //new Set()
    this.aggImp = 0
    this.tp = null
  }

  $$jsonFrom(jsonObj) {
    Object.keys(jsonObj).forEach(key => {
      this[key] = jsonObj[key];
    });
  }

  dump() {
    if (this.aggImp == null) return '???'

    let d = this.nm + ' ' + Number2String(this.aggImp) //toFixed(2) 
    if (this.syns) {
      
      d += ' ' + [...this.syns].join('; ')
    }
    return d;
  }

  static dumpList(cbList, sep = ' ;') {
    return cbList.map((cb)=>{return cb.dump()}).join(sep)
  }

  static dumpSet(cbSet) {
    const list = [...cbSet]
    return CbItem.dumpList(list)
  }

  static wordSetsCmp(aa, bb) {
    //
    // res.aOnly.size == 0 if ws1 <= ws2 .. ie. subset
    // res.bOnly.size == 0 if ws1 > ws2 .. superset


    const res = {aOnly: new Set(), bOnly: new Set(), overlap:new Set()}
    for (var a of aa) {
      if (bb.has(a)) {
        res.overlap.add(a)
      } else {
        res.aOnly.add(a)
      }
    }
    for (var b of bb) {
      if (! aa.has(b)) res.bOnly.add(a)
    }
    return res

  }

  static NameSetsCmp(aaa, bbb) {
    const res = {aasInBbs:new Set(), bbsInAas:new Set()}

    // consider only overlap of aaa and bbb
    for (var aa of aaa) {
      for (var bb of bbb) {
        var cmp = CbItem.wordSetsCmp(aa,bb)
        if (cmp.aOnly.size == 0) {
          res.aasInBbs.add(aa)
        }

        if (cmp.bOnly.size == 0) {
          res.bbsInAas.add(bb)
        }
      } 
    }
    //const nSmallerAas = 

    if (res.aasInBbs.size == 0 && res.bbsInAas.size == 0) {
      return 0 // i.e. aaa are unrelated
    } else if (res.aasInBbs.size == 0) {
      return 1
    } else if (res.bbsInAas.size == 0) {
      return 2
    }  else {//} if (res.aasInBbs.size > 0 && res.bbsInAas.size > 0) {
      // its a mess - now is "bigger"
      return 3
    }

  }


  // static nameSetsIntersect(aaa, bbb) {
  //   const res = new Set() 
  //   for (let aa of aaa) {
  //     if (thatNameSets.has(t)) res.add(t)
  //   }
  //   return res
  // }

  static Intersection(xx, yy) {
    const res = new Set() 
    for (let x of xx) {
      if (yy.has(x)) res.add(x)
    }
    return res
  }
  static Union(xx, yy) {
    const res = new Set() 
    for (let x of xx) if (!res.has(x)) res.add(x)
    for (let y of yy) if (!res.has(y)) res.add(y)
    
    return res
  }


  static CbMerge(cbList) {
    // returns new list
    
    let dbgDump = null

    // first accumulate dict of all words and cb's that have this word in their names
    const allCbSet = new Set(cbList) // all consumed will be removed from it
    // - consumed cd's will be removed from this set
    
    // keep merging till nothing happened
    while (true) { // the while here is for situation when a cb entry consumes smaller imps and acquires the new words from them??
      // rebuild cbList from allCbSet
    
      let nConsumed = 0


      cbList = []
      for(let cb of allCbSet) cbList.push(cb)
      cbList.sort(function(cb1,cb2) {return -(cb1.aggImp - cb2.aggImp)}) // bigger imps first
      dbgDump = CbItem.dumpList(cbList,'\n')

      const wordDict = {} // for every word assemble cb's that have this word in the name/syns
      for (var cb of cbList) {
        const ws = cb.allWordSetOf() //CbItem.WordSetOf(cb.nm)
        for (var w of ws) {
          let wdEntry = wordDict[w]
          if(wdEntry ===undefined) {
            wdEntry = {word:w,set:new Set()}
            wordDict[w] = wdEntry
          }
          wdEntry.set.add(cb)
        }
      }

      
      // --- from cb's with highest imps down 
      for (let i=0; i < cbList.length -1; i++) {
        const cb = cbList[i]

        if (cb.nm == 'DeSantis') {
          let x = 1
        }
        //  has to be so  // if (! allCbSet.has(cb) ) continue; // was consumed already
        const ws = cb.allWordSetOf() //CbItem.WordSetOf(cb.nm)

        // --- collect cb's (witj lower imps) that are lexigraphcally supersets
        let superCbs = allCbSet // - start with 'all', this way already consumed will not be considered // null
        for (let w of ws) {
          const wordCbSet = wordDict[w].set // that is where wordDict plays!!
          // unnecessary if (superCbs == null) superCbs = new Set(wordCbSet);
          //else 
          superCbs = CbItem.Intersection(superCbs,wordCbSet)
        }

        // --- and merge lowers with the current cb if necessary
        if (superCbs.size == 0) {
          const x = 1 // be very surprised - cb itself is intersected out??
        } else if (superCbs.size == 1) {
          // nothing to merge - only one
        } else {
          // sort
          //superCbs.sort(function(cb1,cb2) {return cb1.aggImp - cb2.aggImp})


          const mergeList  = [...superCbs]
          mergeList.sort(function(cb1,cb2) {return -(cb1.aggImp - cb2.aggImp)})
          // merge smaller imps to the biggest
          const mergeTarget = mergeList[0] 
          for (let k=1; k < mergeList.length; k ++) {
            const cb = mergeList[k]
            mergeTarget.consume(cb)
            allCbSet.delete(cb)
            nConsumed += 1
          }

          dbgDump = CbItem.dumpList(mergeList)
          const ddd = 2
        }

      } // cb loop
      if (nConsumed == 0) {
        break // of the while - because no work is done
      }
    } // of while



    // --- reassamble the result cb list
    var res=[]
    for (let cb of allCbSet) res.push(cb)
    res.sort(function(cb1,cb2) {return -(cb1.aggImp - cb2.aggImp)})

    dbgDump = CbItem.dumpList(res, '\n')

    return res;

  }

  static WordSetOf(str) { //{'yury', 'rapoport'}
    const res = new Set()
    const words = str.split(' ').filter((w)=>{return w != ' '})
    
    for (var w of words) res.add(w)
    return res
  }

  allWordSetOf() {
    const res = CbItem.WordSetOf(this.nm)
    // also pick up word from syns?!!
    if (this.syns) {
      for (var sy of this.syns) {
        res.add(CbItem.WordSetOf(sy))
      }
    }
    return res
  }

  allNamesOf() {
    const res = []; res.push(this.nm)
    // also syns?!!
    if (this.syns) {
      for (var sy of this.syns) {
        res.push(sy)
      }
    }
    return res
  }

  nameSetsOf = () => { //{{'yury', 'rapoport'},{'yury', 'o', 'rapoport'}}
    const res = new Set(); res.add(CbItem.WordSetOf(this.nm))
    if (this.syns) {
      for (var sy of this.syns) {
        res.add(CbItem.WordSetOf(sy))
      }
    }
    return res
  }

  isSimilarTo = (him) => { // unused .. 
    const mine = this.nameSetsOf() 
    const his = him.nameSetsOf() 

    const sanity = CbItem.NameSetsCmp(mine, mine)
    
    const c = CbItem.NameSetsCmp(mine, his)
    return c > 0

  }

  consume = (him) => {
    if (! this.syns) this.syns = new Set()
    this.syns.add(him.nm)
    if (him.syns) {
      for (let himSyn of him.syns) this.syns.add(himSyn)
    }

    const coefficient = him.eimIdxes.length / (this.eimIdxes.length + him.eimIdxes.length)
    for (var idx of him.eimIdxes) this.eimIdxes.push(idx)
    
    //todo a suspect!!!!!
    const hisContribution = coefficient * him.aggImp
    this.aggImp = this.aggImp + hisContribution - this.aggImp * hisContribution 
    return this
  }


  static createCbList(cbListRaw) {
    var cbList = []
    for (var i=0; i < cbListRaw.length; i++) {
      const cbItemRaw = cbListRaw[i]
      const cbi = CbItem.create(cbItemRaw)
      cbList.push(cbi)
    }
    return cbList
  }

  static create(cbItemRaw) {
    try {
    let cbi = new CbItem()
    //cbi = {...cbItemRaw}
    cbi.nm = cbItemRaw.nm
    cbi.aggImp = cbItemRaw.aggImp ?? null
    cbi.nScore = cbItemRaw.nScore ?? null
    if (cbItemRaw.eimIdxes) {
    cbi.eimIdxes = [...cbItemRaw.eimIdxes]
    } else { // case of features withou idxs computed ??
      cbi.eimIdxes = []
    }
    cbi.syns = null
    if(cbItemRaw.syns) {
      // see design note [syns is Set but HAS TO BE AN ARRAY in the Front] 
      if (Array.isArray(cbItemRaw.syns)) {
        cbi.syns = [...cbItemRaw.syns] // but if cbItemRaw.syns == {} it will blow because sets are not numerable .. https://stackoverflow.com/questions/51337811/why-are-set-objects-ignored-when-encountered-by-json-stringify
      }
    }
    return cbi
    } catch (error) {
      const x = 1
    }
  }

} // of class CbItem



function LessNavyButton({onClick, title = 'Clear', margin='' }) {
  return (
    <Tooltip title={title} enterDelay={1000} leaveDelay={200} >
    <IconButton style={{ 
        margin: margin,

        padding: 0, verticalAlign: 'middle'
        , border: '2px solid', marginRight: '10px' 
        // that keeps texxt div off to the righ tmore 
        }}
          onClick={onClick}
        >
      <ExpandLessIcon  
        style={{ fontSize: '16px', color: 'navy' }}
      />
    </IconButton>
  </Tooltip>

  )
}

function ExpandCollapseButton({EcIcon = ExpandLessIcon, 
  tip="Click to Collapse", onClick, index, ecHandleOp = "off"}) { // ExpanCdollapse
  return (
  <Tooltip title={tip} enterDelay={1000} leaveDelay={200} >
    <IconButton style={{ padding: 0, verticalAlign: 'middle'
        , border: '2px solid', marginRight: '10px' // that keeps texxt div off to the righ tmore 
        }}

        // onClick={(e)=>{
        //   onClick(e, index, ecHandleOp)
        // }}
        onClick={onClick}
      
        >
      <EcIcon  
        style={{ fontSize: '16px', color: 'navy' }}
      />
    </IconButton>
  </Tooltip>
  )
}


function ClearButton({udCallBack, action='refresh', param = null}) {
  return ( <>
          {/* Clear Button */}
          <Tooltip title="Clear" enterDelay={500} leaveDelay={200} >
          <IconButton style={{ marginLeft:"10px", padding: 0, verticalAlign: 'middle'
              , border: '2px solid', marginRight: '10px' // that keeps texxt div off to the righ tmore 
              }}
              onClick={(e)=>{
                  // just restore the default state
                  udCallBack({action:action, param:param})
                  return
              }}
          >
            <ClearIcon  
              style={{ fontSize: '16px', color: 'navy' }}
            />
          </IconButton>
        </Tooltip>
        </>
  )


}

function BackButton({udCallBack, isWorking=false, histLen=0}) {

// i should use browser back instead .. !!!!!!!!!!!!!!!!!!!!!!!
//https://stackoverflow.com/questions/36141470/disable-back-button-in-react-router-2
//https://stackoverflow.com/questions/39342195/intercept-handle-browsers-back-button-in-react-router
  
  const isInHelpMode = udCallBack(({action:"get", param:'$$'})).helpMode

  return ( <>
      {/* <Tooltip title={ histLen == 0 ? "Refresh Data" : "Go Back"} 
          enterDelay={500} leaveDelay={200} > */}

{/* //{!$$.helpMode? 'Press to Enter Help Mode' : 'Press to Exit Help Mode' } */}

      <Tooltip title={<DbgMore htmlKey='go_back'  isLong={isInHelpMode} udCallBack={udCallBack}
          htmlShort={ histLen == 0 ? "Refresh Data" : "Go Back"} 
          />} 
          enterDelay={isInHelpMode ? 0 : 500} leaveDelay={200}
      >
          


          <IconButton style={{ marginLeft:"0px", padding: 0, verticalAlign: 'middle'
              , border: '2px solid', marginRight: '10px' // that keeps texxt div off to the righ tmore 
              }}
              onClick={(e)=>{
                  // just restore the default state
                  udCallBack({action:"$$back", param:null})
                  return
              }}
          >
            {histLen == 0 //udCallBack({action:"$$length"}) == 0 
            ?
            <RefreshIcon 
              style={{ fontSize: '16px', color: isWorking? 'red' : 'navy' }}
            />
            :
            <ArrowBackIcon  
              style={{ fontSize: '16px', color: isWorking? 'red' : 'navy' }}
            />
            }

          </IconButton>
        </Tooltip>
    </>
  )
}

function ExpoHeader({siteMode, keyCb, ud, udCallBack}) {
  let expoHeader = null
  if (keyCb != null) { // actor mentions are displayed
    expoHeader = 'Mentioned for ' + keyCb.nm 
    //return KeyCbExpo({keyCb, ud, udCallBack})
  } else if (siteMode.has('searchSemantic')) {
    expoHeader = <div className ="advisoryMessage"  styleXXX={{color:'blue'}}>Semantic Search Results</div>
  } else {
    return null;
  }

  return ( 
    <div 
      style={style_expo_header}

      > {expoHeader}

        {/* Clear Button */}
        <ClearButton udCallBack={udCallBack}/>
        {/* <Tooltip title="Clear" enterDelay={500} leaveDelay={200} >
          <IconButton style={{ marginLeft:"10px", padding: 0, verticalAlign: 'middle'
              , border: '2px solid', marginRight: '10px' // that keeps texxt div off to the righ tmore 
              }}
              onClick={(e)=>{
                  // just restore the default state
                  udCallBack({action:"refresh", param:null})
                  return
              }}
          >
            <ClearIcon  
              style={{ fontSize: '16px', color: 'navy' }}
            />
          </IconButton>
        </Tooltip> */}


    </div> 
  )

}


// function KeyCbExpo({keyCb, ud, udCallBack}) {
//   return ( 
//     <>
//     { keyCb == null ? null : <div 
//       style={style_expo_header}

//       > Mentioned for {keyCb.nm} 
      
//       <ClearButton udCallBack={udCallBack} removeGeoSelection={false}/>
//           {/* <Tooltip title="Clear" enterDelay={500} leaveDelay={200} >
//             <IconButton style={{ marginLeft:"10px", padding: 0, verticalAlign: 'middle'
//                 , border: '2px solid', marginRight: '10px' // that keeps texxt div off to the righ tmore 
//                 }}
//                 onClick={(e)=>{udCallBack({action:"actor", param:null})}}>
//               <ClearIcon  
//                 style={{ fontSize: '16px', color: 'navy' }}
//               />
//             </IconButton>
//           </Tooltip> */}


//           {/* <Tooltip title="Clear Selection" enterDelay={1000} leaveDelay={200} >
//             <IconButton style={{ padding: 0, verticalAlign: 'middle'
//                 , border: '2px solid', marginRight: '10px' 
//                 // that keeps texxt div off to the righ tmore 
//                 }}
//                 onClick={(e)=>{udCallBack({action:"actor", param:null})}}
//                 >
//               <ExpandLessIcon  
//                 style={{ fontSize: '16px', color: 'navy' }}
//               />
//             </IconButton>
//           </Tooltip> */}
//           {/* <LessNavyButton title="Clear Selection" 
//             margin="0px 0px 0px 10px"
//             onClick={(e)=>{udCallBack({action:"actor", param:null})}}
//           /> */}

//       </div> }
//     </>
//   )
// }

function ItemList( {items, actors, keyCb, ud, udCallBack, siteMode
  // , allCompData, setAllCompData
  , selectItemIndex, handleSelectItem
  , expandItemIndex, handleExpandItem
  , handleSearch
}) {
const actorsDict = actorsToDict(actors) 

  const [visibleItems, setVisibleItems] = useState(9);
  const containerRef = useRef(null);
const buffer = 5




return (  
  <div id="itemsDiv"
    style={{ overflowY: 'none' , listStyleType: 'none', paddingLeft: 0 }} 
    ref={containerRef}
    > 
      {/* { !$$.siteMode.has('dbgMode') ? '' : <span>{visibleItems}</span> } */}
      
      <ExpoHeader siteMode={siteMode} keyCb={keyCb} ud={ud} udCallBack = {udCallBack} />

      { 
        items.slice(0, visibleItems).map((eim, index) => {

          let tempTitle = null
          let tempQuote = null
          let tempTxtIn = null
          if (OldWayHighlighting) {
           tempTitle = eim.title
           tempQuote = eim.quote
           tempTxtIn = null
          }


          return <ItemComp eim = {eim}
              index = {index} 
              normalBorder = "none"
              tempTitle = {tempTitle} tempQuote = {tempQuote} tempTxtIn = {tempTxtIn}
              selectItemIndex={selectItemIndex} handleSelectItem={handleSelectItem} 
              expandItemIndex={expandItemIndex} handleExpandItem={handleExpandItem}
              handleSearch={handleSearch}
              siteMode={siteMode}
              udCallBack = {udCallBack}
              />


      })}
     
     <div className="more-section" style={{display:"inline-block", "text-align":"center"}}>

      <br/>  {/* -- making sure it's separated from the list */}
     
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {/* silly way to move a little to the right */}
 
      <b> {/* bold of course */}
        
      {
        visibleItems >= items.length ? '': <a 
        onClick={(e)=>{ e.preventDefault();
          setVisibleItems(Math.min(2 * visibleItems, items.length))
        }
      }
        
        href="">More...</a>}
        <br/>
        <br/>
      </b>
    </div>
  </div>)
}

function DateComp({eim, siteMode}) {
  return (
    <span> 
      <span //onClick={(e)=>{udCallBack({action:"item", param:eim})}}
        style={{fontStyle:"italic", fontWeight:"650"}}> {cb_dateStringOf(eim)}</span> 

      {!siteMode.has('dbgMode')? null : // score only in dbg mode
        <span>
          <span style={{fontSize:"8px"}}> {cb_scoregOf(eim)}</span>
          <span style={{fontSize:"8px"}}> {cb_searchScoreOf(eim)}</span>
          {/* &nbsp; */}
        </span>
      } 

    </span>
  )
}
////////////////////////////////

function StatesComp({eim, prefix = '', udCallBack=null, isDisabledClick, isImped = true
  , isInHelpMode = false}) {
  return (
    
    <div style={{display:"inline", marginLeft:'0px', background:"lightgray"}}>
      <>
        {ustateDictToList(eim.ustateDict).map((entry, index)=>{ // St2_FullNameOf
          return <> { entry.imp < 0.7 ? null : 
            <small>
              {index == 0? prefix : ', ' }
              {/* &nbsp; */}
              {isImped? entry.imp.toString(): null} 
              { 
                  isDisabledClick? <span>&nbsp;{entry.nm}</span>
                    // <div style={{display:"inline"}}>{entry.nm}</div>
                :

                <Tooltip title={!isInHelpMode ? '' // tooltip ONLy in help mode
                  :
                  <DbgMore htmlKey='item_geo_click'  
                  isLong={true} udCallBack={udCallBack}
                  htmlShort={'Press to Restrict to State' }
                  />} 
                  // enterDelay={0} leaveDelay={200} 
                >
                  <a onClick={
                    (e)=>{
                      e.preventDefault();
                      e.stopPropagation()

                      if (isDisabledClick) return

                      if (udCallBack) {
                        udCallBack({action:'st2change', param:entry.nm})
                      }
                    }
                  }> {entry.nm} </a> 
                </Tooltip>
              }
            </small>
          }</>
        })}
      </>
    </div>
  )
}

/////////////////////////////
export function ItemComp({eim
  , index
  , normalBorder
  , tempTitle, tempQuote, tempTxtIn
  , selectItemIndex, handleSelectItem
  , expandItemIndex, handleExpandItem
  , handleSearch
  , siteMode
  , udCallBack = null
  , actors = null, actorsDict = null, keyCb = null}) {

  const isExpandedItem = expandItemIndex.has(index)
  // the last resort technique - very inefficient
  const isInHelpMode = udCallBack(({action:"get", param:'$$'})).helpMode

  let paragraphs = null
  if (OldWayHighlighting) {
    if (isExpandedItem && tempTxtIn == null) tempTxtIn = eim.txtIn
    paragraphs = HiGetParagraphs(tempTxtIn)
  } else {
    const weed = eim.weed
    if (weed) {
    tempTitle = weed.getTitleXprg().htmlOf()
    tempQuote = weed.getQuoteXprg().htmlOf()
    paragraphs = weed.getParagraphs()
    } else {
      tempTitle = eim.title
      tempQuote = eim.quote
      paragraphs = HiGetParagraphs(eim.txtIn)
    }
  }

  


  const buttonStyles = {
    padding: 0,
    verticalAlign: 'bottom',
    backgroundColor: 'transparent', // Transparent background by default
    border: '2px solid navy', // Border to create button appearance
    borderRadius: '50%', // Makes it circular
    cursor: 'pointer',
    transition: 'background-color 0.3s', // Transition for hover effect
    '&:hover': {
      backgroundColor: 'navy', // Change background color on hover
    },
  };

  const iconStyles = {
    color: 'navy', // Default icon color
    '&:hover': {
      color: 'red', // Change icon color on hover
    },
  };

  function domain2(url) {
    const u = new URL(url)
    const d3 = u.host
    let arr = d3.split('.')
    if (arr[0] == 'www') arr = arr.splice(1)
    return arr.join('.')

  }

  function complexExpansionHandling(e) {
    const isInDebugMode = siteMode.has('dbgMode')
    const isAlreadySelectedItem = selectItemIndex.has(index)
    const isAlreadyExpandedItem = expandItemIndex.has(index)
    const isCtrlKey = e.ctrlKey

    if (isInDebugMode && e.ctrlKey) {
      handleSelectItem(e, index, ['flip']); 
      //handleExpandItem(e, index, ['flip'] ); 

      // if (e.ctrlKey) {
      //   handleSelectItem(e, index, ops); 
      //   handleExpandItem(e, index, ops ); 
      // } else {
      //   handleSelectItem(e, index, ['exclusive','flip']); 
      //   handleExpandItem(e, index, ops ); 
      // }
      // let ops = ['exclusive','flip']
      // if (e.ctrlKey) ops = ['flip']
      // handleSelectItem(e, index, ops); 
      // handleExpandItem(e, index, ops ); 

    } else {
      // here all operations aer exclusive - i.e all other item loose their indexes
      if (isAlreadySelectedItem && isAlreadyExpandedItem) { // unselect 
        // if unexpand then highlighitng of text iimpossible without closing???
        handleSelectItem(e, index, ['exclusive', 'off']); 
        //old handleExpandItem(e, index, ['exclusive','flip'] ); 
      } else if (isAlreadySelectedItem) { // expand
        //old handleSelectItem(e, index, ['exclusive', 'flip']); 
        handleExpandItem(e, index, ['exclusive','on'] ); 
        // and selection off!!!!! ???
        handleSelectItem(e, index, ['exclusive', 'off']); 


      } else if (isAlreadyExpandedItem) { // select ??
        // NO!!!! - rely on expandless icon!
        //handleSelectItem(e, index, ['exclusive', 'on']); 



        //old handleExpandItem(e, index, ['exclusive','flip'] ); 
      } else { //select
        handleSelectItem(e, index, ['exclusive', 'on']); 
        //old handleExpandItem(e, index, ['exclusive','on'] ); 
      }
      
      
    }

     // if (! isAlreadyExpandedItem) handleExpandItem(e, index, ['exclusive','flip'] ); 

  }

  function prepTextForSemanticSearch(txt) {
    //return txt // no need for now
    
    try {
      if (txt == null) return null
      txt = txt.trim()
      if (txt == '') return txt
      // for now just remove dquotes around the whole thing if necessary
      //if (txt[0] == '"' && txt[txt.length-1] == '"') {
        const aa = txt.split('"')
        if (aa.length == 3) { // ['','...','']
          txt = txt.substring(1,txt.length -1)
        }
      //}
    } catch {}

    return txt

  }

return (

  <div key={index} 
      style={{ 
        marginTop:'12px', // between each item
        marginLeft:SearchStrip_LefMargin, // just under search strip start
        marginRight: '0px', //
        //margin:"12px 0px 0px 8px", // aligning XXX
        padding:"0px" // - NO padding here - as to align under 
        //, border: selectItemIndex.has(index) ? '2px red solid' : normalBorder,
        , backgroundColor: selectItemIndex.has(index) ? 'rgb(210 210 210)' : 'white',
        //display: "flex", alignItems: "flex-start"
      }}


      onClick = {(e) => { complexExpansionHandling(e); // compare with (see  design note [simple expansion handling]) that happens on clicking quote link!
      }}
  >



    {/* Link/Title */}
    <a href={eim.link} target="scratchFront" >
      <span  className="linkLikeBehaviour"
        style={style_angry_title}
        dangerouslySetInnerHTML={{__html: tempTitle}}
      />
    </a> 
    

    {/*not used START  common expnasion behavior */}
    <span styleZZZ={{display:"inline"}}
      onClickZZZ = {(e) => { complexExpansionHandling(e); // compare with (see  design note [simple expansion handling]) that happens on clicking quote link!
          }}
    > 
      {/* Source, so to say */}
      <span style={{marginLeft:"0px", fontSize:"small", fontStyle:"normal", fontWeight:"bold", opacity:"0.5"}}> &ndash; {domain2(eim.link)}</span>

      <br/> 


    

      {/* Date */}
      <DateComp eim={eim} siteMode={siteMode}/>
      &nbsp;


      {/* small  Semantic button Search for quote */}
      { false && ! siteMode.has('fullMode') ? null :
        <Tooltip 
        
          // title="Find Semantically Close" 
          title = {<DbgMore htmlKey='semantic_similar' isLong={isInHelpMode} udCallBack={udCallBack}
            htmlShort="Find Semantically Close"
          />}

          enterDelay={isInHelpMode ? 0 : 1000} leaveDelay={200}>

            
          <IconButton style={{ padding: 0, verticalAlign: 'middle', border: '1px solid'
            , marginLeft: '0px', marginRight: '5px', 

            opacity: 0.8,
            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.5)",
            borderRadius: 0
            
          }} classNameZZZ='buttonInactiveStyle'
            onClick={(e) => {
              e.preventDefault();
              handleExpandItem(e, index, "off")
            }}>
            {/* <ExpandLessIcon style={{ fontSize: '16px', color: 'navy' }} /> */}
            <SearchIcon  
              style={{ fontSize: '10px', color: 'navy' , fontWeightZZZ: 'bold'}}
              onClick={(e)=>{
                e.preventDefault()
                handleSearch(e,true,
                  prepTextForSemanticSearch(eim.quote)) // should i add title here?
            }} id="similarsearch">
            </SearchIcon>

          </IconButton>
        </Tooltip>
      }

      {/* Quote */}
        <Tooltip titlZZZe="Click to Expand" 

        title={ // somebody's advice on how to handle cnaelling tip in material  ui...
          
            (selectItemIndex.has(index)? "Click to Expand"  : '')
          
        }

          enterDelay={1000} placement="top" leaveDelay={200} >
          {/* <a href={eim.link} target="scratchFront"> */}
            <span classNameZZZ="linkLikeBehaviour"
              style={style_angry_quote}
              dangerouslySetInnerHTML={{__html: tempQuote}}
              onClick={(e)=>{
                if (true) {

                } else if (false) {
                  handleExpandItem(e, index, ['exclusive','flip'] ) // design note [simple expansion handling]
                } else { 

                handleSearch(e,true,
                  prepTextForSemanticSearch(eim.quote)) // ie semaantic search
                }
              }}
              // onMouseOver={()=>setHoverStyle({
              //   color: 'blue', // Example hover color
              //   textDecoration: 'underline', // Example hover text decoration
              //   // Add other styles as needed
              // })}
              // onMouseOut={()=>setHoverStyle({})}
            />
          {/* </a> */}
        </Tooltip>
    </span> {/* not used END of common expnasion behavior */}



            {/* Expansion */}
            {/* <span style={{ border:'1px red solid'
            //,  display: "flex", alignItems: "flex-start" 
          }}>
          <IconButton style={{ padding: 0, verticalAlign: 'middle' }}>
            {isExpandedItem?
              <ExpandLessIcon fontSize="small" style={{ color: 'navy' }}
              onClick={(e)=>{handleExpandItem(e, index)}}/>
              :
              <ExpandMoreIcon fontSize="small" style={{ color: 'navy' }}
              onClick={(e)=>{handleExpandItem(e, index, )}}/>
            }
          </IconButton>
      </span> */}

      {/* expnsion icon & Text */}
      {isExpandedItem?null:          
            <ExpandCollapseButton 
            EcIcon={ExpandMoreIcon} tip="Click To Expand" onClick={(e)=>{handleExpandItem(e, index, "on")}}/>
      }
      {!isExpandedItem || paragraphs == null? null :
      <div style={{ fontWeight: "500", fontFamily: "roboto", display: "flex", alignItems: "flex-start" }}>
        {/* that much code for the expand icon */}
        <div style={{ border:'0px red solid',  
          display: "flex",  flexDirection: "column", alignItems: "flex-start" }}>

          {/* <Tooltip title="Click to Collapse" enterDelay={1000} leaveDelay={200} >
            <IconButton style={{ padding: 0, verticalAlign: 'middle'
                , border: '2px solid', marginRight: '10px' // that keeps texxt div off to the righ tmore 
                }}
                onClick={(e)=>{
                  handleExpandItem(e, index, "off")
                }}>
              <ExpandLessIcon  
                style={{ fontSize: '16px', color: 'navy' }}
              />
            </IconButton>
          </Tooltip> */}
          <ExpandCollapseButton 
            EcIcon={ExpandLessIcon} tip="Click To Collapse" onClick={(e)=>{handleExpandItem(e, index, "off")}}/>

          {/* the semantic search button underneath collapse .. a bad idea

          <Tooltip title="Find Similars" enterDelay={1000} leaveDelay={200}>
            <IconButton style={{ padding: 0, verticalAlign: 'middle', border: '1px solid', marginRight: '10px' 
              , marginTop: '5px' //separating from the top icon
            }}
              onClick={(e) => {
                handleExpandItem(e, index, "off")
              }}>
    
              <ManageSearchIcon  
                style={{ fontSize: '16px', color: 'navy' , fontWeight: 'bold'}}
                onClick={(e)=>{
                  handleSearch(e,true,
                    prepTextForSemanticSearch(eim.quote)) // should i add title here?
              }} id="similarsearch">
              </ManageSearchIcon>

            </IconButton>
          </Tooltip>
          */}


        </div>

        <div>
        { (OldWayHighlighting?
            paragraphs.map((p, index) => {
              //return <p style={{ marginLeft: index === 0 ? 0 : '10px' }}>{p}</p>

              return <p><HtmlToSpan htmlString={p} /></p>
            })
            : paragraphs.map((p, index) => {

              return <p dangerouslySetInnerHTML={{__html: p}}></p>
            })
          )
        }
        </div>
      </div>
      }






    {/* Actors */} {actorsDict== null
      ? // here we are in 'simple' mode
        <span> <StatesComp eim={eim} udCallBack={udCallBack} 
          isDisabledClick={isInSemanticMode(siteMode)} 
          isImped={false}
          isInHelpMode = {isInHelpMode}
          
        /> </span> 
    
      : // here we are in full mode
      <div>{
    
        actorsImpsToList(eim.actorsDict).map((ad, index)=>{

          // unnecessary:
          // if (ad === undefined || ad === null) {
          //    return 'No Actor ??'
          // }

          return <i>
            {index == 0? null : ','}
            &nbsp;{ad.imp.toString()} {GenActorLink(ad.nm, actors, keyCb, udCallBack,null, actorsDict)}</i>
          
        //return <i>{GenActorLink(ad.nm, actors, udCallBack)}<i>
          })}
        
          {/* add state(s) */} <StatesComp eim={eim} udCallBack={udCallBack} isDisabledClick={isInSemanticMode(siteMode)}
            isInHelpMode = {isInHelpMode}/>
          {/* <div style={{display:"inline", background:"lightgray"}}>
            {ustateDictToList(eim.ustateDict).map((entry, index)=>{ // St2_FullNameOf
              return <small>
                {index == 0? ', ' : ','}
                &nbsp;{entry.imp.toString()}  {entry.nm} </small>
            })}
          </div> */}
        
        </div>

    }

   

  </div> 
  )
}


export function SortMenu2({activeSort, isInHelpMode, udCallBack}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
      
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const handleMenuItemClick = (activeSortString) => {
    udCallBack({action:'$$change', param:{nm:'activeSort', val:activeSortString}})
    //finishWithSort(activeSortString', $$.siteMode, $$.items, "by ...") - done in udCallBack
    handleClose();
  };

  return (
      <div>
          {/* <Button aria-controls="simple-menu" aria-haspopup="true"
                    ---  used for accessibility purposes
              onClick={handleClick}>
              Open Menu
          </Button> */}

          <Tooltip 
            title={false? '' // means no tooltip - see https://stackoverflow.com/questions/53591747/conditionally-activate-material-ui-tooltip
            : <DbgMore htmlKey='sorting' isLong={isInHelpMode} udCallBack={udCallBack}
            htmlShort={
              (activeSort == 'default'? Default_Sort_String : Date_Sort_String)
            } 
            />}

            enterDelay={isInHelpMode? 0 : 3000} 
              //  -- deiberately long delay
                
          >
            {/* choose appropriate icon  */}
            <IconButton  
              onClick={handleClick}
            > 
            {activeSort=='date' ? 
              <ScheduleIcon style = {{...style_angry_control,...{color: Angry_Color}, }}
                />
              :
              <SortIcon style = {{...style_angry_control,...{color: Angry_Color}}}/>
            }
            </IconButton>

          </Tooltip> 




          <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
          >
              <MenuItem  selected={activeSort!='date' }
                onClick={() => handleMenuItemClick('default')}
              >Relevance Sort</MenuItem>
              <MenuItem selected={activeSort=='date'}
                onClick={() => handleMenuItemClick('date')}
              >Date Sort</MenuItem>

              {/* <MenuItem onClick={handleClose}>{activeSort}</MenuItem>  */}
             
              
          </Menu>
      </div>
  );
}



// remnants of old sort menu :
{
  
  
  /* <div style={{ position: "relative" 
//, border:'1px solid green'
}}>



  <Tooltip 
  // title={ // somebody's advice on how to handle cnaelling tip in material  ui...
  //   (!$$.toolTipSortEnabled ? '': 
  //     ($$.activeSort == 'default'? Default_Sort_String : Date_Sort_String)
  //   )
  // }
    title={ $$.showSortOptions? '' // means no tooltip - see https://stackoverflow.com/questions/53591747/conditionally-activate-material-ui-tooltip
      : <DbgMore htmlKey='sorting' isLong={$$.helpMode} udCallBack={udCallBack}
      htmlShort={
        !$$.toolTipSortEnabled ? null: // see design note [2 nulls for tooltip]
      ($$.activeSort == 'default'? Default_Sort_String : Date_Sort_String)
    } 
    />}

    enterDelay={$$.helpMode? 0 : 3000}

    
    //open={toolTipSortEnabled}
    // enterDelay={3000} leaveDelay={100}
  >
  
    <IconButton  
      onClick= {
      () => {
        $$change('showSortOptions', !$$.showSortOptions) ; //setShowSortOptions(prev => !prev)
        $$change('toolTipSortEnabled', !$$.toolTipSortEnabled) ; //setToolTipSortEnabled(prev => !prev)
      }
    } 
    > 
    {$$.activeSort=='date' ? 
      <ScheduleIcon style = {{...style_angry_control,...{color: Angry_Color}, }}
        />
      :
      <SortIcon style = {{...style_angry_control,...{color: Angry_Color}}}/>
    }


  

  
    </IconButton>
  </Tooltip> 


  {$$.showSortOptions && (
    <div style={{display:"inline-block", position:"absolute", 
    top: "100%", 
    //left: "25%",
    right: 0,  // Adjusted here
    zIndex: 1,
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
    minWidth: '120px',

    //border:'1px solid blue'
    
    }}

    
    >
      
    <div style={{ padding: '8px 16px', 
        fontSize:'small',
        cursor: 'pointer', 
        backgroundColor: $$.activeSort == 'default' ? "#f0f0f0" : "white", 
        borderBottom: '1px solid #e0e0e0', transition: 'background-color 0.3s'
      }}                  
        onClick={(e) => { $$change('toolTipSortEnabled', true) //setToolTipSortEnabled(true); 
          //setActiveSortWrapper('default');  
          $$change('activeSort','default')
          $$change('showSortOptions', false) //setShowSortOptions(false)
          finishWithSort('default', $$.siteMode, $$.items, "by ..")

        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = $$.activeSort == 'default' ? "#f0f0f0" : "white"}
   
      >{
        //'By Relevance'
        Default_Sort_String
      }</div>

      <div style={{ padding: '8px 16px', 
        fontSize:'small',
        cursor: 'pointer', 
        backgroundColor: $$.activeSort == 'date' ? "#f0f0f0" : "white", 
        borderBottom: '1px solid #e0e0e0', transition: 'background-color 0.3s' 
      }} 
        onClick={(e) => { $$change('toolTipSortEnabled', true) //setToolTipSortEnabled(true); 
          //setActiveSortWrapper('date');  
          $$change('activeSort','date')
          $$change('showSortOptions',false) //setShowSortOptions(false)
          finishWithSort('date', $$.siteMode, $$.items, "by ...")
        }}
        onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#e0e0e0"}
        onMouseOut={(e) => e.currentTarget.style.backgroundColor = $$.activeSort == 'date' ? "#f0f0f0" : "white"}
   
      >{
        //'By Date' 
        Date_Sort_String
      }</div>
    </div>

    
  )}
</div> */}