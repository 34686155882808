


// a function that returns a promise
function fnToGenAPromise(dataToResolve, dataToReject = 'rejected error'
    , resolvedDelayMillis = 500, rejectedDelayMillis = 600) {
  return new Promise((resolveFn, rejectFn) => {
      if (resolvedDelayMillis >= 0) setTimeout(() => resolveFn(dataToResolve), resolvedDelayMillis)
      if (rejectedDelayMillis >= 0) setTimeout(() => rejectFn(dataToReject), rejectedDelayMillis)
    }
  );
}

function isPromise(object) {
    //https://www.debugpointer.com/javascript/check-if-an-object-is-a-promise
    if (Promise && Promise.resolve) {
      return Promise.resolve(object) == object
    } else {
      throw "Promise not supported in your environment" // Most modern browsers support Promises
    }
  }


const fnAsync = async (verbal = false) => {
    //https://reqbin.com/code/javascript/ricgaie0/javascript-fetch-bearer-token
    const response = await fetch(`http://httpstat.us/500?sleep=1000`
        , {
            //ContentType: 'application/json'
            headers: {Accept: 'application/json'} // Authentication: 'Bearer {token}'
            // ,data: JSON.stringify(SendData)
            }
        );

    if (verbal) console.log('after fetch')
    try {
        const newData = await response.json();
        if (verbal)console.log('after json: ' + JSON.stringify(newData))
    } catch (error) {
        if (verbal)console.log('after json error: ' + JSON.stringify(error))
        return "fn failed"
    }
    
    return "fn() finished"
  };

  if (false) {
    // fnAsync(true);
    // console.log('after fn')
    const b =  isPromise(fnAsync()) 

    // fnAsync(true).then(
    //     (val)=>{console.log('==> fn resolved with : ' + val)},
    //     (val) =>{console.log('==> fn reject with : ' + val)}
    // )

    const p = new Promise((resolve, reject)=>{
        setTimeout(()=>{resolve("aaa")},3000);
        setTimeout(()=>{reject("bbb")},2000);
    })
    p.then((val)=>{console.log('==> resolved with : ' + val); 
        return "First Then Resolved"},
        (val) =>{console.log('==> reject with : ' + val);
        return "First Then Rejected"}
        )
    .then((val)=>{console.log('==> 2 resolved with : ' + val)},
    (val) =>{console.log('==> 2 reject with : ' + val)}
    )
  }


  function createQnaUnit ({txt, auth, targets = null}) {
    console.log("txt: " + txt)
    console.log("auth: " + auth)
    console.log("targets: " + JSON.stringify(targets))
  }
  

  createQnaUnit({txt : "blah blah", auth:"me",  targets: ['aa','bb']} );

fnToGenAPromise('hi resolved', 'hi rejected', -1, 100 ).then(
  (resolvedResult) =>{
    console.log("==> fnToGenAPromise resolvedResult: " + resolvedResult) 
  }
  ,
  (rejectdResult) =>{
    console.log("==> fnToGenAPromise rejectedResult: " + rejectdResult)
  }
  ).catch((error) => {
    console.log("==> fnToGenAPromise caught: " + error)

  });



/* import React, { useEffect, useState } from 'react';

export default function DataDisplayer(props) {
  const [data, setData] = useState('');

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`https://httpstat.us/500`);
      const newData = await response.json();
      setData(newData);
    };
    getData();
  }, [props.id]); //<-- This is the dependency array, with a variable

  if (data) {
    return <div>{data.name}</div>;
  } else {
    return null;
  }
}
 */

export class NormArr {

  constructor(arr) { //, oldWay = false) {
    this.normArr = []
    
    // arr is assumed to be a struct of numbers with exception of 'obj' key
    if (arr == null || arr.length == 0) return 0
    const firstElement = arr[0]

    this.keys = []

    // figure out all keys by the first element
    for (let key in firstElement) this.keys.push(key);


    // let rMax = {}, rMin = {}
    // for (let el of arr) {
    //   for (let key of this.keys) { if (key == 'obj' ) continue;
    //     if (rMin[key] == undefined || rMin[key] > el[key]  ) rMin[key] = el[key]
    //     if (rMax[key] == undefined || rMax[key] < el[key]  ) rMax[key] = el[key]
    //   }
    // }
  
  
    
    for (let el of arr) {
      let nel= {obj:el.obj, sort:-1}
      for (let key of this.keys) {  if (key == 'obj' ) continue;
        // if (oldWay) {
        // const delta = (rMax[key] - rMin[key])
        // if (delta == 0) nel[key] = -1
        // else nel[key] = (el[key] - rMin[key]) / delta
        // } else {
          nel[key] = el[key]
        // }
      }
      this.normArr.push(nel);
    }
    //   normArr = {
    //     score : (r.score - rMin.score) / (rMax.score - rMin.score),
    //     search : (r.search - rMin.search) / (rMax.search - rMin.search),
    //     dtRange : (r.dt - rMin.dt) (rMax.dt - rMin.dt),
    //     bSort : (r.bSort - rMin.bSort) (rMax.bSort - rMin.bSort)
    //   }
    //   eim.sort = 0
    //   eim.sort = 1 * (1 + r.score / (rMax.score - rMin.score))
    //   eim.sort = 2 * (1 + r.score / (rMax.score - rMin.score))
    //   eim.sort = 4 * (1 + r.score / (rMax.score - rMin.score))
    //   eim.sort = 8 * (1 + r.score / (rMax.score - rMin.score))
    // }
  } // end of constructor


  keys = []

  sort(sortKeys) { //, oldWay = false) {
    //if (! oldWay)
      return this.sortR(sortKeys)

    // const reversedKeys = [...sortKeys].reverse();
    // for (let nel of this.normArr) {
    //   nel.sort = 0
    //   let factor = 1
    //   for (let sortKey of reversedKeys) { // sort keys are given in the order of importance
    //     const v = nel[sortKey]
    //     if (!(v == undefined || isNaN(v) || v == null))
    //       nel.sort += factor * (1 + v)
    //     factor *= sortKeys.length
    //   }
    // }
    // this.normArr.sort((e1, e2)=>{return -(e1.sort - e2.sort)}) // the biggest first?
    // const sortedItems = this.normArr.map((nel) => nel.obj);
    // return sortedItems

  }

  sortR(sortKeys) {
    this.normArr.sort((e1, e2) => {
      return NormArr.recurseCmp(0, sortKeys, e1, e2)
    })
  
    const sortedItems = this.normArr.map((nel) => nel.obj);
    return sortedItems

  }
  
  static recurseCmp(iKey, keys, e1, e2) { // biggest first !!!
    const key = keys[iKey]
    const v1 = e1[key]
    const v2 = e2[key]
    
    if (v1 != v2) return -(v1 - v2) // biggest first
    if (iKey >= keys.length) return 0
    return this.recurseCmp(iKey + 1, keys, e1, e2)
  }


} // en of class