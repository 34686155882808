
import {useNavigate} from "react-router-dom" 
import {useState, useEffect, useRef} from "react" 
import ContentEditable from 'react-contenteditable'

import {IsElementInView, GetScrollableParent} from "../Utils/MiscForApp.js"

// import AiQnaList from "./AiQnaList.js"

// import "../App.css";
import {QnA_List}  from '../Logic/CnvClasses.ts'
import { Checkbox } from "@mui/material"

export const CnvScroll = ({udCallBack, records, participants, pnifs=null, ...props}) => {
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
    const scrollContainerRef = useRef(null);

    const [selectedIndex, setSelectedIndex] = useState(-1)
    const hasBeenScrolled = useRef(false);
    const selectRef = useRef(null)
   
    const isScrollAtBottom = () => {
        const { scrollTop, scrollHeight, clientHeight, offsetHeight } = scrollContainerRef.current;
        const tolerance = 50; // You can adjust this value as needed
        const isAtTheBottom = scrollHeight - scrollTop - clientHeight <= tolerance;
        //const v2 = 
        return isAtTheBottom;
       }

    function checkScroll(e) {
        const iss = isScrollAtBottom()
        //console.log("Scrolling : " + iss)
        setIsScrolledToBottom(iss)
      }

    useEffect(() => { // this effect is for scrolling
        if (isScrolledToBottom) { //hasBeenScrolled.current) {
          scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
      }, [records]);


    const handleItemContextClick = (e) => {
    //https://stackoverflow.com/questions/31110184/react-synthetic-event-distinguish-left-and-right-click-events
        e.preventDefault();
        if (e.type === 'click') {
          alert('Left click');
        } else if (e.type === 'contextmenu') {
          alert('Right click');
        }
    };

    const handleItemClick = (e, idx, item) => { 
      if (e.ctrlKey) {
        alert('Ctrl click');
      }
      if (e.nativeEvent.button === 0) {
        console.log('Left click');
      } else if (e.nativeEvent.button === 2) {
        alert('Native Right click');
      }
        setSelectedIndex(idx)
        udCallBack({markedXunitIdx:idx});
    }

    const handleSelectChange = (e,index, participantName) => {
        // if (selectRef.current) {
        //   selectRef.current.style.width = '90px' // `${e.target.value.length + 1}ch`;
        // }
        handleParticipantChange(index, participantName);
      };
    
    const handleParticipantChange = (idx, participantName) => { 
        udCallBack({targetChange:{idx:idx, targetName:participantName}});
    }

    const handleContentChanged = (e, idx, item) => { 
      //console.log('===>CnvScroll content change')
      const newContent = e.target.innerText //innerHTML
      const oldContent = item.txt
      if (newContent != oldContent) {
        if (window.confirm('Save Changes')) {
          udCallBack({contentChange:{idx:idx, content:newContent}});
        } else {
          e.target.innerText = oldContent
        }
      }
    }


    const qnal = QnA_List.createFromRecords(records)
    let units = []
    if (qnal) units = qnal.getUnits()

    // temp:
    function getIconAndColor(auth) {
        // find the proper participant
        let p = participants.find(p => (p.dname === auth || p.name === auth));
        if (p) return { icon: p.icon, bgColor: p.bgColor, iconName: p.iconName };
        else return { icon: 'path/to/user-icon.svg', bgColor: '#dedbec' , iconName: "sentiment_neutral"}; 
        
        // switch (auth) {
        //   case 'aaa':
        //     return { icon: 'path/to/admin-icon.svg', bgColor: '#d3d1dd', iconName: "face_5" }; // bob man
        //   case 'bbb':
        //     return { icon: 'path/to/user-icon.svg', bgColor: '#dedbec' , iconName: "sentiment_neutral"};
        //   default:
        //     return { icon: 'path/to/guest-icon.svg', bgColor: '#cbc9d5' , iconName: "face_4"}; // alice . woman
        //     // face_3 woman face_5 - man; 
        // }
      }



    return (  
        <div id="remarksDiv" className="remarks" 
          onScroll={checkScroll}
          onResize={checkScroll}
          style={{ flex: 1, overflowY: "auto" }}
          ref={scrollContainerRef}
        >
                    
        {/* notice that  i use cnvIdSelected down below - to use the same "selected" clue */}
    
        {
    
          <ul style={{ overflowY: 'auto', listStyleType: 'none', paddingLeft: 0 }}>
              {units.map((u, index) => {
                const { icon, bgColor , iconName} = getIconAndColor(u.auth);
                
                  let clsNames = selectedIndex === index ? "cnvIdSelected" : ""
                  if (pnifs && pnifs.length > 0 
                    && !pnifs.includes(u.auth) 
                    && !pnifs.includes('all')
                    && !(u.auth == 'me')
                    ) {
                      clsNames += ' ' + 'pnifHide'
                  }
                  return (
                  <li key={index} style={{ backgroundColor: bgColor, border: "1px solid lightgray", margin:"3px" }}
                    className={clsNames} 
                    onClick={(e) => handleItemClick(e, index, u)}
                    onContextMenuXXX={handleItemContextClick}
            
                    /* ref={liRef} */
                  >
                    <span className="material-icons" style={{ width:'', marginRight: '8px' }}>{iconName}</span>
                        <span>{u.auth + ' : ' }  </span>
        
                        <select onChange={(e)=>handleSelectChange(e,index, e.target.value)}
                          style={{ backgroundColor: bgColor
                          , border: "1px solid lightgray"
                          , margin:"3px"
                          , widthSSS: "auto !important" }}
                          ref={selectRef}
                        >
                            <option>{'all'}</option>
    
                            {participants.map((p,pindex)=>{
                                return <option key={pindex} value={p.name} 
                                    selected = {u.targets != null && u.targets.includes(p.name)}>
                                    {p.dname}</option>})
                            }
    
                        </select>
    
                        {false ? 
                          <div>{u.txt} </div> 
                          :
                          <ContentEditable html={u.txt} 
                            onBlur={(e) => handleContentChanged(e, index, u)}
                          />
                        }
                        
                    
    
                    {/* {item.auth} :::   {item.text} */}
    
                  </li>
                  )
              })}
    
        </ul>
    
      }
    </div>)



}

export const ParticipantSelect = ({selectedNames, handleChange, participants
    , bgColor ="gray"}) => {
  // via handleChange([set of changes]) returns results
  return(
    <select onChange={(e)=>handleChange( [e.target.value])}
    style={{ backgroundColor: bgColor
    , border: "1px solid lightgray"
    , margin:"3px"
    , widthSSS: "auto !important" }}
    // ref={selectRef}
    >
      <option>{'all'}</option>

      {participants.map((p,pindex)=>{
          return <option key={pindex} value={p.name} 
              // selected = {unit.targets != null && unit.targets.includes(p.name)}
              selected = {selectedNames != null && selectedNames.includes(p.name)}
          >
              {p.dname}</option>})
      }

    </select>
  )
}