import axios from "axios"
import {ScratchBackUrl} from '../Utils/gptCall.js'
import React from "react";

// does not woik :)
// const process = require('process');
// const arg0 = process.argv[0]
// console.log(arg0);

export async function immitateSaveRecord(cnt){
  try {
    const postData = {question: "QQQuestion" + cnt, answer:"gptAnswer" + cnt, mood: "mmmmmm", ts : new Date()}
    //console.log("AiPage.onFormSubmit: before post: " + JSON.stringify(postData));
    //const response = 
    await axios.post(ScratchBackUrl + '/saveRecord', postData);

  } catch (error) {
    console.log("AiPage.onFormSubmit: error in post: " + JSON.stringify(error.response.data));
  }
}

// async function Get_ScratchBack_Records_Sync() {
//   console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
//   try {

//     const recs = await Get_ScratchBack_Records(null)
    

//   } catch (error) {
//     console.log('===>\n' + error);
    
//     //     
//   }
// };

//-----------------------------------------------------------------
export async function Get_CnvIds() {
  console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
  // try {

    const response = await axios.get(ScratchBackUrl + '/getCnvIds');
    // - axios.get might trow an error from the back end by the caller should handle it
    
    const res = response.data

    const cnvIds = res
    return cnvIds

};

export async function Rename_CnvId(oldCnvId, newCnvId) {
  console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
  // try {
    const postData = {
      filter:{cnvId: oldCnvId}
    , update:{$set:
        {cnvId:newCnvId}
      }
    }  

    const response = await axios.post(ScratchBackUrl + '/updateMany', postData);
       
    //const res = 

    return response.data

};

export async function Remove_CnvId(oldCnvId) {
  console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
  // try {
    const postData = {
      filter:{cnvId: oldCnvId}
    , options:{}
    }  

    const response = await axios.post(ScratchBackUrl + '/deleteMany', postData);
       
    //const res = 

    return response.data

};

export async function Get_ScratchBack_Records(cnvId) {
  console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
  // try {
    const filter = {cnvId: cnvId}
    const postData = {filter: filter}
    const response = await axios.post(ScratchBackUrl + '/getRecordsEx',postData);
    // - axios.get/post might trow an error from the back end by the caller should handle it
    
    const recs = response.data

    //const recs = await immitateGetRecords(records)

    // resort records 
    recs.sort((a,b)=>{
      
    // return (b.ts.getTime() - a.ts.getTime());}) .. the oldest first
      return (new Date(a.ts) - new Date(b.ts))})

    for (let i = 0; i< recs.length; i++) {
      recs[i]["id"] = i
    }

     console.log(' getRecords() finished')

     return recs

// - the call should handle 

  // } catch (error) {
  //   console.log('===>\n' + error);
  //   return {error:error}
  //   //     
  // }
};
//------------------------------------------------------------

//https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
export function HashCode_LordVlad(s) {
  return s.split("").reduce(function(a, b) {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0);
  // example return :  -1220935268
}

// export function IsElementInView(element) { // generated by chatGPT!!
//   // alse see stackoverflow solutions : https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
//   const rect = element.getBoundingClientRect();
//   const windowHeight = window.innerHeight || document.documentElement.clientHeight;
//   const windowWidth = window.innerWidth || document.documentElement.clientWidth;

//   // Check if the element is in the viewport
//   const elementInView = (
//     rect.top >= 0 &&
//     rect.left >= 0 &&
//     rect.bottom <= windowHeight &&
//     rect.right <= windowWidth
//   );

//   return elementInView;
// }


export function IsElementInView(element, container) {
// generated by chatGPT!!
//   // alse see stackoverflow solutions : https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling

  const rect = element.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();
  const containerTop = containerRect.top;
  const containerBottom = containerRect.bottom;
  const containerLeft = containerRect.left;
  const containerRight = containerRect.right;
  const elementTop = rect.top - containerTop;
  const elementBottom = rect.bottom - containerTop;
  const elementLeft = rect.left - containerLeft;
  const elementRight = rect.right - containerLeft;
  const containerHeight = container.clientHeight;
  const containerWidth = container.clientWidth;

  // Check if the element is in the container viewport
  const elementInView = (
    elementTop >= 0 &&
    elementLeft >= 0 &&
    elementBottom <= containerHeight &&
    elementRight <= containerWidth
  );

  return elementInView;
}


// what about a function that returns a parent div(if any) for an element
// Sure! Here's an example function that returns the closest ancestor element that has a scroll overflow:

export function GetScrollableParent(element) {
  // Check if the element is already a scrollable element
  const isScrollable = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  if (isScrollable) {
    return element;
  }

  let parent = element.parentElement;
  while (parent) {
    const overflowY = window.getComputedStyle(parent).overflowY;
    const overflowX = window.getComputedStyle(parent).overflowX;
    const isScrollableY = parent.scrollHeight > parent.clientHeight && overflowY !== 'visible' && overflowY !== 'hidden';
    const isScrollableX = parent.scrollWidth > parent.clientWidth && overflowX !== 'visible' && overflowX !== 'hidden';

    if (isScrollableY || isScrollableX) {
      return parent;
    }

    parent = parent.parentElement;
  }

  // If no scrollable parent is found, return the window object
  return window;
}

export function DotDotDot({ isRemoting }) {
  return (
    <div>
      {! isRemoting ? (
        <p>Remote is off</p>
      ) : (
        <p>WAITING</p>
        // <p style={{ animation: "dots 1s infinite" }}>...</p>
      )}
    </div>
  );
}

export function DelayMillis(t) {
  return 
  new Promise(resolve => setTimeout(resolve, t))
}
  

export const UseEventListener = (type, handler, el = window) => {
  //https://www.30secondsofcode.org/react/s/use-event-listener/
  
  const savedHandler = React.useRef();



  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const listener = e => savedHandler.current(e);

    el.addEventListener(type, listener);

    return () => {
      el.removeEventListener(type, listener);
    };
  }, [type, el]);
};









