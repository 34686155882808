import React, { useState, useRef, useEffect } from 'react';
//import './Feedback.css';
import Tooltip from '@mui/material/Tooltip';


export function DbgDialog({ isOpen, onClose, onSubmit = null }) {
  // how to send email via smtp:
  // https://www.programonaut.com/how-to-send-an-email-in-node-js-using-an-smtp-step-by-step/
  // go daddy :  https://clean.email/blog/email-settings/godaddy-workspace-email-settings

  const dialog = useRef(null);
  const [feedback, setFeedback] = useState('');
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isOpen && dialog.current) {
      dialog.current.show();
    }
  }, [isOpen]);

  const handleClose = () => {
    if (dialog.current) {
      dialog.current.close();
    }
    onClose();
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Simulating sending feedback
    try {
      // Here you would replace this with an actual API call
      // For example:
      // await axios.post('/api/feedback', { feedback, email });

      

      if (onSubmit) {
        onSubmit({ feedback, email })
      } else {
        // it's better to send from react client thanr form node server because sending ip's will be various ..
        // actually - impossible from the client :()
      }

      console.log('Feedback submitted:', { feedback, email });
      // Handle success (show a message to the user, etc.)
    } catch (error) {
      // Handle error (show error message to the user)
      console.error('Failed to submit feedback:', error);
    } finally {
      setIsSubmitting(false);
      setFeedback('');
      setEmail('');
      handleClose();
    }
  };


  return (
    <dialog  
      style={{ position: "fixed", top: 0, right: 0 }}
      ref={dialog} classNameZZZZ="feedback-dialog" onClose={handleClose}
      
      >
      <form onSubmit={handleSubmit}>
        <h1>Feedback</h1>
        <div className="form-group">
          {/* "required" attribute (by HTML 5 spec) causes the chrome display of "please fill .." */}
          <textarea
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Type your feedback here..."
            rows="8"
            required
          ></textarea> 
        </div>
        <div className="form-group">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Your email (optional)"
          />
        </div>
        <div className="dialog-actions">
          <button type="submit">Send Feedback</button>
          <button type="button" onClick={handleClose}>Close</button>
        </div>
      </form>
    </dialog>
  );
}

export const Tips = { // .short fields are used only if caller does not supply it's own
  cancelHelpMode: "Cancel Help",

  
  logo: { 
    short:<>Click to refill with the latest data</> // it has the same effect as a go back icon when history is empty
    , long:
    <div>
    The data is regularly updated. Click to refill the data with the most recent updates.
    <br/>
    The app collects interesting (the most angry) recent political news and presents them as items in the right pane.
    </div>
  },


  help_itself: { 
    //paradoxically 0 the long mode should be displayed in NO Help and short one In Help Mode??
    // i am open to better suggestions
    short: null // <>Press the link to exit Help Mode </> // used IN! Help Mode
    , long: // - is used when NOT! in help mode
    <div>
    In Help Mode, hover over elements to see detailed explanations. 
    Click the Help Mode again to return to normal operation.
    </div>
  }, 

  go_back: {
    short:<>Go Back</> // it has the same effect as a go back icon when history is empty
        , long:
        <>
        <div>
          Use the Go Back button to navigate through the history of your searches within the application. 
          Avoid using the browser's back button as it will replace the application.
        </div>
        <div style={{ marginTop: '10px' }}>
          If the application history is empty, a refresh button will be displayed. Pressing this will reload the currently available data.
        </div>
        </>

  }, 

  textual_search: { // in the caller "Press for Textual search" : "Textual search" - dependng on serach mode - semantic or textual
    // htmlShort={isInSemanticMode($$.siteMode) ? "Press for Textual search" : "Textual search"} 
    short:<>Press for Textual Search</>
    , long: 
    <div>
    In normal search mode, data is found by matching words. For precise matches, use double quotes (e.g., "exact phrase").
    </div>
  },

  semantic_search: { // simialar to textual
    // htmlShort={isInSemanticMode($$.siteMode) ? "Semantic search" : "Press for Semantic search"} /> }
    short:<>Press for Semantic Search</>
    , long: <>
    <div>
    In semantic search mode, data is found based on semantic closeness, identifying items similar in meaning.
    </div>

    <div style={{ marginTop: '10px' }}>
    If direct searches yield insufficient data, try using semantic search.
    </div>

    <div style={{ marginTop: '10px' }}>
      Restricting articles to selected states is not available in this mode.
    </div>
    </>
  },

  advanced_mode: { // explains what happenes in advanced node
    // htmlShort={!$$.siteMode.has('fullMode') ?'Go to Advance Mode':'Go to Basic Mode'} 
    // ..
    // {$$.siteMode.has('fullMode') ? 
    // <KeyboardDoubleArrowLeftIcon fontSize="large" /> 
    // : <KeyboardDoubleArrowRightIcon fontSize="large" />}
    // 
    // so i guess i need a detailed explanation only in basicc mode - it will describe what will be added in advanced?
    short:  <>Switch to Advanced Mode</>
    , long:
    <div>
    In advanced mode, additional features are enabled to enhance your experience, 
    such as search by mentions.
    </div>
  },

  sorting: { 
    //($$.activeSort == 'default'? Default_Sort_String : Date_Sort_String)
    // where 
    // const Default_Sort_String = 'Relevance Sort'
    // const Date_Sort_String = 'Date Sort'

    short: null // see design note [2 nulls for tooltip]
    , long: 
    <>
    <div>
      Sorting by 'relevance' combines freshness and the emotional level of the article to provide the most pertinent results.
    </div>
    <div style={{ marginTop: '10px' }}>
      Sorting by 'date' preserves relevance within the same dates.
    </div>
    </>
  },

  semantic_similar: {
    short:<>Find Semantically Similar Items</>
    , long: 

        <div>
        This searches for items that are semantically similar, based on meaning and associations. 
        If direct searches yield insufficient data, try using semantic search.
        </div>
  },

  item_geo_click: { // this one on hovering over states
    short:<>Filter by State</>
    , long:  
      <div>
        Restricts articles to those related to the selected state.
      </div>
  },

  mentioned: {
    //htmlShort={'Major mentions in articles'}
    short: <>Major Mentions in Articles</>
    , long:
      <div>
      Pressing on a mentioned person or organization narrows the search to that mention, 
      allowing focused exploration of related articles.
      </div>

  }
}

function tipOf(key, subKey = null) {
  if (subKey == null) subKey = 'long'
  let res = null
  const entry = Tips[key]
  if (entry) {
    const subEntry = entry[subKey]
    if (subEntry) res = subEntry
  }

  return res
}

// export function DbgMore({isLong, htmlShort=null, htmlKey, udCallBack, noCancel=false}) {
//   const [isVisible, setIsVisible] = useState(false)
//   // design note [2 nulls for tooltip]
//   let shortTop = tipOf(htmlKey,'short')
//   let longBottom = isLong? tipOf(htmlKey,'long') : null


//   if (htmlShort) shortTop = htmlShort
//   // // design note [2 nulls for tooltip]
//     // if both - short is missing in tips and calle supples null than NO TIP
//   if (shortTop == null && longBottom) 
//     return ''
  
//   return ( // it always displays the short version on top, and then, optionally, the long addition
//     <>

//       <div onMouseDown={(e)=>{
//             // - so that mouse press does not close the tooltip, for example
//             e.preventDefault(); 
//             //e.stopPropagation();
//           }
//         }
//       > 


//         {/* The shortmessage is always displayed */}
//         {/* {htmlShort? htmlShort: tipOf(htmlKey,'short')}  */}
//         {shortTop}

//         {/* display cancel help if long */}
//         <div style={{display:isLong?'block':'none'}}>

//           {/* display long if any */}
//           {/* {tipOf(htmlKey)} */}
//           <div style={{margin:'0px'}}>{longBottom}</div>

//           {noCancel? null : 
//             <div>
//               <a
//                 href="#"
//                 style={{
//                   display: !isVisible ? 'inline' : 'none',
//                   color: 'blue',
//                   textDecoration: 'underline',
//                   cursor: 'pointer'
//                 }}
//                 onClick={() => udCallBack({action:'offHelpMode'})}
//               >
//                 {Tips.cancelHelpMode}
//               </a>
//             </div>
//           }



//         </div>


//       </div>
      
//     </>
//   )

// }

export function DbgMore({ isLong, htmlShort = null, htmlKey, udCallBack, noCancel = false }) {
  const [isVisible, setIsVisible] = useState(false);
  
  let shortTop = tipOf(htmlKey, 'short');
  let longBottom = isLong ? tipOf(htmlKey, 'long') : null;

  if (htmlShort) shortTop = htmlShort;

  if (shortTop == null && longBottom) return '';

  return (
    <>
      <div 
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        style={{ padding: '10px', fontFamily: 'Arial, sans-serif', fontSize: '14px' }}
      >
        {shortTop}

        <div style={{ display: isLong ? 'block' : 'none', marginTop: '10px' }}>
          <div style={{ margin: '0px', borderTop: '1px solid #ccc', paddingTop: '10px' }}>
            {longBottom}
          </div>

          {!noCancel && (
            <div style={{ marginTop: '10px' }}>
              <a
                href="#"
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}
                onClick={() => udCallBack({ action: 'offHelpMode' })}
              >
                Cancel Help
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}



// chat gpt sublassing - do tomorrow 
/* 
import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

function DynamicTooltip({ children, initialContent, actionButtonLabel }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(initialContent);

  // Function to open the tooltip
  const handleOpen = () => setOpen(true);

  // Function to close the tooltip
  const handleClose = () => setOpen(false);

  // Function to change the content and resize the tooltip
  const handleChangeContent = (newContent) => {
    if (open) {
      setOpen(false); // Close the tooltip to reset its size
      setTimeout(() => {
        setContent(newContent); // Change content after a brief timeout
        setOpen(true); // Reopen the tooltip
      }, 10); // Adjust timeout if needed for better UI experience
    } else {
      setContent(newContent); // Simply update content if tooltip is not open
    }
  };

  useEffect(() => {
    handleChangeContent(initialContent); // Initialize with default content
  }, [initialContent]);

  return (
    <Tooltip
      open={open}
      title={content}
      onClose={handleClose}
      onOpen={handleOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <Button onMouseEnter={handleOpen} onMouseLeave={handleClose}>
        {children}
      </Button>
      <Button onClick={() => handleChangeContent('Extended content after action.')}>
        {actionButtonLabel}
      </Button>
    </Tooltip>
  );
}


function DynamicTooltip({ children, title, interactive = false }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={title}
      interactive={interactive}
      disableFocusListener
      disableHoverListener
      disableTouchListener
    >
      {React.cloneElement(children, {
        onMouseEnter: handleOpen,
        onMouseLeave: handleClose,
      })}
    </Tooltip>
  );
}
 */