import React, {useState, useEffect, useRef } from 'react';
import axios from "axios"

import Joyride from 'react-joyride';

// import SplitPane from 'react-split-pane';

// import {Search_ToArray, HiText, HighlightText, HiTextComp, HiGetParagraphs, HtmlToSpan
// } from '../Utils/HiText.js'

import './ElraiProto.css';


import * as HiUtils from '../Utils/HiText.js'
import {QMatching, QMatchingWeed} from '../Utils/HiText.js'

import {ConversationDropdownButton, Modal123, BlobDisplay} from "../Utils/Misc2.js"
import {ScratchBackUrl} from '../Utils/gptCall.js'

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import { dialogClasses } from '@mui/material';

export  function LinearIndeterminate() {
  return (
    <Box sx={{ display:'inline-block',
      //display: 'flex', 
      align:'center',
      width: '20%' }}>
      <LinearProgress />
    </Box>
  );
}

export function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
  );
}

// const JsonResponsePage = () => {
//   useEffect(() => {
//     // Get the JSON object from the URL query parameter
//     const url = new URL(window.location.href);


//     const jsonString = JSON.stringify({aaa:"aaa", bbb:123}) //url.searchParams.get('data');

//     // Parse the JSON string into an object
//     const jsonObject = JSON.parse(jsonString);

//     // Set the response headers
//     const headers = new Headers();
//     headers.set('Content-Type', 'application/json');

//     // Create a new response with the JSON object
//     const response = new Response(JSON.stringify(jsonObject), {
//       headers
//     });

//     // // Return the response
//     // window.parent.postMessage(response, '*');

// // Extract data from the response object
// response.clone().text().then((data) => {
//   // Pass the data to postMessage
//   window.parent.postMessage(data, '*');
// });

//   }, []);

//   return null;
// };

// export default JsonResponsePage;

export  function TextAreaPageDBG() {
  return (
    <div className="advisoryMessage">Test Advisory Message!!!</div>
  );
}




export default function TextAreaPage() {
  const textAreaEl= useRef()
  const paramsEl= useRef()
  const [isRemoting, setIsRemoting] = useState(false)

  const [weedResult, setWeeResult] = useState(null)

  const blobWindowRef = useRef(null); // for
  const blobWindowRef2 = useRef(null); // for
  const queryElRef = useRef()

  const [apdWindowHandle, setApdWindowHandle] = useState(null)

  const [run, setRun] = useState(false);

  const steps = [
    {
      target: '.submit-button',
      content: 'This button SUBMITS.',
    },
    {
      target: '.dbg-button',
      content: 'This button for DBG.',
    },
    // Add more steps as needed
  ];

  
  async function dbgText(txt, query) {

    const weed = QMatching.QueryMatchEval(query, null, null, txt) //alert(query + '\n\n' + txt)
    setWeeResult(weed)
    //alert(JSON.stringify(qme, null, 2))
    BlobDisplay(weed, blobWindowRef)
    return

    setIsRemoting(!isRemoting);
    return 

    try{
      JSON.parse(txt)
      const x = 1
    } 
    catch(error) {
      const x = 1
      BlobDisplay(error.message, blobWindowRef)
      return
    }
  }

  async function handlePress(e, txt) {
    const fJson = getFilter2(txt)
    if (fJson) {
      const result = await Get_By_Filter(fJson)
      //alert(txt)
      if (result.error) {
        BlobDisplay(result, blobWindowRef2)
        return
      } else {
        const rawEimList = result.data
        if (rawEimList && rawEimList.length > 0) {
          const rawEim = rawEimList[0]
          rawEim.embeddings = 'removed'
          const req = JSON.parse(rawEim.request)
            if (req.prompt) req.prompt = req.prompt.replace(/(?:\\r\\n|\\r|\\n)/g, '\n')

          const res = JSON.parse(rawEim.response)


          textAreaEl.current.value = rawEim.txtIn
          rawEim.txtIn = rawEim.txtIn.replace(/(?:\r\n|\r|\n)/g, '\n')
          const reqres = {req: req, res:res}
          BlobDisplay(reqres, blobWindowRef2)
          return
        }
        BlobDisplay(result, blobWindowRef2)
      }
      
    }
  }

  ///////////////////////////////////////
  function getFilter2(filterJson) {
    try { 
      let  fJson = null

      fJson = JSON.parse(filterJson)


      return fJson
    } catch(e) {
      alert('getFilter2\n' + e.message); return null;
    }
  }

  async function Get_By_Filter(filter = null) {
    //console.log('===> ScratchBackUrl : ' + ScratchBackUrl)
    // try {
  
    const outServerUrl = ScratchBackUrl
  
    // all fields for now .. 
      const postData = {action:null, filter: filter, fields: {}, options: null, sort: {score:-1}}
  
  //Atlas atlas-mii1uc-shard-0 [primary] test> db.electrais.find({score:{$gte:0.7}},{title:1,score:1,link:1}).sort({score:-1}).count()
  
  
    //return await Get_Elrais_Inner(postData)
  
      const response = await axios.post(outServerUrl + '/outServerArt/Electrai_LookupMany', postData);
      // - axios.get might trow an error from the back end by the caller should handle it
  
      return response.data // i.e. returms {error:{}. data:P{}}
  
  };



  async function onDoDo() {
    //alert(textAreaEl.current.value)

    //alert(ScratchBackUrl);


    const jsonProblem = "{\n  \"ptension\": 1,\n  \"explication\": \"The text talks about a continuous \"unsolvable\" situation of a lack of policy for leasing out school bus services. This is expressed by the urgency mentioned by Katherine who said that they have been \"discussing for too long\", suggesting a problem without a proper solution. The repeated request and the use of phrases such as \"desperate need\", suggest a mismatch between the expectations and needs of obtaining a policy in the form of resolving the bus usage situations. Hence, there are strong indications of perceived tension, leading to the value of 1.\" \n}"
    
    // on the back :
    // const action = req.body.action
    // const parameters = req.body.parameters
    // const text = req.body.text
    try {
      //JSON.parse(jsonProblem)
      var parameters = JSON.parse(paramsEl.current.value)
    } catch(error) {
      const x = 1
      BlobDisplay(error.message, blobWindowRef)
      return
    }
    
    const postData = {
      parameters: parameters,
      text: textAreaEl.current.value // shouldn't axios escape text properly?
    }

    let baseUrl = ScratchBackUrl
    if (parameters.baseUrl) baseUrl = parameters.baseUrl.trim() //'http://localhost:5000'

    if (postData.parameters.parseJson) {

      try {
        const xxx = JSON.parse(postData.text);
        xxx.___signature = parameters.modelName + ' // ' + parameters.promptFname
        BlobDisplay(xxx, blobWindowRef)
        return 
      } catch (e) {
        BlobDisplay(e, blobWindowRef)
        return
      }

    } else if (true) {

      const bwref = (parameters.multiBlobs ? null : blobWindowRef)
      try {
        const response = await axios.post(baseUrl + '/returnPosdataJson',postData);
        // - axios.get/post might trow an error from the back end by the caller should handle it
      
        const recs = response.data

        // attempt to convert "text" field of response to json
        if (recs.text) {
          try{
            const jsonReply = JSON.parse(recs.text)



            recs.text = jsonReply //JSON.stringify(jsonReply,null,2)
            
          } 
          catch(error) {
            // never mind
            alert(error)
          }
        }

        recs.___signature = parameters.modelName + ' // ' + parameters.promptFname

        BlobDisplay(recs, bwref)

      } catch (error) {
        BlobDisplay(error.message, bwref)
      }
    

    } else {
      const recs = {aaa:'aaaaaaaaaa',bbb:12345}

      BlobDisplay(recs, null) //blobWindowRef)
    }
    return 

  }


  return (<>

{/* <div className ="advisoryMessage">Test Advisory Message</div> */}


    <button className="submit-button" onClick={onDoDo}>submit</button>
    <button className="dbg-button" onClick={(e)=>dbgText(textAreaEl.current.value, queryElRef.current.value)}>dbg</button>

    <button onClick={(e)=>{  
      if (apdWindowHandle != null) {
        const txt = textAreaEl.current.value
        const mmm = {op:'..', params: {q: txt} }
        console.log("SENDER : sending message: " + txt);
        apdWindowHandle.postMessage(
          mmm,
          '*', //window.location.origin + '/textareapage' ,
        );
      } else {
      const apdH = window.open("/?apdH");
      setApdWindowHandle(apdH)
      window.addEventListener(
        "message",
        (event) => {
          // if (event.origin !== window.location.origin) {
          //   // Check the origin to ensure security
          //   return;
          // }
          console.log("in SENDER ??")
          // Handle the incoming message
          const receivedMessage = event.data;
          if (receivedMessage.items) {
            BlobDisplay(receivedMessage.items, blobWindowRef)
          } else {
            console.log("SENDER : Received message: " + receivedMessage);
          }
        },
        false,
      );
      }
    }}>dbg2</button>

    {/* /////////////////////////////////////////////////////// */}
    <button onClick={() => {
        //alert('help clicked')
        setRun(false);  // Stop the tour
  setTimeout(() => {
    setRun(true);  // Restart the tour after a brief pause
  }, 0);  // Ensures the tour reset is recognized by React
      }
    }
    >Help</button>
    <Joyride
        steps={steps}
        run={run}
        continuous
        showSkipButton
        showProgress
        callback={(data) => {
          console.log('joy action: ' + data.action)
          if (data.action === 'close') setRun(false);
        }}
      />
    {/* ///////////////////////////////////////////////// */}



    <input type="text" size="100" placeholder="info .."
              style={{ 

                  boxSizing: "border-box",
                  outline: "none",
                  border: "1px solid #ccc",

              }} 
              onKeyDown={(e)=>{

                if (e.key == 'Enter') {
                  handlePress(e, queryElRef.current.value)
                }

              }}queryElRef


              ref={queryElRef}
              defaultValue='{"idx": "m:_a:StructExtract_p:ptaState_PrimariesOnly.txt_u:FPVYTPQD"}'
          />

    {isRemoting ? <span>&nbsp;<LinearIndeterminate/> </span> : null }
    {/* {isRemoting ? <CircularIndeterminate/> : null } */}
    

    {/* <SplitPane split="vertical" defaultSize="50%">
        <div>Pane 1</div>
        <div>Pane 2</div>
    </SplitPane> */}
    
    <textarea
      rows={25}
      name="simpleText" 
      style={{ 
        width: "100%",
        height: "100%", 
        // boxSizing: "border-box",
        // resize: "none",
         margin: 10,
        // padding: 0,
        // outline: "none",
        // border: "1px solid #ccc",
      }} 
      ref = {textAreaEl}

    >
   School officials are working on a new policy for leasing out bus services over the summer season.


Newly elected incoming chair of the MVRHS committee Kathryn Shertzer says the school is currently without a policy. 

“We are in desperate need of, and have been discussing for too long, a policy of our bus use. The requests keep flooding in,” Shertzer said at a recent committee meeting.
</textarea>
      <div>  
        {weedResult ? <WeedComp weed = {weedResult} /> : null}
      </div>



  <textarea
      rows={7}
      columns={30}
      name="parametersJSON" 
      ref = {paramsEl}

      style={{ 
        width: "100%"
      }}
    >
      {JSON.stringify({ multiBlobs: true
      , baseUrl:"http://localhost:5000"
      , action:"whatever"
      ,promptFname:"ptaState_PrimariesOnly.txt"
      ,modelName: "gpt-3.5-turbo-0613"
      ,modelNameXXX: "gpt-4"
      ,temperature: 0.1
      ,top_p: 1


      }, null,2)
      
      }
  </textarea>

    
  </>)
}

function WeedComp({weed}) {


  return (
    <div>
      {weed.xprgs.map( (xprg,index) => {
        return (
          <p> 
            <span dangerouslySetInnerHTML={{ __html: xprg.hiHtml(QMatching.barelyAcceptableMatch) }} />
            {/* {xprg.hiHtml(QMatching.barelyAcceptableMatch)} */}
          </p>
        )
      })
      }
    </div>
  )
}



